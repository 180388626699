export const langs = [
  {code: 'en', name: 'English'},
  {code: 'vi', name: 'Tiếng Việt'},
]
export const texts = [
  ['Email'],
  ['Password', 'Mật khẩu'],
  ['Login', 'Đăng nhập'],
  ['Logout', 'Đăng xuất'],
  ['Schedule', 'Lịch công tác'],
  ['System', 'Hệ thống'],
  ['UserManagement', 'User Management', 'Quản lý người dùng'],
  ['RoleManagement', 'Role Management', 'Quản lý nhóm quyền'],
  ['Name', 'Tên'],
  ['Name2', 'Họ tên'],
  ['CreatedAt', 'Created At', 'Ngày tạo'],
  ['CreateNew', 'Create New', 'Tạo mới'],
  ['Actions', 'Hành động'],
  ['Search', 'Tìm kiếm'],
  ['Role', 'Nhóm quyền'],
  ['PermissionList', 'Permission List', 'Danh sách quyền'],
  ['UserEditing', 'User Editing', 'Chỉnh sửa người dùng'],
  ['Save', 'Lưu'],
  ['Edit', 'Chỉnh sửa'],
  ['Cancel', 'Thoát'],
  ['Close', 'Đóng'],
  ['Back', 'Quay lại'],
  ['ThisFieldIsRequired', 'This field is required', 'Trường này là bắt buộc'],
  ['Department', 'Phòng ban'],
  ['SubmitSchedule', 'Submit schedule', 'Đặt lịch'],
  ['ScheduleApproval', 'Schedule Approval', 'Phê duyệt lịch'],
  ['SeeCalendar', 'Calendar', 'Xem lịch'],
  ['Title', 'Tiêu đề'],
  ['Date', 'Ngày'],
  ['StartTime', 'Start time', 'Giờ bắt đầu'],
  ['EndTime', 'End time', 'Giờ kết thúc'],
  ['Participants', 'Thành phần tham gia'],
  ['Host', 'Người chủ trì'],
  ['Reporter', 'Người báo cáo'],
  ['Content', 'Nội dung'],
  ['Submit', 'Gửi đi'],
  ['Week', 'Tuần'],
  ['Month', 'Tháng'],
  ['Type', 'Loại'],
  ['Display', 'Hiển thị'],
  ['MonSun', 'Mon - Sun', 'Th2 - CN'],
  ['MonFri', 'Mon - Fri', 'Th2 - Th6'],
  ['Day', 'Ngày'],
  ['EventsOfTheDay', 'Events of the day', 'Sự kiện trong ngày'],
  ['AllDay', 'All day', 'Cả ngày'],
  ['Place', 'Địa điểm'],
  ['MeetingRoom', 'Meeting Room', 'Phòng họp'],
  ['Warning', 'Cảnh báo'],
  ['AreYouSureToDelete', 'Are you sure to delete', 'Bạn có chắc muốn xóa'],
  ['Agree', 'Đồng ý'],
  ['StartTimeGreaterThanEndTime', 'Start time greater than end time', 'Thời gian bắt đầu lớn hơn thời gian kết thúc'],
  ['EndTimeSmallerThanStartTime', 'End time smaller than start time', 'Thời gian kết thúc nhỏ hơn thời gian bắt đầu'],
  ['TheTimeFieldIsBlank', 'The time field is blank', 'Trường thời gian trống'],
  ['NOT_APPROVED', 'CHƯA DUYỆT'],
  ['APPROVED', 'CHẤP THUẬN'],
  ['REJECTED', 'TỪ CHỐI'],
  ['Status', 'Trạng thái'],
  ['CreatedBy', 'Created by', 'Người tạo'],
  ['Approval', 'Phê duyệt'],
  ['Approver', 'Người duyệt'],
  ['Calendar', 'Lịch chung'],
  ['weekday_1', 'Monday', 'Thứ Hai'],
  ['weekday_2', 'Tuesday', 'Thứ Ba'],
  ['weekday_3', 'Wednesday', 'Thứ Tư'],
  ['weekday_4', 'Thursday', 'Thứ Năm'],
  ['weekday_5', 'Friday', 'Thứ Sáu'],
  ['weekday_6', 'Saturday', 'Thứ Bảy'],
  ['weekday_7', 'Sunday', 'Chủ Nhật'],
  ['Time', 'Thời gian'],
  ['Previous', 'Trước'],
  ['Next', 'Sau'],
  ['FromDate', 'From date', 'Từ ngày'],
  ['ToDate', 'To date', 'Đến ngày'],
  ['Zoom', 'Phóng to'],
  ['Compose', 'Soạn thư'],
  ['Inbox', 'Hộp thư đến'],
  ['Sent', 'Đã gửi'],
  ['Junk', 'Thư rác'],
  ['Trash', 'Thùng rác'],
  ['Drafts', 'Thư nháp'],
  ['Subject', 'Tiêu đề'],
  ['Seen', 'Đã xem'],
  ['Unseen', 'Chưa xem'],
  ['Delete', 'Xóa'],
  ['MarkAsUnread', 'Mark as unread', 'Đánh dấu là chưa đọc'],
  ['MarkRead', 'Mark read', 'Đánh dấu đọc'],
  ['of', 'của'],
  ['used', 'đã sử dụng'],
  ['messages', 'Tin nhắn'],
  ['ToggleDetail', 'Toggle Detail', 'Ẩn hiện chi tiết'],
  ['Loading', 'Đang tải'],
  ['Reload', 'Tải lại'],
  ['NewMessage', 'New message', 'Thư mới'],
  ['To', 'Tới'],
  ['Send', 'Gửi'],
  ['Reply', 'Trả lời'],
  ['ReplyAll', "Reply all", 'Trả lời tất cả'],
  ['Forward', 'Chuyển tiếp'],
  ['NewMailFrom', 'New mail from', 'Có thư mới từ'],
  ['InsertAttachment', 'Insert Attachment', 'Chèn tệp đính kèm'],
  ['MyDrive', 'My Drive', 'Drive của tôi'],
  ['ShareWithMe', 'Share with me', 'Được chia sẻ với tôi'],
  ['Recent', 'Gần đây'],
  ['Upload', 'Tải lên'],
  ['Search image', 'Tìm kiếm hình ảnh'],
  ['Order', 'Đơn hàng'],
  ['Storage', 'Lưu trữ'],
]
