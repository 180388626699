<template>
    <v-container
        fluid
        class="pa-0"
        >
        <v-row
            style="width: 100%;"
        >
            <v-btn-toggle
                    v-model="tab"
                    mandatory
                    group
                    class="ml-5 summaryContainer justify-space-between mt-3"
                    style="width: 100%;"
                >

                <v-btn value="Green Cover" class="tabBtn" @click="changeTab">
                Green Cover &#38; Green Density
                </v-btn>
                <v-btn value="Top 10" class="tabBtn" @click="changeTab">Top 10 Change Results</v-btn>
            </v-btn-toggle>
        </v-row>
        <!-- <span>&#178;</span> For 2 square-->
        <div
            class="mt-5"
        >
            <div v-if="tab === 'Green Cover'">

            <v-row justify="space-around" class="summaryContainer mt-10">
                <v-col
                    cols="6"
                    class="pa-0 ma-0"
                >
                    <p class="pa-0 ma-0 ml-9 SummarySubHeading">Date for the month of <span class="greenCoverColor text-uppercase">{{month_label}}</span></p>
                </v-col>
                <v-col
                    cols="6"
                    class="pa-0 ma-0"
                >
                    <p class="pa-0 ma-0 SummarySubHeading">Date for the month of 
                    
                    <!-- <v-select
					    v-model="cmpmonth"
                        :items="months"
                        label="Select Date Period"
                        solo
                        class="pa-0 SelectionBox"
                        color= "#893FF2"
                    >
                    </v-select> -->
                    <v-autocomplete
                            v-model="cmpmonth"
                            :items="TableMonths"
                            dense
                            hide-selected
                            hide-detail
                            placeholder="Select Month "
                            @change="changeTo"
                            class="greenCoverColor"
                        >
                    </v-autocomplete>
                    </p>
                </v-col>
            </v-row>

            <!-- 2 Grid Panel -->
            <v-layout class="pa-0 summaryContainer">
                <v-col
                class="pa-1 summaryBox panelBox">
                  <v-row class="greenCoverTM">
                        <v-col
                            class="pa-0"
                            cols="12"
                        >
                            <p class="panelBoxLM greenCoverColor font-weight-black font-italic mb-0 greenCoverPanelText text-h5">Land Cover Classification Area (sqkm)</p>
                        </v-col>  
                    </v-row>

                    <v-row class="mt-10">
                        <v-col
                            class="pa-0"
                            cols="6"
                        >
                            <p class="panelSmallerCategory panelBoxLM greenCoverColorV2">&#9866; Green Cover Area</p>
                        </v-col>
                        <v-col
                            cols="6"
                            class="pa-0"
                            align-self="end"
                        >
                            <p class="text-right panelSmallerCategory panelBoxRM greenCoverColorV2">{{summary.result.green_cover_area}} sqkm</p>
                        </v-col>  
                    </v-row>

                    <v-row >
                        <v-col
                            class="pa-0"
                            cols="6"
                        >
                            <p class="panelSmallerCategory panelBoxLM NonGreenColor">&#9866; Non Green Area</p>
                        </v-col>
                        <v-col
                            cols="6"
                            class="pa-0"
                            align-self="end"
                        >
                            <p class="text-right panelSmallerCategory panelBoxRM NonGreenColor">{{summary.result.non_green_area}} sqkm</p>
                        </v-col>  
                    </v-row>

                    <v-row>
                        <v-col
                            class="pa-0"
                            cols="6"
                        >
                            <p class="panelSmallerCategory panelBoxLM WaterColor">&#9866; Water Area</p>
                        </v-col>
                        <v-col
                            cols="6"
                            class="pa-0"
                            align-self="end"
                        >
                            <p class="text-right panelSmallerCategory panelBoxRM WaterColor">{{summary.result.water_area}} sqkm</p>
                        </v-col>  
                    </v-row>

                    <v-row>
                        <v-col
                            class="pa-0"
                            cols="6"
                        >
                            <p class="panelSmallerCategory panelBoxLM CloudColor">&#9866; Cloud Area</p>
                        </v-col>
                        <v-col
                            cols="6"
                            class="pa-0"
                            align-self="end"
                        >
                            <p class="text-right panelSmallerCategory panelBoxRM CloudColor">{{summary.result.cloud_area}} sqkm</p>
                        </v-col>  
                    </v-row>

                    <v-row class="greenCoverTM">
                        <v-col
                            class="pa-0"
                            cols="12"
                        >
                            <p class="panelBoxLM greenCoverColor font-weight-black font-italic font-weight-bold mb-0 text-h6 pl-3">Green Density Classification Area (sqkm)</p>
                        </v-col>
                    </v-row>

                    <v-row class="mt-10">
                        <v-col
                            class="pa-0"
                            cols="6"
                        >
                            <p class="panelSmallerCategory panelBoxLM HDenseColor">&#9866; Highly Dense</p>
                        </v-col>
                        <v-col
                            cols="6"
                            class="pa-0"
                            align-self="end"
                        >
                            <p class="text-right panelSmallerCategory panelBoxRM HDenseColor">{{summary.green_density[2].value}} sqkm</p>
                        </v-col>  
                    </v-row>

                    <v-row>
                        <v-col
                            class="pa-0"
                            cols="6"
                        >
                            <p class="panelSmallerCategory panelBoxLM DenseColor">&#9866; Dense</p>
                        </v-col>
                        <v-col
                            cols="6"
                            class="pa-0"
                            align-self="end"
                        >
                            <p class="text-right panelSmallerCategory panelBoxRM DenseColor">{{summary.green_density[1].value}} sqkm</p>
                        </v-col>  
                    </v-row>

                    <v-row>
                        <v-col
                            class="pa-0"
                            cols="6"
                        >
                            <p class="panelSmallerCategory panelBoxLM LDenseColor">&#9866; Low Dense</p>
                        </v-col>
                        <v-col
                            cols="6"
                            class="pa-0"
                            align-self="end"
                        >
                            <p class="text-right panelSmallerCategory panelBoxRM LDenseColor">{{summary.green_density[0].value}} sqkm</p>
                        </v-col>  
                    </v-row>
                </v-col>
                <v-col
                class="pa-1 summaryBox panelBox">
                    <v-row class="greenCoverTM">
                        <v-col
                            class="pa-0"
                            cols="12"
                        >
                            <p class="panelBoxLM greenCoverColor font-weight-black font-italic mb-0 greenCoverPanelText text-h5">Land Cover Classification Area (sqkm)</p>
                        </v-col>  
                    </v-row>

                    <v-row class="mt-10">
                        <v-col
                            class="pa-0"
                            cols="6"
                        >
                            <p class="panelSmallerCategory panelBoxLM greenCoverColorV2">&#9866; Green Cover Area</p>
                        </v-col>
                        <v-col
                            cols="6"
                            class="pa-0"
                            align-self="end"
                        >
                            <p class="text-right panelSmallerCategory panelBoxRM greenCoverColorV2">{{summary.compare_result.green_cover_area}} sqkm</p>
                        </v-col>  
                    </v-row>

                    <v-row>
                        <v-col
                            class="pa-0"
                            cols="6"
                        >
                            <p class="panelSmallerCategory panelBoxLM NonGreenColor">&#9866; Non Green Area</p>
                        </v-col>
                        <v-col
                            cols="6"
                            class="pa-0"
                            align-self="end"
                        >
                            <p class="text-right panelSmallerCategory panelBoxRM NonGreenColor">{{summary.compare_result.non_green_area}} sqkm</p>
                        </v-col>  
                    </v-row>

                    <v-row>
                        <v-col
                            class="pa-0"
                            cols="6"
                        >
                            <p class="panelSmallerCategory panelBoxLM WaterColor">&#9866; Water Area</p>
                        </v-col>
                        <v-col
                            cols="6"
                            class="pa-0"
                            align-self="end"
                        >
                            <p class="text-right panelSmallerCategory panelBoxRM WaterColor">{{summary.compare_result.water_area}} sqkm</p>
                        </v-col>  
                    </v-row>

                    <v-row>
                        <v-col
                            class="pa-0"
                            cols="6"
                        >
                            <p class="panelSmallerCategory panelBoxLM CloudColor">&#9866; Cloud Area</p>
                        </v-col>
                        <v-col
                            cols="6"
                            class="pa-0"
                            align-self="end"
                        >
                            <p class="text-right panelSmallerCategory panelBoxRM CloudColor">{{summary.compare_result.cloud_area}} sqkm</p>
                        </v-col>  
                    </v-row>

                    <v-row class="greenCoverTM">
                        <v-col
                            class="pa-0"
                            cols="12"
                        >
                            <p class="panelBoxLM greenCoverColor font-weight-black font-italic mb-0 text-h6 pl-3">Green Density Classification Area (sqkm)</p>
                        </v-col>
                    </v-row>

                    <v-row class="mt-10">
                        <v-col
                            class="pa-0"
                            cols="6"
                        >
                            <p class="panelSmallerCategory panelBoxLM HDenseColor">&#9866; Highly Dense</p>
                        </v-col>
                        <v-col
                            cols="6"
                            class="pa-0"
                            align-self="end"
                        >
                            <p class="text-right panelSmallerCategory panelBoxRM HDenseColor">{{summary.compare_green_density[2].value}} sqkm</p>
                        </v-col>  
                    </v-row>

                    <v-row>
                        <v-col
                            class="pa-0"
                            cols="6"
                        >
                            <p class="panelSmallerCategory panelBoxLM DenseColor">&#9866; Dense</p>
                        </v-col>
                        <v-col
                            cols="6"
                            class="pa-0"
                            align-self="end"
                        >
                            <p class="text-right panelSmallerCategory panelBoxRM DenseColor">{{summary.compare_green_density[1].value}} sqkm</p>
                        </v-col>  
                    </v-row>

                    <v-row>
                        <v-col
                            class="pa-0"
                            cols="6"
                        >
                            <p class="panelSmallerCategory panelBoxLM LDenseColor">&#9866; Low Dense</p>
                        </v-col>
                        <v-col
                            cols="6"
                            class="pa-0"
                            align-self="end"
                        >
                            <p class="text-right panelSmallerCategory panelBoxRM LDenseColor">{{summary.compare_green_density[0].value}} sqkm</p>
                        </v-col>  
                    </v-row>
                </v-col>
            </v-layout>

            <p class="ml-6 mt-2 SummarySubHeading">Compared changes between <span class="greenCoverColor">{{cmpmonth.toUpperCase()}}</span> &#38; <span class="greenCoverColor">{{month_label}}</span></p>

           <!-- <p>{{summary}}</p> -->
            <v-card 
            height="285px"
            class="summaryBox pa-2 topSectionBox mx-2 mt-3">
                <v-layout>
                    <v-col 
                    cols="6"
                    class="ma-0 pa-0">
                        <v-row
                        class="my-3"
                        >
                            <v-col
                            cols="6"
                            class="ma-0 pa-0"
                            >
                            <p class="ma-0 pa-0 text-center text-body2">Area Changes in<br>
                                <span class="greenCoverColorV2 text-uppercase text-h6">GREEN COVER</span>
                            </p>
                            </v-col>
                            
                            <v-col
                            cols="6"
                            class="ma-0 pa-0 justify-space-around alignColumn"
                            >
                                <p class="ma-0 pa-0 rowOneIndicator">{{summary.green_cover_pc}}%
                                   
                                </p>

                                 <v-icon 
                                        v-if="summary.green_cover_pc < 0"
                                        style="vertical-align: top;"
                                        color= "#860030"
                                        class="mdi-rotate-180 mdi-36px"
                                    >mdi-triangle
                                    </v-icon>
                                    <v-icon 
                                        v-else
                                        style="vertical-align: top;"
                                        color= "#4DA76B "
                                        class="mdi-36px"
                                    >mdi-triangle
                                    </v-icon>
                            </v-col>
                        </v-row>

                        <v-row
                        class="my-3"
                        >
                            <v-col
                            cols="6"
                            class="ma-0 pa-0"
                            >
                            <p class="ma-0 pa-0 text-center text-body2">Area Changes in<br>
                                <span class="NonGreenColor text-uppercase text-h6">NON GREEN</span>
                            </p>
                            </v-col>
                            
                            <v-col
                            cols="6"
                            class="ma-0 pa-0 justify-space-around alignColumn"
                            >
                                <p class="ma-0 pa-0 rowOneIndicator">{{summary.non_green_pc}}%
                                </p>
                                <v-icon 
                                        v-if="summary.non_green_pc < 0"
                                        style="vertical-align: top;"
                                        color= "#860030"
                                        class="mdi-rotate-180 mdi-36px"
                                    >mdi-triangle
                                    </v-icon>
                                    <v-icon 
                                        v-else
                                        style="vertical-align: top;"
                                        color= "#4DA76B "
                                        class="mdi-36px"
                                    >mdi-triangle
                                </v-icon>
                            </v-col>
                        </v-row>

                        <v-row
                        class="my-3"
                        >
                            <v-col
                            cols="6"
                            class="ma-0 pa-0"
                            >
                            <p class="ma-0 pa-0 text-center text-body2">Area Changes in<br>
                                <span class="HDenseColor text-uppercase text-h6">Highly Dense</span>
                            </p>
                            </v-col>
                            
                            <v-col
                            cols="6"
                            class="ma-0 pa-0 justify-space-around alignColumn"
                            >
                                <p class="ma-0 pa-0 rowOneIndicator">{{summary.highly_dense_pc}}%
                                    
                                </p>
                                <v-icon 
                                        v-if="summary.highly_dense_pc < 0"
                                        style="vertical-align: top;"
                                        color= "#860030"
                                        class="mdi-rotate-180 mdi-36px"
                                    >mdi-triangle
                                    </v-icon>
                                    <v-icon 
                                        v-else
                                        style="vertical-align: top;"
                                        color= "#4DA76B "
                                        class="mdi-36px"
                                    >mdi-triangle
                                    </v-icon>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col 
                    cols="6"
                    class="ma-0 pa-0">
                        <p class="ma-0 pa-0 topSummaryRightPanel my-3 ml-15">
                                    <span class="font-weight-bold">{{summary.green_cover_chg}} sqkm </span>
                                        <v-icon
                                            v-if="summary.green_cover_chg < 0" 
                                            style="vertical-align: top;"
                                            small
                                            color= "#860030"
                                            class="mdi-rotate-180 raiseTriangle"
                                            >mdi-triangle
                                        </v-icon>
                                        <v-icon
                                            v-else
                                            style="vertical-align: top;"
                                            small
                                            color= "#4DA76B"
                                            class="raiseTriangle"
                                            >mdi-triangle
                                        </v-icon>
                                    in <span class="greenCoverColor">Green Cover area</span><br>
                                    compared to <span class="RightTopSummaryDate greenCoverColor">{{compareMonth.name.toUpperCase()}}</span> records
                        </p>

                        <p class="ma-0 pa-0 topSummaryRightPanel my-3 ml-15">
                            
                                    <span class="font-weight-bold">{{summary.non_green_chg}} sqkm </span>
                                        <v-icon
                                            v-if="summary.non_green_chg < 0" 
                                            style="vertical-align: top;"
                                            small
                                            color= "#860030"
                                            class="mdi-rotate-180 raiseTriangle"
                                            >mdi-triangle
                                        </v-icon>
                                        <v-icon
                                            v-else
                                            style="vertical-align: top;"
                                            small
                                            color= "#4DA76B"
                                            class="raiseTriangle"
                                            >mdi-triangle
                                        </v-icon>
                                    
                                    in <span class="NonGreenColor">Non Green area</span><br>
                                    compared to <span class="RightTopSummaryDate greenCoverColor">{{compareMonth.name.toUpperCase()}}</span> records
                        </p>

                        <p class="ma-0 pa-0 topSummaryRightPanel my-3 ml-15">
                            
                                    <span class="font-weight-bold">{{summary.highly_dense_chg}} sqkm </span>
                                        <v-icon
                                            v-if="summary.highly_dense_chg < 0" 
                                            style="vertical-align: top;"
                                            small
                                            color= "#860030"
                                            class="mdi-rotate-180 raiseTriangle"
                                            >mdi-triangle
                                        </v-icon>
                                        <v-icon
                                            v-else
                                            style="vertical-align: top;"
                                            small
                                            color= "#4DA76B"
                                            class="raiseTriangle"
                                            >mdi-triangle
                                        </v-icon>
                                    
                                    in <span class="HDenseColor">Highly Dense</span><br>
                                    compared to <span class="RightTopSummaryDate greenCoverColor">{{compareMonth.name.toUpperCase()}}</span> records
                        </p>
                    </v-col>
                    
                </v-layout>
            </v-card>
            </div>

            <div v-if="tab === 'Top 10'" style="width: 100%; padding: 0 16px;">

                <div class="topHeader">
                    <p style="color:black;" class="ml-4 mt-2 mb-0 pl-2"><span class="font-weight-bold text-uppercase">Top 10 change results</span> for 
                        <!-- <span class="greenCoverColor font-weight-bold">{{months[month - 1]}} 2021</span>  -->
                        <span class="greenCoverColor font-weight-bold">{{aoi.name}}</span>
                        for the month of 
                        <!-- <span class="greenCoverColor font-weight-bold">OCTOBER</span>  -->
                        <v-autocomplete
                            v-model="dateto_top10_label"
                            :items="TableMonths"
                            dense
                            hide-selected
                            hide-detail
                            placeholder="Select Month "
                            @change="changeTo_top10"
                            class="greenCoverColor top10DatePicker ml-2"
                            hide-details
                            item-color="#893FF2"
                        >
                        </v-autocomplete>
                        </p>
                <v-btn-toggle
                    v-model="resultMode"
                    mandatory
                    group
                    class="ml-5 justify-space-around mt-4 cateBtnList"
                >
                    <v-btn 
                        style="border-radius: 7px;"
                        class="mx-2 topBtn greenCoverColor align-self-center" height="30" 
                        value="topTenGreen"
                        :style="{'background': resultMode === 'topTenGreen'? '#FFFFFF !important' : 'rgba(255, 255, 255, 0.22)', 'color': resultMode === 'topTenGreen'? '#000000 !important' : '#893FF2 !important'}">
                    <span style="padding-top: 3px; font-size: 12px" class="py-0">Top 10 Green                                    
                            <v-icon class="raiseTriangle translateTri" color="green">mdi-triangle
                            </v-icon>
                        </span>
                    </v-btn>

                    <v-btn 
                        style="border-radius: 7px;"
                        class="mx-2 topBtn greenCoverColor align-self-center" height="30" 
                        value="btmTenGreen"
                        :style="{'background': resultMode === 'btmTenGreen'? '#FFFFFF !important' : 'rgba(255, 255, 255, 0.22)', 'color': resultMode === 'btmTenGreen'? '#000000 !important' : '#893FF2 !important'}">
                    <span style="padding-top: 3px; font-size: 12px" class="py-0">Top 10 Green <v-icon class="raiseTriangle translateTri mdi-rotate-180" color="red">mdi-triangle
                            </v-icon>
                            </span>
                    </v-btn>
                    <v-btn 
                        style="border-radius: 7px;"
                        class="mx-2 topBtn greenCoverColor" height="30" 
                        value="topTenNGreen"
                        :style="{'background': resultMode === 'topTenNGreen'? '#FFFFFF !important' : 'rgba(255, 255, 255, 0.22)', 'color': resultMode === 'topTenNGreen'? '#000000 !important' : '#893FF2 !important'}">
                    <span style="padding-top: 3px; font-size: 12px" class="py-0">Top 10 Non Green <v-icon class="raiseTriangle translateTri" color="green">mdi-triangle
                            </v-icon></span>
                    </v-btn>
                    <v-btn 
                        style="border-radius: 7px;"
                        class="mx-2 topBtn greenCoverColor" height="30" 
                        value="btmTenNGreen"
                        :style="{'background': resultMode === 'btmTenNGreen'? '#FFFFFF !important' : 'rgba(255, 255, 255, 0.22)', 'color': resultMode === 'btmTenNGreen'? '#000000 !important' : '#893FF2 !important'}">
                    <span style="padding-top: 3px; font-size: 12px" class="py-0">Top 10 Non Green <v-icon class="raiseTriangle translateTri mdi-rotate-180" color="red">mdi-triangle
                            </v-icon></span>
                    </v-btn>
                </v-btn-toggle>
            </div>
                <v-data-table
                    :headers="(resultMode === 'topTenGreen' || resultMode === 'btmTenGreen') ? green_headers : nongreen_headers"
                    :items="topStats_sorted"
                    class="elevation-1"
                    disable-pagination
                    hide-default-footer
					@click:row="row_click"
                >
                    <template v-slot:item.change="{ item }">
                        {{item.change}}
                        <v-icon v-if="item.change > 10" color="green">mdi-triangle</v-icon> 
                        <v-icon v-else color="red" class="mdi-rotate-180">mdi-triangle</v-icon>
                    </template>
                </v-data-table>
            </div>
        </div>

    </v-container>
</template> 

<script>
import aois_info0 from '@/assets/aois-overview.json'
import {getDataDashBoard} from "@/backend"
import area from'@turf/area'
import * as turf from '@turf/turf'

export default {
     props: {
         summary: {
             type: Object,
             required: true
         },
	month: {
             type: Number,
             required: true
         },
    falseMth: {
            type: Number,
            required: true
        },
	compareMonth: {
             type: Object,
             required: true
         },
	tab_aoi: {
             type: Object,
             required: true
         },

	aois_detail: {
             type: Object,
             required: true
         }
    },
  created () {
    this.cmpmonth_model = this.compareMonth
  },	
    data() {
        return {
		aois_info : [],
		aoi : {},
		render : false,
		chartelem : undefined,
		legendelem : undefined,
		aoi_ids : [],
			cmpmonth_model : undefined,
            tab: 'Green Cover',
            months: ['January','February','March','April','May','June','July','August','September','October','November','December'],
            TableMonths: ['September','October','November'],
			dateto_top10_label : 'October',
			dateto_top10 : '10',
            dateto: null,
            resultMode: '',
            valid: '',
            green_headers: [
                {
                text: 'AOI NAME',
                align: 'start',
                sortable: false,
                value: 'aoi',
                },
                { text: 'PROVINCE', value: 'prov' },
                { text: 'TYPE OF MINE', value: 'mine' },
                { text: 'CLOUD % FROM', value: 'from_cloud_pc' },
                { text: 'CLOUD % TO', value: 'to_cloud_pc' },
                { text: '% CHANGE', value: 'green_chg_pc' }
               

            ],
			   nongreen_headers: [
                {
                text: 'AOI NAME',
                align: 'start',
                sortable: false,
                value: 'aoi',
                },
                { text: 'PROVINCE', value: 'prov' },
                { text: 'TYPE OF MINE', value: 'mine' },
                { text: 'CLOUD % FROM', value: 'from_cloud_pc' },
                { text: 'CLOUD % TO', value: 'to_cloud_pc' },
                { text: '% CHANGE', value: 'nongreen_chg_pc' }
               

            ],

            topStats: [],
            
        }
    },
    watch: {
	tab_aoi(val) {
	this.changeAOI(val)
	},
    compareMonth (val) {
      this.cmpmonth_model = val
      this.dateto = this.compareMonth;
    },
    month: {
        deep: true,
        handler: function(){
            // console.log('Prop Changed: ', newVal)
            this.changeTo_top10();
        }
    },
    falseMth: {
        deep: true,
        handler: function(newVal, oldVal){
            // console.log('Prop Changed: ', newVal)
            console.log('New: ',newVal, ' Old: ', oldVal);
            // this.month_label = this.months[newVal - 1];
        }
    }
  },
 mounted () {
 
 this.chartelem = document.getElementById('myChart')
 this.legendelem = document.getElementById('legend-container')
 
 
  this.aois_info = []
  
 aois_info0.data.forEach(a => {
  
  var polygon = turf.polygon(a.geometry.features[0].geometry.coordinates)


 a.area = Number((area(polygon)/1000000.0).toFixed(2))

 this.aois_info.push(a)
 }
 )
 
 
  //setTimeout(this.clear_chart,500)
  setTimeout(this.changeAOI,500)
 
 },
    computed: {
	topStats_sorted() {
	  switch (this.resultMode) {
      case 'topTenGreen':
	  this.topStats.sort(function(a,b) { 
          return b.green_chg_pc - a.green_chg_pc 
          })
	  break
      case 'btmTenGreen':
	  this.topStats.sort(function(a,b) { 
          return a.green_chg_pc - b.green_chg_pc 
          })
	  break
	  case 'topTenNGreen':
	  this.topStats.sort(function(a,b) { 
          return b.nongreen_chg_pc - a.nongreen_chg_pc 
          })
	  break
      case 'btmTenNGreen':
	  this.topStats.sort(function(a,b) { 
          return a.nongreen_chg_pc - b.nongreen_chg_pc 
          })
	  break
    }
    // console.log('Green Header: ',this.green_headers)
    // console.log('None Green Header: ',this.nongreen_headers)
    console.log(this.dateto_top10_label)
    
    if (this.dateto_top10_label && this.month){
        this.green_headers[3].text = 'CLOUD % FROM ' + this.months[this.month - 1]
        this.green_headers[4].text = 'CLOUD % TO ' + this.dateto_top10_label
        this.nongreen_headers[3].text = 'CLOUD % FROM ' + this.months[this.month - 1]
        this.nongreen_headers[4].text = 'CLOUD % TO ' + this.dateto_top10_label
    }
	return this.topStats.slice(0,10);
	
	},	
	   cmpmonth: {
      get () {
        return this.cmpmonth_model.name
      },
      set (val) {
        this.cmpmonth_model.name = val
		var i = 1
		this.months.forEach((m) => {
		if (m == val)
		{
		this.cmpmonth_model.value = (i < 10 ? '0' : '') + String(i)
		}
		
		i = i + 1
		})
		
        this.$emit('input', this.cmpmonth_model)
      }
    },
    month_label () {
  
		// var idx = Number(this.month) - 1
		
		// if (idx < 1)
		// idx = 12
        // console.log('FalseMth: ', this.falseMth)
      
      return this.months[this.falseMth - 1].toUpperCase() 
    }
    },
    methods: {
	row_click(e) {
	this.$emit('getData_from_Tab',{aoi_id : e.id , geometry: e.geometry , month : this.month, compareMonth :this.dateto_top10})
	//this.getData_from_Tab(e.id,this.month1,this.dateto_top10)
	},
	clear_chart(){
	this.$emit('clear_Chart_Map')
	},
	changeTo_top10(){
	
	 var hash = {'September' : '09','October' : '10' , 'November' : '11'}
	 
	this.dateto_top10 = hash[this.dateto_top10_label]
	
	this.getData()
	},
	changeAOI(item) {
		if (!item)
	item = {name:'All Mine AOI'}
	
	this.aoi = item
	
	 this.aoi_ids = []
	 
	 var area1 = 0
		 
	   if (item.name == 'Mineral' || item.name == 'Batu Bara')
	   {
					this.aois_info.forEach(a =>
						   {
						   if (a.type == item.name)
						   {
					   area1 = area1 + a.area
						this.aoi_ids.push(a.id)
						   
						   }
						   
						   })
	   }
	   else
	   {
					   this.aois_info.forEach(a =>
					   {
					   if (a.province == item.name || item.name == 'All Mine AOI')
					   {
					  area1 = area1 + a.area
						 this.aoi_ids.push(a.id)
					   }
					   })
	   }
	   
this.$emit('set_aoi_area1', area1.toFixed(2))	   
	
       this.getData()	   
    },
   async getData () {
   /*
			if (this.render)
			{
   				this.chartelem.style.display = 'none'
				this.legendelem.style.display = 'none'
			this.$emit('clear_Chart_Map')	
			}			
			*/
      try {
       // this.loading = true
	//  console.log(this.aoi_ids)
	//   console.log(this.aoi_ids)
        if (this.aoi_ids.length < 1 || !this.dateto_top10 )
		return
		
		//this.$emit('clear_Chart_Map')
		
        let month = null
        month = this.month < 10 ? `0${this.month}` : this.month
		
		var payload = {
          month: month,
          compare_month: this.dateto_top10,
          year: '2021',
          source: 'planetscope' 
        }
		
		var i = 0
		this.aoi_ids.forEach(id => {
		payload["aoi_ids[" + String(i) + "]"] = id
		
		i = i + 1
		}
		
		)
		
		  
        var res = await getDataDashBoard(payload)
		
	this.topStats = []
	res.data.data.forEach(a => {
	
		if (!a.aoi_id)
			return
	
	var aoi_id = a.aoi_id

	var item = {	
	geometry: this.aois_detail[aoi_id].geometry.features[0].geometry,
	id : aoi_id,
	aoi : this.aois_detail[aoi_id].name,
	area : this.aois_detail[aoi_id].area,
	prov : this.aois_detail[aoi_id].province,
	mine : this.aois_detail[aoi_id].type
	}
	
		if (a.result && a.compare_result)
		{
		item.green_chg_pc =(100.0 * (a.result.green_cover_area - a.compare_result.green_cover_area)/a.compare_result.green_cover_area).toFixed(2)
	   item.nongreen_chg_pc =(100.0 * (a.result.non_green_area - a.compare_result.non_green_area)/a.compare_result.non_green_area).toFixed(2)
	   item.from_cloud_pc = (100.0 * a.compare_result.cloud_area/item.area).toFixed(2)
	   item.to_cloud_pc = (100.0 * a.result.cloud_area/item.area).toFixed(2)
	 
	   }
	 
this.topStats.push(item)	 
	
})	

this.$emit('zoomTo_Tab',this.aoi.name)

this.$emit('getData_overview',{result_data :res.data.data , payload: payload})

      } 
	  catch (e) {
         console.log(e.message)
		console.log(e.stack)
        this.loading = false
      } finally {
        this.loading = false
      }
    },	
        Tab(x){
            this.tab = x;
            console.log(this.tab)
        },
        changeTab(tab){
            // console.log('From Tab Btn:', tab.target.innerText)
            let event = tab.target.innerText.trim()
            // console.log('event Text: ',tab.target.innerText)

            //let render = false
            if (event === 'GREEN COVER & GREEN DENSITY'){
			 this.chartelem.style.display = 'block'
			 this.legendelem.style.display = 'block'
                this.render = false;
                this.$emit('tabChange',this.render)
				  

            }
            else if (event === 'TOP 10 CHANGE RESULTS'){
				//this.chartelem.style.display = 'none'
				//this.legendelem.style.display = 'none'
				//this.$emit('clear_Chart_Map')
                this.render = true;
				
                this.$emit('tabChange', this.render);

				this.changeAOI()
				
            }
            
            // console.log('From Tab Model:', this.tab)
        },
        changeTo(){
		    this.green_headers[4].text = 'Change % '+ this.dateto
            this.green_headers[3].text = 'Change % '+ this.months[this.month - 1]
		    this.nongreen_headers[4].text = 'Change % '+ this.dateto
            this.nongreen_headers[3].text = 'Change % '+ this.months[this.month - 1]           
        }
    },
    
}
</script>
<style scoped>

/* For position triangle arrow */
.raiseTriangle {
    position: relative;
    bottom: 5px;
}
/* Date Border bottom */
.RightTopSummaryDate{
 border-bottom: dashed 1px black ;
}

/* Right Panel Text styling */
.topSummaryRightPanel{
    font-size: 19.8349px;
    line-height: 23px;
}

/* For Percentage font sizing */
.rowOneIndicator{
    font-size: 40px;
    line-height: 47px;
}

/* Tab Buttons at the top */
.tabBtn{
 padding: 0 8px;
 box-shadow: none;
}
.tabBtn:active{
    color: #893FF2;
    border-radius: 2px;
    border-top: solid 2px #893FF2;
}

/* Frame for White box */
.summaryBox{
background: #FFFFFF;
box-shadow: 1px 2px 2px rgba(128, 124, 133, 0.35), inset 0px 0px 4px rgba(137, 63, 242, 0.33);
border-radius: 11px;

}

/* Maintaining width to not have x scrollbar in left panel */
.summaryContainer{
    width: calc(100% - 4px);
}

/* 2 Grid Panel Box Height */
.panelBox{
    height: 370px;
    margin: 0 5px;
}

/* Category Text Colour */
.greenCoverColor {
    color: #893FF2;
    /* color: #228b22; */
}
.greenCoverColorV2 {
    /* color: #893FF2; */
    color: #228b22;
}

.NonGreenColor {
    /* color: #9C7A00; */
    color: #e47a10;
}
.WaterColor{
    /* color: #1F7BB0; */
    color: #11b9db;
}
.CloudColor {
    /* color: #D17E00; */
    color: #9e9b9b;
}

.HDenseColor{
    /* color: #04752B; */
    color: #4caf50;
}
.DenseColor{
    /* color: #28ECB5; */
    color: #8bc34a;
}
.LDenseColor{
    /* color: #D10950; */
    /* color: #ff0000; */
    color: #9b2121;
}


/* Left Panel text sizing of 2 grid panel */
.greenCoverPanelText{
    font-size: 19px;
    line-height: 23px;
}

/* 2 grid number figure */
.greenCoverFigure {
    font-size: 54px;
    line-height: 45px;
    letter-spacing: 0.03em;
}

.greenCoverTM {
    margin-top: 28px;
}

.panelBoxLM{
    margin-left: 30px !important;
    
}
.panelBoxRM{
    margin-right: 30px !important;
}

/* 2 Grid Panel Listing font sizing */
.panelSmallerCategory {
    margin: 4px 0;
    font-size: 15.68px;
    line-height: 18px;
}

.SummarySubHeading{
    font-size: 15px;
line-height: 18px;
letter-spacing: 0.05em;
}

.SelectionBox{
    box-shadow: 1px 2px 2px rgba(128, 124, 133, 0.2), inset 0px 0px 4px rgba(137, 63, 242, 0.33);
    border-radius: 7px;
}

.topSectionBox{
    padding: 25px 15px 10px !important;
}
.topBtn{
    background: rgba(255, 255, 255, 0.22);
    box-shadow: 1px 2px 2px rgba(128, 124, 133, 0.35), inset 0px 0px 4px rgba(137, 63, 242, 0.33);
    border:solid 1px #893FF2;
    border-radius: 7px !important;
}
button.topBtn.v-btn.v-btn--active{
    color: #893FF2  !important;
}

button.tabBtn.v-btn.v-btn--active{
    border-top: solid 3px #893FF2;
    color: #893FF2  !important;
}

.topHeader[data-v-1c742f4c] {
    background: rgba(137, 63, 242, 0.11);
    border-radius: 7px 7px 0px 0px;
    height: 96px;
    border-left: 1px solid rgba(0, 0, 0, 0.25);
    border-right: 1px solid rgba(0, 0, 0, 0.25);
    border-top: 1px solid rgba(0, 0, 0, 0.25);
}

.theme--light.v-data-table{
    border-radius: 0px 0px 7px 7px;
    border-left: 1px solid rgba(0, 0, 0, 0.25);
    border-right: 1px solid rgba(0, 0, 0, 0.25);
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}

/* .v-btn-toggle--group > .v-btn.v-btn{
    border-color: ;
} */

.v-input.v-input--is-focused.v-input--dense.theme--light.v-text-field.v-text-field--is-booted.v-text-field--placeholder.v-select.v-select--is-menu-active.v-autocomplete.primary--text,
.v-input.greenCoverColor.v-input--dense.theme--light.v-text-field.v-text-field--is-booted.v-text-field--placeholder.v-select.v-autocomplete{
    display: inline-block;
}
.v-select__slot input{
    color: #893FF2 !important;
    font-weight: 700;
    text-transform: uppercase;
}

td.text-start{
    line-height: 12px;
    text-transform: uppercase;
}
.alignColumn{
    display: flex;
}

</style>

<style>
.top10DatePicker .v-text-field__details{
    display: none;
}

.top10DatePicker .v-input__control{
    background: white;
    border-radius: 7px;
    color: #893FF2;
}

.cateBtnList > .v-btn.v-btn{
    background: rgba(255, 255, 255, 0.22) !important;
    box-shadow: 1px 2px 2px rgba(128, 124, 133, 0.35), inset 0px 0px 4px rgba(137, 63, 242, 0.33) !important;
    border-radius: 7px;
}

i.v-icon.notranslate.raiseTriangle.mdi.mdi-triangle {
    transform: translate(5px, 5px);
}

th.text-start span{
    color: #893FF2;
}

.topBtn:before{
    background-color: white !important;
}
</style>