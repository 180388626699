<template>
  <div class="box">
    <section>
      <date-picker
        v-model = 'date'
        value-type="format" 
        title-format="titleformat"
        type="month" 
        range-separator=' - '
        range placeholder="Select Month Range" 
        @change="DateSelected"></date-picker>
    </section>
  </div>
</template>

<script>
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';

  export default {
    components: { DatePicker },
    props: [
        // 'pickertype',
        // 'titleformat',
        // 'dateformat'
    ],
    mounted() {
    },
    data() {
      return {
        date: [new Date(2019, 9, 8), new Date(2019, 9, 19)],
        // DatePicker : this.pickertype
        // mth: [new Date(), new Date()]
      };
    },
    methods: {
       DateSelected(){
            // console.log(this.date)
            this.$emit('changedDate', this.date)
        },
        calenderChange(x,y,z){
            // console.log('Changed')
                    // @calendar-change="calenderChange"
                   // console.log('Icon Clickers',this.date)
                    //console.log('Date: ', x); //Returns caldendar range of new event(click)
                    //console.log('Old Date: ', y); //Returns date of previous calender range
                    //console.log('Type: ', z); //Return type of event trigger 
                    var value = new Date(x)
                    var newValue = value.setMonth(value.getMonth()+1)
                    this.date[1] = new Date(newValue);
                    console.log(this.date)      
        },
        panelChange(){
            console.log('Panel Changed')
            //  @panel-change="panelChange"
        }
    }
  };
</script>

<style scoped>
div.mx-range-wrapper div.mx-calendar.mx-calendar-panel-date:nth-child(1) .mx-calendar-header button.mx-btn-icon-double-right,
div.mx-range-wrapper div.mx-calendar.mx-calendar-panel-date:nth-child(1) .mx-calendar-header button.mx-btn-icon-right,
div.mx-range-wrapper div.mx-calendar.mx-calendar-panel-date:nth-child(2) .mx-calendar-header button.mx-btn-icon-left,
div.mx-range-wrapper div.mx-calendar.mx-calendar-panel-date:nth-child(2) .mx-calendar-header button.mx-btn-icon-double-left
{
    display:none
}

.mx-datepicker-range{
  width: 170px;
}

</style>