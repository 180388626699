<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        icon
      >
        <v-icon>mdi-earth</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="(item, index) in langs"
        :key="index"
        @click="selectLanguage(item)"
      >
        <v-list-item-title>{{ item.name }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { langs } from '@/plugins/i18n/message'
import { setLocale } from '@/plugins/i18n'
export default {
  computed: {
    langs () {
      return langs;
    }
  },

  methods: {
    selectLanguage(item) {
      setLocale(item.code)
    }
  }
}
</script>
