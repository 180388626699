import Vue from "vue";
import Vuetify from "vuetify";
import colors from "vuetify/es5/util/colors";
import en from 'vuetify/es5/locale/en'
import vi from './vi'

Vue.use(Vuetify);

const currentLocale = localStorage.getItem('locale') || 'en'
const currentDark = Boolean(localStorage.getItem('dark')) || false

const options = {
  icons: {
    iconfont: "mdi"
  },
  theme: {
    options: { customProperties: true },
    dark: currentDark,
    themes: {
      light: {
        bar: '#314555',
        primary: '#314555',
        accent: '#526c80',
        tab: '#6686a3',
        secondary: '#f2f8fc',
        backgroundInput: '#F1F9FF',
        background: '#fff',
        info: colors.teal.lighten1,
        warning: colors.amber.darken2,
        error: colors.red.darken2,
        success: colors.green.darken2
      },
      dark: {
        bar: '#37474f',
        primary: '#37474f',
        accent: '#455a64',
        secondary: '#546e7a',
        tab: '#455a64',
        backgroundInput: '#546e7a',
        background: colors.grey.darken2,
        info: colors.teal.lighten1,
        warning: colors.amber.base,
        error: colors.red.darken2,
        success: colors.green.accent3
      }
    }
  },
  lang: {
    locales: {en, vi},
    current: currentLocale
  }
};

export const vuetify = new Vuetify(options);

export function toggleDark() {
  vuetify.framework.theme.dark = !vuetify.framework.theme.dark;
  localStorage.setItem('dark', vuetify.framework.theme.dark ? 'true': '')
}
