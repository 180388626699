<template>
  <v-app>
    <div style="height: 100vh" :style="{'background-image': `url(${require('@/assets/images/bau-troi-day-sao.webp')}`}">
      <v-layout class="py-5 justify-center align-center" column style="height: 100%;">
        <span style="font-size: 60px; font-weight: bold; color: white">Change Password</span>
        <v-form @submit="changePassword" onSubmit="return false;" v-model="valid" ref="formChangePass">
          <div style="width: 500px">
            <v-text-field
                height="60"
                dark
                flat
                solo-inverted
                :type="isShowPassword ? 'text' : 'password'"
                v-model="password"
                label="Password"
                placeholder="Password"
                :append-icon="isShowPassword ? 'mdi-eye-off' : 'mdi-eye'"
                @click:append="isShowPassword = !isShowPassword"
                :rules="[rules.requiredPassword]"
            >
            </v-text-field>
          </div>
          <div style="width: 500px">
            <v-text-field
                @input="checkInvalidPassword"
                :disabled="!password"
                height="60"
                dark
                flat
                solo-inverted
                :type="isShowPassword ? 'text' : 'password'"
                v-model="confirmPassword"
                label="Confirm Password"
                placeholder="Confirm Password"
                :append-icon="isShowPassword ? 'mdi-eye-off' : 'mdi-eye'"
                @click:append="isShowPassword = !isShowPassword"
                :rules="[rules.requiredConfirmPassword]"
                :error-messages="errors.password"
            >
            </v-text-field>
          </div>
          <div style="width: 500px; text-align: center">
            <v-btn color="accent" width="100%" height="60" type="submit">Submit</v-btn>
          </div>
        </v-form>
      </v-layout>
    </div>
  </v-app>
</template>

<script>
import {
  resetPassword
} from "@/backend";
import config from '@/config.json'
import AvatarPopOver from "@/components/AvatarPopOver";
import {mapState} from "@/store/ults";
import api from "@/api";
export default {
  components: {
    AvatarPopOver
  },
  data: () => ({
    loading: false,
    valid: false,
    password: undefined,
    confirmPassword: undefined,
    isShowPassword: false,
    rules: {
      requiredPassword: value => (!!value && !!value.trim()) || 'Password is invalid',
      requiredConfirmPassword: value => (!!value && !!value.trim()) || 'Password is invalid'
    },
    errors: {
      password: undefined,
    }
  }),
  computed: {
    ...mapState("auth", ["currentUser"]),
  },
  created () {
    // const token = api.getToken()
    // if (token) this.checkTokenExist()
  },
  methods: {
    checkInvalidPassword () {
      if (this.password !== this.confirmPassword) return (this.errors.password = 'Password invalid')
      else return (this.errors.password = undefined)
    },
    async changePassword () {
      this.$refs.formChangePass.validate()
      if (!this.confirmPassword) return (this.errors.password = 'Password invalid')
      if (!this.valid || this.errors.password) return
      try {
        this.loading = true
        let email = this.$route.query.email
        let token = this.$route.query.token
        if (!email) return (alert('Email is not define'))
        if (!token) return (alert('Token is not define'))
        const res = await resetPassword({
          email: email,
          password: this.password,
          password_confirmation: this.confirmPassword,
          token: token
        })
        location.href = `${location.origin}/signIn`
      } catch (e) {
        alert(e.message)
      } finally {
        this.loading = false
      }
    },
    goToLink(link) {
      if (this.$route.path !== link) this.$router.push({ path: link });
    }
  }
};
</script>
<style scoped>
@media (prefers-reduced-motion: no-preference) {
  .square-animation {
    animation: wipe-enter 1s 1;
  }
}
.before-enter {
  opacity: 0;
  transform: scale(.5) rotateZ(-25deg);
  transition: all 1s ease-out;
}
.enter {
  opacity: 1;
  transform: scale(1) rotateZ(0deg);
}
.header {
  height: 800px;
}
.content {
  height: 100vh
}
.join-button {
  display: flex;
}

@media only screen and (max-width: 1100px) {
  .join-button {
    display: none;
  }
}
@media only screen and (max-height: 800px) {
  .header {
    height: 700px;
  }
}
</style>
<style>
.custom-nav .v-navigation-drawer__content {
  overflow-y: hidden;
}

.main-layout .v-list-group__header .v-list-item {
  padding-left: 0;
}
.main-layout .custom-list-item {
  padding-left: 30px;
}
</style>
<style>
html,
body,
.app {
}
</style>
