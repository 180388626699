<template>
  <router-view/>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>
<style>
.mapboxgl-ctrl-logo{
  display: none !important;
}
.map-info {
  color: #893FF2 !important;
  border: 1px #893FF2 solid !important;
}
.map-info .v-icon {
  color: #893FF2 !important;
}
.mapboxgl-ctrl-scale {
  border: 1px #893FF2 solid !important;
  color: #893FF2 !important;
}
</style>
