<template>
  <v-card flat style="height: calc(100vh - 120px); background: #f4ecfd; border-radius: 0px">
    <v-overlay :value="loading" absolute>
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-card-text class="fill-height" style="border-radius: 10px; padding: 0px 36px 16px 36px">
        <div class="mr-6" style="border-radius: 10px; width: 100%; height: 100%; border: 1px solid rgba(137, 63, 242, 0.2); box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.22); background-color: #fff">
          <div style="border-top-right-radius: 10px; border-top-left-radius: 10px; height: 70px; border: 1px solid rgba(137, 63, 242, 0.2); box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.22); background: linear-gradient(180deg, #EFEFEF 76.97%, rgba(227, 227, 227, 0.28) 100%)" class="px-0 py-1">
            
            <v-layout class="fill-height">
              <div class="fill-height" style="width: 50%">
                <!-- AOI Overview Dropdown Menu -->
                <v-layout class="fill-height px-5" align-center>
                  <span style="font-size: 0.8vw; font-weight: 700;">Overview of Changes in </span>
                  <div style="width: 300px" class="pl-3">
                    <!-- Dropdown Menu itself -->
                  
                        <span v-if="dropdown === false">
                            <allDropdown @change="changeAOI"></allDropdown>
                        </span>
                        <span v-else-if="dropdown === true">
                            <TopDropdown @change="changeAOI_top10"></TopDropdown>
                        </span>
                  </div>

                  <span class="font-weight-black ml-8">AREA <span class="mx-1">{{dropdown ? aoi_area1 : aoi_area}}</span> sqkm<span>&#178;</span></span>
                </v-layout>
              </div>
              <!-- MonthPicker Slider -->
              <div class="flex fill-height pl-3">
                <v-layout class="fill-height" align-center>
                  <span class="mr-4" style="color: #893FF2; font-size: 13px; font-weight: 700"> Jan 2021</span>
                  <v-card style="border-radius: 8px; margin-top: -10px" width="100%" height="32" class="px-2">
                    <!-- Scrollable Slider -->
                    <v-slider
						v-if="!dropdown"
                        @change="getData"
                        class="monthRanger"
                        color="#893FF2"
                        v-model="month"
                        :tick-labels="monthLabels"
                        :max="12"
                        :min="1"
                        step="1"
                        ticks="always"
                        tick-size="4"
                    ></v-slider>
					    <v-slider
						v-if="dropdown"
                       
                        class="monthRanger"
                        color="#893FF2"
                        v-model="month1"
                        :tick-labels="monthLabels"
                        :max="12"
                        :min="1"
                        step="1"
                        ticks="always"
                        tick-size="4"
                    ></v-slider>
                  </v-card>
                  <span class="ml-4" style="color: #893FF2; font-size: 13px; font-weight: 700"> Dec 2021</span>
                </v-layout>
              </div>
            </v-layout>
          </div>
          <div style="height: calc(100% - 70px);">
            <v-layout class="fill-height pa-3">
              <v-layout class="fill-height pa-3 elevation-2 PanelBox LP-Background" style="border-radius: 10px;">
                <v-layout style="height: 100%; width: 50%; border-radius: 11px 0 0 11px;" class="overflow-y-auto elevation-2" column>
				
    <allTabs 
      :falseMth="month" 
      :aois_detail="aois_detail" 
      :tab_aoi="tab_aoi" 
      @zoomTo_Tab="zoomTo_Tab" 
	  @getData_overview="getData"
      @getData_from_Tab="getData_from_Tab" 
      @clear_Chart_Map="clear_Chart_Map" 
      @set_aoi_area1="set_aoi_area1" 
      :summary="summary" 
      :month="month1" 
      :compareMonth="compareMonth" 
      @input="chgCompareMonth" 
      @tabChange=ChangeTab
    ></allTabs>
	
                </v-layout>
                <div style="height: calc(100% - 5px); width: 50%;" class="ml-6 d-flex flex-column">
                  <div style="width: 100%; height: 50%; border-top: 1px solid lightgray">
                    <v-layout align-center style="height: 45px; background-color: #f4f3f6" class="px-6 elevation-2">
                      <span class="text-header text-uppercase" style="letter-height: 16px; letter-spacing: 0.1em; color: #893FF2; font-weight: 500;">Map Overview</span>
                    </v-layout>
                    <div style="height: calc(100% - 45px);">
                      <BaseMap
                          ref="mapView"
                          :dataSource.sync="dataSource"
                          @getData="getData"
                          @gotoMapView="() => $emit('gotoMapView')"
                      />
                    </div>
                  </div>
                  <div style="width: 100%; height: 50%">
                    <v-layout align-center style="height: 45px; flex: none" class="px-6">
                      <span class="text-header" style="font-size: 1.2vw; color: #893FF2; letter-spacing: 0.1em; line-height: 23px;">DATA GRAPH</span>
                      <v-spacer/>
                      <v-btn icon small color="green" @click="refreshChart"><v-icon>mdi-refresh</v-icon></v-btn>
                    </v-layout>
                    <div class="px-2 flex d-flex" style="height: calc(100% - 45px);" >
                      <v-layout class="fill-height">
                        <v-layout class="fill-height pr-3">
                          <canvas style="height: 100%; width: 100%; flex: none" id="myChart"></canvas>
                        </v-layout>
                        <div id="legend-container" style="width: 33%; height: 100%; overflow-y: auto; flex: none" class="pt-3">
                          <v-expansion-panels multiple flat class="legend-panel">
                            <v-expansion-panel
                                class="py-1 px-1"
                                v-for="(group, i) in groupLegend"
                                :key="i"
                            >
                              <v-expansion-panel-header style="box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.25); border-radius: 7px">
                                <v-layout class="fill-height" align-center :style="{'color': group.legend[0].strokeStyle}">
                                  <!-- <v-icon size="22" class="mr-1" :color="group.legend[0].strokeStyle">mdi-drag</v-icon> -->
                                  <v-checkbox
                                      @click.native.stop="ChangeDisplayGroupLegend(group)"
                                      class="small-radio"
                                      v-model="group.checkAll"
                                      :color="group.legend[0].strokeStyle">
                                  </v-checkbox>
                                  {{group.name}}
                                </v-layout>
                              </v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <v-list dense height="100%" class="py-0">
                                  <v-list-item v-for="(label, index) of group.legend" :key="'legend-'+index" style="min-height: 0;height: 30px" class="px-1" @click="changeDisplayChart(label)">
                                    <v-list-item-action>
                                      <v-icon size="22" v-if="label.hidden" :color="label.fillStyle">mdi-checkbox-blank-outline</v-icon>
                                      <v-icon size="22" v-else :color="label.fillStyle">mdi-checkbox-marked</v-icon>
                                    </v-list-item-action>
<!--                                    <v-list-item-action>-->
<!--                                      <div style="width: 20px; height: 20px; max-height: 30px"-->
<!--                                           :style="{'background-color': label.fillStyle, 'border': `2px solid ${label.strokeStyle}`}">-->
<!--                                      </div>-->
<!--                                    </v-list-item-action>-->
                                    <span class="text-truncate" :title="label.text" style="font-size: 12px" :style="{'color': label.fillStyle, 'text-decoration': label.hidden ? 'line-through' : ''}">{{label.text}}</span>
                                    <v-spacer/>
                                  </v-list-item>
                                </v-list>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                        </div>
                      </v-layout>
                    </div>

                  </div>
                </div>
              </v-layout>
            </v-layout>
          </div>
        </div>
    </v-card-text>
  </v-card>
</template>

<script>
import BaseMap from "@/components/home/dataSource/BaseMap"
import bbox from '@turf/bbox'
import MonthPicker from "@/components/MonthPicker"
import Chart from "chart.js/auto"
import {getAOI,getAOIResults, getDataDashBoard} from "@/backend";
import MonthTransform from "@/ultis/converMonth";
import numberFormat from "@/ultis/comma";
import randomColor from "@/ultis/randomColor";
import allTabs from  "../LeftSidePanel/Tab";
// import Menu from "@/components/home/LeftSidePanel/dropdown";
import allDropdown from "@/components/home/mainMenu";
import area from '@turf/area'
import * as turf from '@turf/turf'
import TopDropdown from "@/components/home/top10Menu";
import aois_info from '@/assets/aois-overview.json'
import aois_geojson from '@/assets/aoisx.json'

let date = new Date()
export default {
  name: "Datasource",
  components: { MonthPicker, BaseMap, allTabs, allDropdown,TopDropdown },
  data () {
    return {
	project_bbox :[97.66289935180629, -3.281951569775302,129.17130064819315,2.1766068558036125],
	province_geom : {},
		chartelem : undefined,
		legendelem : undefined,	
	tab_aoi : {},
	aois_info : [],
	aois_detail : {},
	aoi_ids : [],
	aoi_area : 0,
	aoi_area1 : 0,
	summary : { 
	green_cover_chg : 0, non_green_chg : 0, highly_dense_chg : 0,
		green_cover_pc : 0, non_green_pc : 0, highly_dense_pc : 0,
result: {
            green_cover_area: 0,
     
            area_change: 0,
            water_area: 0,
            non_green_area: 0,
			cloud_area : 0
        },
compare_result: {
			green_cover_area: 0,
           
            area_change: 0,
            water_area: 0,
            non_green_area: 0,
			cloud_area : 0
        },		
       green_density: [
            {
                label: "Open",
                value: 0,
                color: "#F44336",
                change_percent: 0
            },
            {
                label: "Low Dense",
                value: 0,
                color: "#3FF980",
                change_percent: 0
            },
            {
                label: "Slightly Dense",
                value: 0,
                color: "#FFEB3B",
                change_percent: 0
            },
            {
                label: "Dense",
                value: 0,
                color: "#8BC34A",
                change_percent: 0
            },
            {
                label: "Highly Dense",
                value: 0,
                color: "#4CAF50",
                change_percent: 0
            }
        ],
        compare_green_density: [
            {
                label: "Open",
                value: 0,
                color: "#F44336"
            },
            {
                label: "Low Dense",
                value: 0,
                color: "#3FF980"
            },
            {
                label: "Slightly Dense",
                value: 0,
                color: "#FFEB3B"
            },
            {
                label: "Dense",
                value: 0,
                color: "#8BC34A"
            },
            {
                label: "Highly Dense",
                value: 0,
                color: "#4CAF50"
            }
        ]		
		},
      loading: false,
      chartLegend: [],
      monthLabels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      fullMonthLabel:  ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      aoi: undefined,
      aois: [],
      compareMonth: {
        name: 'October',
        value: '10'
      },
      greenCoverData: {},
      dataTableGreenDensity: [],
      dataTablePlantHealth: [],
      data: {},
      month: 11,
	    month1: 11,
      labels: [],
      dropdown: false,
      dropdownOption: false,
      greenCoverChart: undefined,
      canopyCoverChart: undefined,
      chartFilters: [{
        name: 'DISTRICT',
        code: 'district'
      }, {
        name: 'AOI SELECTED',
        code: 'aoi_selected'
      }],
      chartData: [],
      chartType: 'overall_green_cover',
      imageDataChart: undefined,
      recordedFor: '2021-11',
      overViews: ['GREEN COVER'],
      myChart: undefined,
	  groupLegend1:[]
    }
  },
  props: {
    dataSource: {}
  },
  mounted () {

this.province_geom["Papua"] = {"type":"Polygon","coordinates":[[[134.295669556,-9.118924141],[134.295669556,0.937796652],[141.019393921,0.937796652],[141.019393921,-9.118924141],[134.295669556,-9.118924141]]]}
this.province_geom["Riau"] = {"type":"Polygon","coordinates":[[[100.024940491,-1.122272849],[100.024940491,2.919127941],[103.810150146,2.919127941],[103.810150146,-1.122272849],[100.024940491,-1.122272849]]]}
this.province_geom["Sulawesi Selatan"] = {"type":"Polygon","coordinates":[[[117.038482666,-7.758405209],[117.038482666,-1.895491958],[121.839675903,-1.895491958],[121.839675903,-7.758405209],[117.038482666,-7.758405209]]]}
this.province_geom["Sulawesi Tengah"] = {"type":"Polygon","coordinates":[[[119.447021484,-3.640743494],[119.447021484,1.373923898],[124.181526184,1.373923898],[124.181526184,-3.640743494],[119.447021484,-3.640743494]]]}
this.province_geom["Sulawesi Tenggara"] = {"type":"Polygon","coordinates":[[[120.865684509,-6.213387489],[120.865684509,-2.779382944],[124.621902466,-2.779382944],[124.621902466,-6.213387489],[120.865684509,-6.213387489]]]}
this.province_geom["Sulawesi Utara"] = {"type":"Polygon","coordinates":[[[123.110939026,0.291559964],[123.110939026,5.564164162],[127.163032532,5.564164162],[127.163032532,0.291559964],[123.110939026,0.291559964]]]}
this.province_geom["Kepulauan Bangka Belitung"] = {"type":"Polygon","coordinates":[[[105.108001709,-3.416534901],[105.108001709,-1.50188446],[108.847991943,-1.50188446],[108.847991943,-3.416534901],[105.108001709,-3.416534901]]]}
this.province_geom["Sumatera Barat"] = {"type":"Polygon","coordinates":[[[98.597648621,-3.344361544],[98.597648621,0.906464279],[101.892913818,0.906464279],[101.892913818,-3.344361544],[98.597648621,-3.344361544]]]}
this.province_geom["Sumatera Utara"] = {"type":"Polygon","coordinates":[[[97.06072998,-0.638760507],[97.06072998,4.301465511],[100.422767639,4.301465511],[100.422767639,-0.638760507],[97.06072998,-0.638760507]]]}
this.province_geom["Gorontalo"] = {"type":"Polygon","coordinates":[[[121.161247253,0.305967003],[121.161247253,1.041011095],[123.55229187,1.041011095],[123.55229187,0.305967003],[121.161247253,0.305967003]]]}
this.province_geom["Jakarta Raya"] = {"type":"Polygon","coordinates":[[[106.383178711,-6.369930267],[106.383178711,-5.184457302],[106.968986511,-5.184457302],[106.968986511,-6.369930267],[106.383178711,-6.369930267]]]}
this.province_geom["Bali"] = {"type":"Polygon","coordinates":[[[114.432701111,-8.849252701],[114.432701111,-8.061818123],[115.712036133,-8.061818123],[115.712036133,-8.849252701],[114.432701111,-8.849252701]]]}
this.province_geom["Sulawesi Barat"] = {"type":"Polygon","coordinates":[[[117.069473267,-3.569032669],[117.069473267,-0.846701562],[119.875457764,-0.846701562],[119.875457764,-3.569032669],[117.069473267,-3.569032669]]]}
this.province_geom["Sumatera Selatan"] = {"type":"Polygon","coordinates":[[[102.067451477,-4.960040569],[102.067451477,-1.625441194],[106.188796997,-1.625441194],[106.188796997,-4.960040569],[102.067451477,-4.960040569]]]}
this.province_geom["Yogyakarta"] = {"type":"Polygon","coordinates":[[[110.01474762,-8.203577042],[110.01474762,-7.541841507],[110.834213257,-7.541841507],[110.834213257,-8.203577042],[110.01474762,-8.203577042]]]}
this.province_geom["Banten"] = {"type":"Polygon","coordinates":[[[105.101135254,-7.015110016],[105.101135254,-5.807565689],[106.779937744,-5.807565689],[106.779937744,-7.015110016],[105.101135254,-7.015110016]]]}
this.province_geom["Bengkulu"] = {"type":"Polygon","coordinates":[[[101.022323608,-5.442462444],[101.022323608,-2.27675128],[103.767486572,-2.27675128],[103.767486572,-5.442462444],[101.022323608,-5.442462444]]]}
this.province_geom["Jambi"] = {"type":"Polygon","coordinates":[[[101.128623962,-2.77119875],[101.128623962,-0.733294666],[104.497398376,-0.733294666],[104.497398376,-2.77119875],[101.128623962,-2.77119875]]]}
this.province_geom["Jawa Barat"] = {"type":"Polygon","coordinates":[[[106.370552063,-7.822554588],[106.370552063,-5.914500237],[108.833816528,-5.914500237],[108.833816528,-7.822554588],[106.370552063,-7.822554588]]]}
this.province_geom["Kepulauan Riau"] = {"type":"Polygon","coordinates":[[[103.285240173,-1.298363924],[103.285240173,4.7940135],[109.163963318,4.7940135],[109.163963318,-1.298363924],[103.285240173,-1.298363924]]]}
this.province_geom["Lampung"] = {"type":"Polygon","coordinates":[[[103.571250916,-6.164572716],[103.571250916,-3.723822594],[106.211433411,-3.723822594],[106.211433411,-6.164572716],[103.571250916,-6.164572716]]]}
this.province_geom["Maluku Utara"] = {"type":"Polygon","coordinates":[[[124.283477783,-2.477341175],[124.283477783,2.643977642],[129.653717041,2.643977642],[129.653717041,-2.477341175],[124.283477783,-2.477341175]]]}
this.province_geom["Maluku"] = {"type":"Polygon","coordinates":[[[125.718513489,-8.339374542],[125.718513489,-1.380649567],[134.90838623,-1.380649567],[134.90838623,-8.339374542],[125.718513489,-8.339374542]]]}
this.province_geom["Nusa Tenggara Barat"] = {"type":"Polygon","coordinates":[[[115.820983887,-9.108882904],[115.820983887,-8.080054283],[119.335800171,-8.080054283],[119.335800171,-9.108882904],[115.820983887,-9.108882904]]]}
this.province_geom["Nusa Tenggara Timur"] = {"type":"Polygon","coordinates":[[[118.927856445,-11.007615089],[118.927856445,-8.063357353],[125.186012268,-8.063357353],[125.186012268,-11.007615089],[118.927856445,-11.007615089]]]}
this.province_geom["Papua Barat"] = {"type":"Polygon","coordinates":[[[129.30770874,-4.25427103],[129.30770874,0.578191221],[135.257980347,0.578191221],[135.257980347,-4.25427103],[129.30770874,-4.25427103]]]}
this.province_geom["Jawa Tengah"] = {"type":"Polygon","coordinates":[[[108.555900574,-8.210907936],[108.555900574,-5.725720406],[111.69140625,-5.725720406],[111.69140625,-8.210907936],[108.555900574,-8.210907936]]]}
this.province_geom["Kalimantan Tengah"] = {"type":"Polygon","coordinates":[[[110.732223511,-3.540827274],[110.732223511,0.793256342],[115.846961975,0.793256342],[115.846961975,-3.540827274],[110.732223511,-3.540827274]]]}
this.province_geom["Aceh"] = {"type":"Polygon","coordinates":[[[95.00970459,2.008107185],[95.00970459,6.076940536],[98.285926819,6.076940536],[98.285926819,2.008107185],[95.00970459,2.008107185]]]}
this.province_geom["Jawa Timur"] = {"type":"Polygon","coordinates":[[[110.899749756,-8.780309677],[110.899749756,-5.048857212],[116.270149231,-5.048857212],[116.270149231,-8.780309677],[110.899749756,-8.780309677]]]}
this.province_geom["Kalimantan Barat"] = {"type":"Polygon","coordinates":[[[108.677841187,-3.067629576],[108.677841187,2.080966234],[114.198242188,2.080966234],[114.198242188,-3.067629576],[108.677841187,-3.067629576]]]}
this.province_geom["Kalimantan Selatan"] = {"type":"Polygon","coordinates":[[[114.345855713,-4.744812965],[114.345855713,-1.315039515],[116.554763794,-1.315039515],[116.554763794,-4.744812965],[114.345855713,-4.744812965]]]}
this.province_geom["Kalimantan Timur"] = {"type":"Polygon","coordinates":[[[113.843040466,-2.408681393],[113.843040466,4.402960777],[118.989486694,4.402960777],[118.989486694,-2.408681393],[113.843040466,-2.408681393]]]}
  
  
   this.chartelem = document.getElementById('myChart')
 this.legendelem = document.getElementById('legend-container')
 
  setTimeout(this.changeAOI,500)
  
  setTimeout(this.changeAOI_top10,500)
  
 this.aois_info = []
 aois_info.data.forEach(a => {
 
  var polygon = turf.polygon(a.geometry.features[0].geometry.coordinates)

	
 a.area = Number((area(polygon)/1000000.0).toFixed(2))
 this.aois_detail[a.id] = a
 this.aois_info.push(a)
 }
 )
 
  },
  computed: {
    months () {
      const months = require('@/assets/monthSelect.json')
      return months.filter(month => parseInt(month.value) <= parseInt(this.month))
    },
    groupLegend: function () {
      if (!this.myChart) return []
      let groups = []
	  
      this.myChart.data.datasets.forEach(dataset => {
        this.chartLegend.forEach(legend => {
		    if (dataset.label && legend.text && dataset.label.toUpperCase() === legend.text.toUpperCase()) {
            if (!groups.length) {
              groups.push({
                name: dataset.stack,
                stack: dataset.stack,
                legend: [legend]
              })
            } else {
              let index = groups.findIndex(val => val.stack === dataset.stack)
              if (index >= 0) groups[index].legend.push(legend)
              else groups.push({
                name: dataset.stack,
                stack: dataset.stack,
                legend: [legend]
              })
            }
          }
        })
      })
      groups.forEach(group => {
        let totalDisplay = 0
        group.legend.forEach(legend => {
          if (!legend.hidden) totalDisplay = totalDisplay + 1
        })
        group['checkAll'] = totalDisplay === group.legend.length
      })
      return groups
    }
  },
  watch: {
    dataSource (val) {
      this.getAOI()
      this.getData()
    },
    month (val) {
      //if (parseInt(val) < parseInt(this.compareMonth.value)) this.compareMonth = this.months[0]
    }
  },
  methods: {
  getmapbbox(){
  console.log('mapbbox',window.overview_map.getBounds())
  },
   ChangeTab(x){
      // this.dropdown = true
      this.dropdown = x
      // this.dropdown = x
	  //console.log('dropdown',this.dropdown)
	  if (!this.dropdown)
	  {
	  this.changeAOI()
	  //setTimeout(this.changeAOI,300)
	  }
    },
  chgCompareMonth(e) {
  console.log('chgCompareMonth',e)
  this.compareMonth = e
  this.changeAOI(this.aoi)
  console.log('chgCompareMonth1',e)
  },
    ChangeDisplayGroupLegend (group) {
      group.legend.forEach(val => {
        val.hidden = group.checkAll
        this.changeDisplayChart(val)
      })
    },
    refreshChart () {
      this.updateChartData(this.chartData, this.convertMonth(this.greenCoverData.labels))
    },
    transformData () {
      this.chartData = []

var area_change_colors =
{
"Green cover change" :  [
            "#893ff299"
          ],
"Green to non" : [
            "#B9b40899"
          ],
"Non to green" :  [
            "#3ff98099"
          ]
}	  
if (this.greenCoverData.green_area[0])
{
  this.greenCoverData.area_change.forEach(val => {
        this.chartData.push({
          type: 'line',
          label: val.label,
          data: val.data,
        fill: true,
        tension: 0.4,
        pointRadius: 7,
        pointHoverRadius: 7,
          backgroundColor: area_change_colors[val.label],
          borderColor:  area_change_colors[val.label],
          borderWidth: 1,
          stack: 'Green area change'
        })
      })
}
else
{
      this.chartData.push({
        type: 'line',
        label:  this.greenCoverData.area_change.label,
        data:  this.greenCoverData.area_change.data,
        fill: true,
        pointRadius: 7,
        tension: 0.4,
        pointHoverRadius: 7,
        backgroundColor: 'rgba(137,63,242,0.35)',
        borderColor:  'rgb(128,8,250)',
        stack: 'Green area change'
      })
}

var green_area_colors =
{
"Green cover" :  [
            "#228b22"
          ],
"Non green" : [
            "#e47a10"
          ],
"Water" :  [
            "#11b9db"
          ],
"Cloud" :  [
            "#9e9b9b"
          ]			  


}

if (this.greenCoverData.green_area[0])
{
  this.greenCoverData.green_area.forEach(val => {
        this.chartData.push({
          type: 'bar',
          label: val.label,
          data: val.data,
          barPercentage: 0.8,
          backgroundColor: green_area_colors[val.label],
          borderColor:  '#0a7830',
          borderWidth: 1,
          stack: 'Green area'
        })
      })
}
else
{

  this.chartData.push({
        type: 'bar',
        label:  this.greenCoverData.green_area.label,
        data:  this.greenCoverData.green_area.data,
        barPercentage: 0.8,
        backgroundColor:  'rgb(54, 162, 235)',
        borderColor:  'rgb(54, 162, 235)',
        stack: 'Green area'
      })
}	  
      this.greenCoverData.green_density.forEach(val => {
        this.chartData.push({
          type: 'bar',
          label: val.label,
          data: val.data,
          barPercentage: 0.8,
          backgroundColor: val.color,
          borderColor: '#434cf6',
          borderWidth: 1,
          stack: 'Density'
        })
      })
    },
    changeDisplayChart (legend) {
      this.myChart.setDatasetVisibility(legend.datasetIndex, legend.hidden);
      this.myChart.update()
    },
    updateChartData (newData, labels) {
      this.myChart.data.datasets = newData
      this.myChart.data.labels = labels
      this.myChart.update()
    },
    displayChart () {
	
      if (!this.greenCoverData) return
      this.transformData()
      const data = {
        labels: this.convertMonth(this.greenCoverData.labels),
        datasets: this.chartData
      }
      let ctx = document.getElementById('myChart')
      const htmlLegendPlugin = {
        id: 'htmlLegend',
        afterUpdate: (chart, args, options) => {
          this.chartLegend = chart.options.plugins.legend.labels.generateLabels(chart);
        }
      }
      this.myChart = new Chart(ctx, {
        data: data,
        options: {
          plugins: {
            htmlLegend: {
              containerID: 'legend-container',
            },
            legend: {
              display: false,
              position: window.innerHeight > 850 ? 'right' : 'top'
            }
          },
          pointBorderColor: '#893FF2',
          pointBorderWidth: 3,
          pointBackgroundColor: '#fff',
          onClick: (c,i) => {
            let e = i[0]
            if (!e) return
            let newData = []
            newData.push({
              type: 'line',
              label:  this.greenCoverData.area_change.label,
              data:  [this.greenCoverData.area_change.data[e.index]],
              fill: true,
              pointRadius: 7,
              pointHoverRadius: 7,
              backgroundColor: '#67bb6a',
              stack: 'Green area change'
            })
            newData.push({
              type: 'bar',
              label:  this.greenCoverData.green_area.label,
              data:  [this.greenCoverData.green_area.data[e.index]],
              barPercentage: 0.8,
              backgroundColor:  '#893ff2',
              borderColor:  'rgb(54, 162, 235)',
              borderWidth: 1,

              stack: 'Green area'
            })
            this.greenCoverData.plant_health.forEach(val => {
              newData.push({
                type: 'bar',
                label: val.label,
                data: [val.data[e.index]],
                barPercentage: 0.8,
                backgroundColor: val.color,
                borderColor: '#f76700',
                borderWidth: 1,
                stack: 'Plant health'
              })
            })
            this.greenCoverData.green_density.forEach(val => {
              newData.push({
                type: 'bar',
                label: val.label,
                data: [val.data[e.index]],
                barPercentage: 0.8,
                backgroundColor: val.color,
                borderColor: '#434cf6',
                borderWidth: 1,
                stack: 'Density'
              })
            })
            this.updateChartData(newData, [this.convertMonth(this.greenCoverData.labels)[e.index]])
          },
          scales: {
            x: {
              stacked: true,
              grid: {
                display: false
              }
            },
            y: {
              title: {
                text: 'AREA SQKM',
                display: true
              },
              stacked: true,
              grid: {
                display: true
              }
            }
          },
          tooltips: {
            callbacks: {
              label: function(tooltipItem) {
                return tooltipItem.yLabel;
              }
            }
          }
        },
        plugins: [htmlLegendPlugin]
      })
    },
    async getAOI () {
      try {
        //this.loading = true
        const res = await getAOI({source: this.dataSource})
        this.aois = res.data.data
        if (!this.aoi) {
          let sing = this.aois.find(val => val.name === 'Singapore')
          if (sing) this.aoi = sing
          else this.aoi = this.aois[1]
        }
        //this.$refs.mapView.addSingaporeBoundary(this.aoi.geometry)
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
	clear_Chart_Map(){
	 // if (this.myChart) 
	  //this.myChart.destroy()
	  
        //this.updateChartData ([], []) 
	   
	
	this.$refs.mapView.removeLayer()
	},
	set_aoi_area1(val){
	this.aoi_area1 = val
	},
	changeAOI_top10(item) {
	
	if (!item)
	item = {name:'All Mine AOI'}
	
	this.tab_aoi = item

	},
    changeAOI (item) {
	
	if (!item)
	item = {name:'All Mine AOI'}
	
	  console.log('From Main Menu', item)
      this.aoi = item
      console.log('Item: ', this.aoi)
	 	 
		 this.aoi_ids = []
		 
	   if (item.bbox)
	   {
	  var polygon = turf.polygon(this.aoi.geometry.features[0].geometry.coordinates)

	this.aoi_area = (area(polygon)/1000000.0).toFixed(2)
      // this.$refs.mapView.addSingaporeBoundary(this.aoi.geometry)
	  
	 this.aoi_ids.push(this.aoi.id)
	  

	   }
	   else
	   {
	   
		var area1 = 0

	   if (item.name == 'Mineral' || item.name == 'Batu Bara')
	   {
					this.aois_info.forEach(a =>
						   {
						   if (a.type == item.name)
						   {
					   area1 = area1 + a.area
					   
						this.aoi_ids.push(a.id)
						   
						   }
						   
						   })
	   }
	   else
	   {
					   this.aois_info.forEach(a =>
					   {
					   if (a.province == item.name || item.name == 'All Mine AOI')
					   {
					   
					   area1 = area1 + a.area
						 this.aoi_ids.push(a.id)
					   }
					   })
	   }
	    console.log('area1',area1)
	   this.aoi_area = area1.toFixed(2)
	   	   
	   }
	   
       this.getData()	   
    },
    async getData (obj) {
      try {
       // this.loading = true
	   var payload = {}
	   var result_data = []
	   
	   //console.log('getData dropdown',this.dropdown)
	   //console.log('getData obj',obj)
	   
	   if (!(obj && obj.result_data))
	   {
	   
	   if (this.dropdown)
	   return
	   	   
		//console.log('getData aoi_ids',this.aoi_ids.length)
		
        if (this.aoi_ids.length < 1 )
		return
				
        let month = null
        month = this.month < 10 ? `0${this.month}` : this.month
		
		 payload = {
          month: month,
          compare_month: this.compareMonth.value,
          year: new Date(this.recordedFor).getFullYear(),
          source: this.dataSource 
        }
		
		//console.log('getData payload',payload)
		
		var i = 0
		this.aoi_ids.forEach(id => {
		payload["aoi_ids[" + String(i) + "]"] = id
		
		i = i + 1
		}
		
		)
		
		  
        var res = await getDataDashBoard(payload)
		
		result_data = res.data.data
		
		}
		else
		{
		
		result_data = obj.result_data
		payload = obj.payload
		
		}
		
		
		var data_mth_aois = {}
		var aoi_idx = {}
		//check no data
		
		var mth = Number(payload.month)
		var cmpmth = Number(payload.compare_month)
		
		result_data.forEach((a,idx) => {
		
			if (!a.aoi_id)
			return
	
		if (mth > cmpmth && !a.compare_result && a.result)
		{
		if (a.image_mosaic)
		{
		var data_mth = 0
		a.green_cover_change_in_year.labels.forEach(m => {
		//console.log('m',m)
		
		if (Number(m) < mth && m > data_mth)
		data_mth = m
		
				
		})
		
		aoi_idx[a.aoi_id] = idx
		
		if (!data_mth_aois[data_mth])
		data_mth_aois[data_mth] = [a.aoi_id]
		else
		data_mth_aois[data_mth].push(a.aoi_id)
		}
		}

		if (mth < cmpmth && a.compare_result && !a.result)
		{
		if (a.image_mosaic)
		{
		var data_mth = 0
		a.green_cover_change_in_year.labels.forEach(m => {
		//console.log('m',m)
	
		if (Number(m) < cmpmth && m > data_mth)
		data_mth = m
		
				
		})
		
		aoi_idx[a.aoi_id] = idx
		
		if (!data_mth_aois[data_mth])
		data_mth_aois[data_mth] = [a.aoi_id]
		else
		data_mth_aois[data_mth].push(a.aoi_id)
		}
		}
		
		
	})
		
		console.log('data_mth_aois',data_mth_aois)
		
for (const [key, value] of Object.entries(data_mth_aois)) {
  var pay = {
          month: key,
          compare_month: key,
          year: new Date(this.recordedFor).getFullYear(),
          source: this.dataSource 
        }
		
		var j = 0
		value.forEach(id => {
		pay["aoi_ids[" + String(j) + "]"] = id
		
		j = j + 1
		}
		
		)
		
		  
        var res1 = await getDataDashBoard(pay)
		
		res1.data.data.forEach(a => {
		
			if (!a.aoi_id)
			return
			
			var idx = aoi_idx[a.aoi_id]
			
			if (!result_data[idx].result)
			{
			result_data[idx].result = a.result
			result_data[idx].green_density = a.green_density
			}
			
			if (!result_data[idx].compare_result)
			{
			result_data[idx].compare_result = a.compare_result
			result_data[idx].compare_green_density = a.compare_green_density
			}
		
		})
		
}
		
	   var green_cover_chg = 0
	   var non_green_chg = 0
	   var highly_dense_chg = 0
	   
	    var  result = {
      "green_cover_area": 0,
      "water_area": 0,
      "non_green_area": 0,
      "cloud_area": 0
    }
    var compare_result = {
      "green_cover_area": 0,
      "water_area": 0,
      "non_green_area": 0,
      "cloud_area": 0
    }
	   var green_density = [
      {
        "label": "Low Dense",
        "value": 0,
        "color": "#ff0000",

      },
      {
        "label": "Dense",
        "value": 0,
        "color": "#8BC34A",
 
      },
      {
        "label": "Highly Dense",
        "value": 0,
        "color": "#4CAF50",
 
      }
    ]
    var compare_green_density =[
      {
        "label": "Low Dense",
        "value": 0,
        "color": "#ff0000"
      },
      {
        "label": "Dense",
        "value": 0,
        "color": "#8BC34A"
      },
      {
        "label": "Highly Dense",
        "value": 0,
        "color": "#4CAF50"
      }
    ] 

    var green_cover_change_in_year = {
      "labels": [
        "09",
        "10",
        "11"
      ],
      "green_density": [
        {
          "label": "Low Dense",
          "data": [
            0,
            0,
            0
          ],
          "color": [
            "#9b2121"
          ]
        },
        {
          "label": "Dense",
          "data": [
            0,
            0,
            0
          ],
          "color": [
            "#8BC34A"
          ]
        },
        {
          "label": "Highly Dense",
          "data": [
            0,
            0,
            0
          ],
          "color": [
            "#11BADB"
          ]
        }
      ],
      "green_area": [
        {
          "label": "Green cover",
          "data": [
            0,
            0,
            0
          ],
          "color": [
            "#F44336"
          ]
        },
        {
          "label": "Non green",
          "data": [
            0,
            0,
            0
          ],
          "color": [
            "#8BC34A"
          ]
        },
        {
          "label": "Water",
          "data": [
            0,
            0,
            0
          ],
          "color": [
            "#4CAF50"
          ]
        },
       {
          "label": "Cloud",
          "data": [
            0,
            0,
            0
          ],
          "color": [
            "#4CAF50"
          ]
        }		
      ],
      "area_change": [
        {
          "label": "Green cover change",
          "data": [
            0,
            0,
            0
          ],
          "color": [
            "#F44336"
          ]
        },
        {
          "label": "Green to non",
          "data": [
            0,
            0,
            0
          ],
          "color": [
            "#8BC34A"
          ]
        },
        {
          "label": "Non to green",
          "data": [
            0,
            0,
            0
          ],
          "color": [
            "#4CAF50"
          ]
        }
      ]
	}	
	var result_count = 0
	var compare_result_count = 0
	result_data.forEach(a => {
	
			if (!a.aoi_id)
			return
	
	
		if (a.result)
		{
		result_count = result_count + 1
	   result.green_cover_area = result.green_cover_area + a.result.green_cover_area
	   result.non_green_area = result.non_green_area + a.result.non_green_area
	   result.water_area = result.water_area + a.result.water_area
	   result.cloud_area = result.cloud_area + a.result.cloud_area
	   
	   	green_density[0].value = green_density[0].value + a.green_density[0].value
		green_density[1].value = green_density[1].value + a.green_density[1].value
		green_density[2].value = green_density[2].value + a.green_density[2].value
	   }
	   
	   if (a.compare_result)
	   {
	   compare_result_count = compare_result_count + 1
	   compare_result.green_cover_area = compare_result.green_cover_area + a.compare_result.green_cover_area
	   compare_result.non_green_area = compare_result.non_green_area + a.compare_result.non_green_area
	   compare_result.water_area = compare_result.water_area + a.compare_result.water_area
	   compare_result.cloud_area = compare_result.cloud_area + a.compare_result.cloud_area
	   
	   	compare_green_density[0].value = compare_green_density[0].value + a.compare_green_density[0].value
		compare_green_density[1].value = compare_green_density[1].value + a.compare_green_density[1].value
		compare_green_density[2].value = compare_green_density[2].value + a.compare_green_density[2].value
	   }
	   

green_cover_change_in_year.green_density[0].data[0] = green_cover_change_in_year.green_density[0].data[0] + a.green_cover_change_in_year.green_density[0].data[0]
green_cover_change_in_year.green_density[0].data[1] = green_cover_change_in_year.green_density[0].data[1] + a.green_cover_change_in_year.green_density[0].data[1]
green_cover_change_in_year.green_density[0].data[2] = green_cover_change_in_year.green_density[0].data[2] + a.green_cover_change_in_year.green_density[0].data[2]
green_cover_change_in_year.green_density[1].data[0] = green_cover_change_in_year.green_density[1].data[0] + a.green_cover_change_in_year.green_density[1].data[0]
green_cover_change_in_year.green_density[1].data[1] = green_cover_change_in_year.green_density[1].data[1] + a.green_cover_change_in_year.green_density[1].data[1]
green_cover_change_in_year.green_density[1].data[2] = green_cover_change_in_year.green_density[1].data[2] + a.green_cover_change_in_year.green_density[1].data[2]
green_cover_change_in_year.green_density[2].data[0] = green_cover_change_in_year.green_density[2].data[0] + a.green_cover_change_in_year.green_density[2].data[0]
green_cover_change_in_year.green_density[2].data[1] = green_cover_change_in_year.green_density[2].data[1] + a.green_cover_change_in_year.green_density[2].data[1]
green_cover_change_in_year.green_density[2].data[2] = green_cover_change_in_year.green_density[2].data[2] + a.green_cover_change_in_year.green_density[2].data[2]

green_cover_change_in_year.green_area[0].data[0] = green_cover_change_in_year.green_area[0].data[0] + a.green_cover_change_in_year.green_area[0].data[0]
green_cover_change_in_year.green_area[0].data[1] = green_cover_change_in_year.green_area[0].data[1] + a.green_cover_change_in_year.green_area[0].data[1]
green_cover_change_in_year.green_area[0].data[2] = green_cover_change_in_year.green_area[0].data[2] + a.green_cover_change_in_year.green_area[0].data[2]
green_cover_change_in_year.green_area[1].data[0] = green_cover_change_in_year.green_area[1].data[0] + a.green_cover_change_in_year.green_area[1].data[0]
green_cover_change_in_year.green_area[1].data[1] = green_cover_change_in_year.green_area[1].data[1] + a.green_cover_change_in_year.green_area[1].data[1]
green_cover_change_in_year.green_area[1].data[2] = green_cover_change_in_year.green_area[1].data[2] + a.green_cover_change_in_year.green_area[1].data[2]
green_cover_change_in_year.green_area[2].data[0] = green_cover_change_in_year.green_area[2].data[0] + a.green_cover_change_in_year.green_area[2].data[0]
green_cover_change_in_year.green_area[2].data[1] = green_cover_change_in_year.green_area[2].data[1] + a.green_cover_change_in_year.green_area[2].data[1]
green_cover_change_in_year.green_area[2].data[2] = green_cover_change_in_year.green_area[2].data[2] + a.green_cover_change_in_year.green_area[2].data[2]
green_cover_change_in_year.green_area[3].data[0] = green_cover_change_in_year.green_area[3].data[0] + a.green_cover_change_in_year.green_area[3].data[0]
green_cover_change_in_year.green_area[3].data[1] = green_cover_change_in_year.green_area[3].data[1] + a.green_cover_change_in_year.green_area[3].data[1]
green_cover_change_in_year.green_area[3].data[2] = green_cover_change_in_year.green_area[3].data[2] + a.green_cover_change_in_year.green_area[3].data[2]

green_cover_change_in_year.area_change[0].data[0] = green_cover_change_in_year.area_change[0].data[0] + a.green_cover_change_in_year.area_change[0].data[0]
green_cover_change_in_year.area_change[0].data[1] = green_cover_change_in_year.area_change[0].data[1] + a.green_cover_change_in_year.area_change[0].data[1]
green_cover_change_in_year.area_change[0].data[2] = green_cover_change_in_year.area_change[0].data[2] + a.green_cover_change_in_year.area_change[0].data[2]
green_cover_change_in_year.area_change[1].data[0] = green_cover_change_in_year.area_change[1].data[0] + a.green_cover_change_in_year.area_change[1].data[0]
green_cover_change_in_year.area_change[1].data[1] = green_cover_change_in_year.area_change[1].data[1] + a.green_cover_change_in_year.area_change[1].data[1]
green_cover_change_in_year.area_change[1].data[2] = green_cover_change_in_year.area_change[1].data[2] + a.green_cover_change_in_year.area_change[1].data[2]
green_cover_change_in_year.area_change[2].data[0] = green_cover_change_in_year.area_change[2].data[0] + a.green_cover_change_in_year.area_change[2].data[0]
green_cover_change_in_year.area_change[2].data[1] = green_cover_change_in_year.area_change[2].data[1] + a.green_cover_change_in_year.area_change[2].data[1]
green_cover_change_in_year.area_change[2].data[2] = green_cover_change_in_year.area_change[2].data[2] + a.green_cover_change_in_year.area_change[2].data[2]

})	

//console.log('result',result)

        //this.dataTablePlantHealth = []
        this.dataTableGreenDensity = []
        this.greenCoverData = {}
        // let data = result_data
		
		if (result_count > 0)
		{
result = {
      "green_cover_area": result.green_cover_area.toFixed(2),
      "water_area": result.water_area.toFixed(2),
      "non_green_area": result.non_green_area.toFixed(2),
      "cloud_area": result.cloud_area.toFixed(2)
    }
		}	
else
{
result = {
      "green_cover_area": 'NA',
      "water_area": 'NA',
      "non_green_area": 'NA',
      "cloud_area": 'NA'
    }

}		
       		
		if (compare_result_count > 0)
		{
compare_result = {
      "green_cover_area": compare_result.green_cover_area.toFixed(2),
      "water_area": compare_result.water_area.toFixed(2),
      "non_green_area": compare_result.non_green_area.toFixed(2),
      "cloud_area": compare_result.cloud_area.toFixed(2)
    }
		}
else
{
compare_result = {
      "green_cover_area": 'NA',
      "water_area": 'NA',
      "non_green_area": 'NA',
      "cloud_area": 'NA'
    }

}

		if (result_count > 0)
		{
green_density[0].value = green_density[0].value.toFixed(2)
green_density[1].value = green_density[1].value.toFixed(2)
green_density[2].value = green_density[2].value.toFixed(2)
		}	
else
{
green_density = [
      {
        "label": "Low Dense",
        "value": 'NA',
        "color": "#F44336",

      },
      {
        "label": "Dense",
        "value": 'NA',
        "color": "#8BC34A",
 
      },
      {
        "label": "Highly Dense",
        "value": 'NA',
        "color": "#4CAF50",
 
      }
    ]

}		
       		
		if (compare_result_count > 0)
		{
compare_green_density[0].value = compare_green_density[0].value.toFixed(2)
compare_green_density[1].value = compare_green_density[1].value.toFixed(2)
compare_green_density[2].value = compare_green_density[2].value.toFixed(2)
		}
else
{
compare_green_density = [
      {
        "label": "Low Dense",
        "value": 'NA',
        "color": "#F44336",

      },
      {
        "label": "Dense",
        "value": 'NA',
        "color": "#8BC34A",
 
      },
      {
        "label": "Highly Dense",
        "value": 'NA',
        "color": "#4CAF50",
 
      }
    ]
}

var green_cover_chg = 'NA'
		
	
		var non_green_chg = 'NA'
		var highly_dense_chg = 'NA'
		
		var green_cover_pc = 'NA'
		var non_green_pc = 'NA'
		var highly_dense_pc = 'NA'
		
		if (result_count > 0 && compare_result_count > 0)
		{
		green_cover_chg = (result.green_cover_area - compare_result.green_cover_area)
		
	
		non_green_chg = (result.non_green_area - compare_result.non_green_area)
		highly_dense_chg = (green_density[2].value - compare_green_density[2].value)
		
		green_cover_pc = ((100.0 * green_cover_chg)/compare_result.green_cover_area).toFixed(2)
		non_green_pc = ((100.0 * non_green_chg)/compare_result.non_green_area).toFixed(2)
		highly_dense_pc = ((100.0 * highly_dense_chg)/compare_green_density[2].value).toFixed(2)
		
		green_cover_chg = green_cover_chg.toFixed(1)
		non_green_chg = non_green_chg.toFixed(1)
		highly_dense_chg = highly_dense_chg.toFixed(1)
		
    }

		this.summary = { green_cover_chg : green_cover_chg, non_green_chg : non_green_chg, highly_dense_chg : highly_dense_chg,
		green_cover_pc : green_cover_pc, non_green_pc : non_green_pc, highly_dense_pc : highly_dense_pc,	
		result : result , compare_result : compare_result , green_density : green_density , compare_green_density : compare_green_density}
    
		
		this.dataTableGreenDensity = green_density
        this.greenCoverData = green_cover_change_in_year
		//delete this.greenCoverData['plant_health']
        this.labels= []
        if (!this.myChart) this.displayChart()
        else {
          this.transformData()
          this.updateChartData(this.chartData, this.convertMonth(this.greenCoverData.labels))
        }
       
        this.$refs.mapView.removeLayer()
		
		if (result_data.length == 1)
		{
		 let demoData = result_data[0]
		 console.log('demo',result_data)
        //if (demoData.image_ndvi) this.$refs.mapView.createImageLayer(demoData.image_ndvi, false)
        if (demoData.image_mosaic) this.$refs.mapView.createImageLayer(demoData.image_mosaic, true)
        if (demoData.image_density) this.$refs.mapView.createImageLayer(demoData.image_density, false)
        if (demoData.image_classification) this.$refs.mapView.createImageLayer(demoData.image_classification, false)
        if (demoData.image_green_cover_change) this.$refs.mapView.createImageLayer(demoData.image_green_cover_change , false)
        if (demoData.cloud_mask) this.$refs.mapView.createVectorLayer(demoData.cloud_mask, false)
		}
		if (this.aoi.geometry)
        this.zoomToArea()
		else
		{
		 if (this.aoi.name == 'Mineral' || this.aoi.name == 'Batu Bara' || this.aoi.name == 'All Mine AOI' )
		 {
		 this.$refs.mapView.submitZoom(this.project_bbox)
		 }
		 else {
		  this.zoomToArea_geom(this.province_geom[this.aoi.name])
		 }
	   
		}
      } 
	  catch (e) {
	    console.log(e.message)
		console.log(e.stack)
        this.$refs.mapView.removeLayer()
         this.loading = false
      } finally {
        this.loading = false
      }
    },
    async getData_from_Tab (e) {
      try {
       // this.loading = true
	   
this.chartelem.style.display = 'block'
this.legendelem.style.display = 'block'
				
        let month = null
        month = e.month < 10 ? `0${e.month}` : e.month
		
		console.log(e)
		
		var payload = {
          month: month,
          compare_month: e.compareMonth,
          year: '2021',
          source: this.dataSource 
        }
				
		payload["aoi_ids[0]"] = e.aoi_id
		  
        var res = await getDataDashBoard(payload)
		
    var green_cover_change_in_year = {
      "labels": [
        "09",
        "10",
        "11"
      ],
      "green_density": [
        {
          "label": "Low Dense",
          "data": [
            0,
            0,
            0
          ],
          "color": [
            "#F44336"
          ]
        },
        {
          "label": "Dense",
          "data": [
            0,
            0,
            0
          ],
          "color": [
            "#8BC34A"
          ]
        },
        {
          "label": "Highly Dense",
          "data": [
            0,
            0,
            0
          ],
          "color": [
            "#4CAF50"
          ]
        }
      ],
      "green_area": [
        {
          "label": "Green cover",
          "data": [
            0,
            0,
            0
          ],
          "color": [
            "#F44336"
          ]
        },
        {
          "label": "Non green",
          "data": [
            0,
            0,
            0
          ],
          "color": [
            "#8BC34A"
          ]
        },
        {
          "label": "Water",
          "data": [
            0,
            0,
            0
          ],
          "color": [
            "#4CAF50"
          ]
        },
       {
          "label": "Cloud",
          "data": [
            0,
            0,
            0
          ],
          "color": [
            "#4CAF50"
          ]
        }		
      ],
      "area_change": [
        {
          "label": "Green cover change",
          "data": [
            0,
            0,
            0
          ],
          "color": [
            "#F44336"
          ]
        },
        {
          "label": "Green to non",
          "data": [
            0,
            0,
            0
          ],
          "color": [
            "#8BC34A"
          ]
        },
        {
          "label": "Non to green",
          "data": [
            0,
            0,
            0
          ],
          "color": [
            "#4CAF50"
          ]
        }
      ]
	}	
	var result_count = 0
	var compare_result_count = 0
	res.data.data.forEach(a => {
	
			if (!a.aoi_id)
			return	
	
green_cover_change_in_year.green_density[0].data[0] = green_cover_change_in_year.green_density[0].data[0] + a.green_cover_change_in_year.green_density[0].data[0]
green_cover_change_in_year.green_density[0].data[1] = green_cover_change_in_year.green_density[0].data[1] + a.green_cover_change_in_year.green_density[0].data[1]
green_cover_change_in_year.green_density[0].data[2] = green_cover_change_in_year.green_density[0].data[2] + a.green_cover_change_in_year.green_density[0].data[2]
green_cover_change_in_year.green_density[1].data[0] = green_cover_change_in_year.green_density[1].data[0] + a.green_cover_change_in_year.green_density[1].data[0]
green_cover_change_in_year.green_density[1].data[1] = green_cover_change_in_year.green_density[1].data[1] + a.green_cover_change_in_year.green_density[1].data[1]
green_cover_change_in_year.green_density[1].data[2] = green_cover_change_in_year.green_density[1].data[2] + a.green_cover_change_in_year.green_density[1].data[2]
green_cover_change_in_year.green_density[2].data[0] = green_cover_change_in_year.green_density[2].data[0] + a.green_cover_change_in_year.green_density[2].data[0]
green_cover_change_in_year.green_density[2].data[1] = green_cover_change_in_year.green_density[2].data[1] + a.green_cover_change_in_year.green_density[2].data[1]
green_cover_change_in_year.green_density[2].data[2] = green_cover_change_in_year.green_density[2].data[2] + a.green_cover_change_in_year.green_density[2].data[2]

green_cover_change_in_year.green_area[0].data[0] = green_cover_change_in_year.green_area[0].data[0] + a.green_cover_change_in_year.green_area[0].data[0]
green_cover_change_in_year.green_area[0].data[1] = green_cover_change_in_year.green_area[0].data[1] + a.green_cover_change_in_year.green_area[0].data[1]
green_cover_change_in_year.green_area[0].data[2] = green_cover_change_in_year.green_area[0].data[2] + a.green_cover_change_in_year.green_area[0].data[2]
green_cover_change_in_year.green_area[1].data[0] = green_cover_change_in_year.green_area[1].data[0] + a.green_cover_change_in_year.green_area[1].data[0]
green_cover_change_in_year.green_area[1].data[1] = green_cover_change_in_year.green_area[1].data[1] + a.green_cover_change_in_year.green_area[1].data[1]
green_cover_change_in_year.green_area[1].data[2] = green_cover_change_in_year.green_area[1].data[2] + a.green_cover_change_in_year.green_area[1].data[2]
green_cover_change_in_year.green_area[2].data[0] = green_cover_change_in_year.green_area[2].data[0] + a.green_cover_change_in_year.green_area[2].data[0]
green_cover_change_in_year.green_area[2].data[1] = green_cover_change_in_year.green_area[2].data[1] + a.green_cover_change_in_year.green_area[2].data[1]
green_cover_change_in_year.green_area[2].data[2] = green_cover_change_in_year.green_area[2].data[2] + a.green_cover_change_in_year.green_area[2].data[2]
green_cover_change_in_year.green_area[3].data[0] = green_cover_change_in_year.green_area[3].data[0] + a.green_cover_change_in_year.green_area[3].data[0]
green_cover_change_in_year.green_area[3].data[1] = green_cover_change_in_year.green_area[3].data[1] + a.green_cover_change_in_year.green_area[3].data[1]
green_cover_change_in_year.green_area[3].data[2] = green_cover_change_in_year.green_area[3].data[2] + a.green_cover_change_in_year.green_area[3].data[2]

green_cover_change_in_year.area_change[0].data[0] = green_cover_change_in_year.area_change[0].data[0] + a.green_cover_change_in_year.area_change[0].data[0]
green_cover_change_in_year.area_change[0].data[1] = green_cover_change_in_year.area_change[0].data[1] + a.green_cover_change_in_year.area_change[0].data[1]
green_cover_change_in_year.area_change[0].data[2] = green_cover_change_in_year.area_change[0].data[2] + a.green_cover_change_in_year.area_change[0].data[2]
green_cover_change_in_year.area_change[1].data[0] = green_cover_change_in_year.area_change[1].data[0] + a.green_cover_change_in_year.area_change[1].data[0]
green_cover_change_in_year.area_change[1].data[1] = green_cover_change_in_year.area_change[1].data[1] + a.green_cover_change_in_year.area_change[1].data[1]
green_cover_change_in_year.area_change[1].data[2] = green_cover_change_in_year.area_change[1].data[2] + a.green_cover_change_in_year.area_change[1].data[2]
green_cover_change_in_year.area_change[2].data[0] = green_cover_change_in_year.area_change[2].data[0] + a.green_cover_change_in_year.area_change[2].data[0]
green_cover_change_in_year.area_change[2].data[1] = green_cover_change_in_year.area_change[2].data[1] + a.green_cover_change_in_year.area_change[2].data[1]
green_cover_change_in_year.area_change[2].data[2] = green_cover_change_in_year.area_change[2].data[2] + a.green_cover_change_in_year.area_change[2].data[2]

})	

       
        this.greenCoverData = {}
        // let data = res.data.data
		
        this.greenCoverData = green_cover_change_in_year
		//delete this.greenCoverData['plant_health']
        this.labels= []
		
        if (!this.myChart || !this.myChart.ctx) 
		{
		try{
		this.displayChart()
		}
		 catch (e) {
       this.myChart.destroy()
	   this.displayChart()
      } 
		}
        else {
          this.transformData()
          this.updateChartData(this.chartData, this.convertMonth(this.greenCoverData.labels))
        }
       
        this.$refs.mapView.removeLayer()
		
		 let demoData = res.data.data[0]
		 console.log('demo',res.data.data)
        //if (demoData.image_ndvi) this.$refs.mapView.createImageLayer(demoData.image_ndvi, false)
        if (demoData.image_mosaic) this.$refs.mapView.createImageLayer(demoData.image_mosaic, true)
        if (demoData.image_density) this.$refs.mapView.createImageLayer(demoData.image_density, false)
        if (demoData.image_classification) this.$refs.mapView.createImageLayer(demoData.image_classification, false)
        if (demoData.image_green_cover_change) this.$refs.mapView.createImageLayer(demoData.image_green_cover_change , false)
        if (demoData.cloud_mask) this.$refs.mapView.createVectorLayer(demoData.cloud_mask, false)
		
        this.zoomToArea_geom(e.geometry)
      } 
	  catch (e) {
        this.$refs.mapView.removeLayer()
        console.log(e.message)
		console.log(e.stack)
        this.loading = false
      } finally {
        this.loading = false
      }
    },	
	zoomTo_Tab(aoi_name){
		 if (aoi_name == 'Mineral' || aoi_name == 'Batu Bara' || aoi_name == 'All Mine AOI' )
		 {
		 this.$refs.mapView.submitZoom(this.project_bbox)
		 }
		 else {
		  this.zoomToArea_geom(this.province_geom[aoi_name])
		 }
	},
	zoomToArea_geom(geometry) {
      this.$refs.mapView.submitZoom(bbox(geometry))
    },
    zoomToArea() {
      this.$refs.mapView.submitZoom(bbox(this.aoi.geometry))
    },
    convertMonth (monthArray) {
      return MonthTransform.transformMonthTextArray(monthArray)
    },
    transformNumber (number) {
      return numberFormat.numberWithCommas(number)
    }
  }
}
</script>

<style scoped>

.PanelBox{
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 1px 2px 4px rgba(0, 0, 0, 0.14);
  border-radius: 11px;
}

.LP-Background{
  background: linear-gradient(180deg, rgba(205, 194, 219, 0.18) 0%, rgba(218, 185, 215, 0.18) 40.1%, rgba(209, 181, 207, 0.18) 40.11%, rgba(226, 213, 227, 0.18) 78.83%, rgba(231, 227, 233, 0.18) 100%);
  box-shadow: 1px 1px 6px 1px rgba(184, 181, 189, 0.32);
}

/deep/
.filter-select .v-input__slot {
  min-height: 0 !important;
}
/deep/
.filter-select .v-input__append-inner {
  margin-top: 2px !important;
}
/deep/
.month-picker .v-input__slot {
  min-height: 0 !important;
}
/deep/
.month-picker .v-input__prepend-inner {
  margin-top: 4px !important;
}
/deep/
.legend-panel .v-expansion-panel-content__wrap {
  padding-left: 10px !important;
  padding-right: 0px !important;
  padding-bottom: 4px !important;
  padding-top: 8px !important;
}
/deep/
.legend-panel .v-expansion-panel-header {
  height: 32px !important;
  font-size: 13px !important;
  min-height: 0 !important;
  padding: 10px !important;
  border: 1px solid rgba(137, 63, 242, 0.2);
  box-sizing: border-box;
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.25);
  border-radius: 7px !important;
}
/deep/
.legend-panel .v-expansion-panel-header--active {
  height: 32px !important;
  font-size: 13px !important;
  min-height: 0 !important;
  padding: 10px !important;
  border-radius: 10px !important;
}
/deep/
.v-select__selection {
  color: #893FF2 !important;
  text-transform: uppercase;
  font-weight: 500;
}
/deep/
.select-input .v-input__control {
  box-shadow: 1px 2px 2px rgba(128, 124, 133, 0.2), inset 0px 0px 4px rgba(137, 63, 242, 0.33);
}
/deep/
.small-radio .v-input--selection-controls__input:hover .v-input--selection-controls__ripple:before {
  background: currentColor;
  transform: scale(0.8);
  transition: none;
}
</style>
