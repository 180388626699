/**
 * Invoke 'number' of functions each 'ms' time
 */
export class RateLimit {
  /**
   *
   * @param {Number} number
   * @param {Number} ms
   * @param {Object} [options]
   * @param {Number} [options.initDelay]
   */
  constructor(number, ms, options = {}) {
    this.number = number
    this.ms = ms
    this.queue = []
    this.locked = false
    this.initDelay = options.initDelay || number * 20 // 20ms time delay between each requests (magic number from my computer)
    this.initDelayTimeoutId = null
  }

  /**
   *
   * @param {Function} fnc
   */
  run(fnc) {
    if (this.initDelayTimeoutId) clearTimeout(this.initDelayTimeoutId)

    this.queue.push(fnc)

    if (!this.locked) {
      // Wait for first initization requests
      this.initDelayTimeoutId = setTimeout(() => {
        this.initDelayTimeoutId = null
        this.processQueue()
      }, this.initDelay)
    }
  }

  processQueue() {
    if (this.queue.length > 0) {
      for (let i = 0; i < this.number; i += 1) {
        if (this.queue.length > 0) {
          const fnc = this.queue.shift()
          fnc()
        }
      }

      this.locked = true
      setTimeout(() => {
        this.locked = false
        this.processQueue()
      }, this.ms)
    }
  }
}
