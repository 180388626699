const animatedScrollObserever = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        const enter = entry.target.querySelector('.enter');
        if (entry.isIntersecting) {
          entry.target.classList.add('enter');
          animatedScrollObserever.unobserve((entry.target));
        }
        enter.classList.remove('enter');
      })
    }
)
export  default {
  bind(el) {
    el.classList.add('before-enter');
    animatedScrollObserever.observe((el))
  }
}
