<template>
  <section slot="pdf-content">
    <div style="display: flex; flex-direction: column; background-color: white">
      <div style="display: flex; flex-direction: column; height: 1122px; width: 100%" class="pdf-item">
        <div style="flex: none; height: 50px; width: 100%; background-color: #EBE3ED">
          <div style="display: flex; height: 100%; padding-top: 10px; padding-left: 30px; padding-right: 30px">
            <span style="font-size: 28px; font-style: italic; font-weight: bold">GEOMINERABA MONTHLY REPORT</span>
            <v-spacer/>
            REPORT DATED:
          </div>
        </div>
        <div class="flex" style="display: flex; flex: 1; padding: 10px; flex-direction: column">
          <div style="width: 100%; height: 40px; background-color: rgba(186,191,201,0.47); padding-left: 32px">
            <div style="display: flex; align-items: center; height: 100%">
              SUBSCRIPTION DETAILS
            </div>
          </div>
          <div style="height: 100%; width: 100%; display: flex; padding: 15px; border: 1px solid lightgray">
            <img alt="AOI" :src="AOI ? AOI : ''"/>
<!--            <AOI @loadNextMap="greenCover1 = true"/>-->
          </div>
          <div style="flex: none; height: 40px; width: 100%; border: 2px #893FF2 solid; margin-top: 5px">
            <div style="height: 100%; padding-top: 10px; padding-left: 30px; padding-right: 30px">
              <span style="font-size: 16px; font-style: italic; font-weight: bold; color: #893FF2">SUBSCRIBED AREA</span>
              <span style="font-size: 16px; font-style: italic; font-weight: bold; color: green; margin-left: 30px">SUBSCRIBED AREA</span>
            </div>
          </div>
        </div>
        <div style="width: 100%; height: 40px; background-color: rgba(186,191,201,0.47); padding-left: 32px; flex: none">
          <div style="height: 100%; display: flex; align-items: center">
            MONTH OVERVIEW
          </div>
        </div>
        <div style="height: 100%; flex: 1; display: flex; flex-direction: column; padding: 20px">
          <div style="flex: 1; display: flex; height: 100%">
            <div style="flex: 2; height: 100%">
              <div style="height: 100%; display: flex; flex-direction: column">
                <div style="flex: none; height: 30px; width: 100%">
                  <div style="display: flex; align-items: center; height: 100%; width: 100%; margin-left: 40px">
                    <span style="font-size: 14px; color: #893FF2; text-transform: uppercase; font-weight: 500">Recorded trends of Green Cover Change in a Year</span>
                  </div>
                </div>
                <div style="display: flex; height: 100%">
                  <canvas style="height: 100%; width: 100%; flex: none" id="greenCoverChartPrint"></canvas>
                </div>
              </div>
            </div>
            <div style="flex: 1; display: flex; flex-direction: column; height: 100%">
              <div style="flex: 1;">
                <div style="display: flex; flex-direction: column; height: 100%">
                  <span style="color: #000000; font-size: 20px; font-weight: 700; font-style: italic">Plant Health</span>
                  <div style="width: 100%; height: 15px" v-for="(data, index) of dataTablePlantHealth" :key="index">
                    <div style="display: flex">
                      <div style="width: 100px">
                        <span :style="{'color': data.color}"  style="font-size: 12px; width: 100px; color: green; font-weight: bold">{{ data.label }}</span>
                      </div>
                      <div :style="{'background-color': data.color}" style="height: 6px; width: 90px; margin-top: 8px"/>
                      <div style="width: 60px; text-align: end">
                        <span style="font-size: 12px; width: 60px; color: green">{{ transformNumber(data.value.toFixed(0)) }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style="flex: 1;">
                <div style="display: flex; flex-direction: column; height: 100%">
                  <span class="pb-3" style="color: #000000; font-size: 20px; font-weight: 700; font-style: italic">Green Density</span>
                  <div style="width: 100%; height: 15px" v-for="(data, index) of dataTableGreenDensity" :key="index">
                    <div style="display: flex;">
                      <div style="width: 100px; flex: none">
                        <span :style="{'color': data.color}"  style="font-size: 12px; width: 100px; color: green; font-weight: bold">{{ data.label }}</span>
                      </div>
                      <div :style="{'background-color': data.color}" style="height: 6px; width: 90px; margin-top: 8px"/>
                      <div style="width: 60px; text-align: end">
                        <span style="font-size: 12px; width: 60px; color: green;">{{  transformNumber(data.value.toFixed(0)) }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="flex: 1; display: flex">
            <div style="flex: 1; display: flex; flex-direction: column">
              <div style="flex: none; height: 30px; width: 100%">
                <div style="display: flex; align-items: center; height: 100%; width: 100%; margin-left: 40px">
                  <span style="font-size: 14px; color: #893FF2; text-transform: uppercase; font-weight: 500">Plant health</span>
                </div>
              </div>
              <div style="display: flex; height: 100%">
                <canvas style="height: 100%; width: 100%; flex: none" id="greenCoverDataChart"></canvas>
              </div>
            </div>
            <div style="flex: 1; display: flex; flex-direction: column">
              <div style="flex: none; height: 30px; width: 100%">
                <div style="display: flex; align-items: center; height: 100%; width: 100%; margin-left: 40px">
                  <span style="font-size: 14px; color: #893FF2; text-transform: uppercase; font-weight: 500">Green Density</span>
                </div>
              </div>
              <div style="display: flex; height: 100%">
                <canvas style="height: 100%; width: 100%; flex: none" id="greenDensity"></canvas>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="display: flex; flex-direction: column; height: 1122px; width: 100%" class="pdf-item">
        <div style="flex: none; height: 50px; width: 100%; background-color: #EBE3ED">
          <div style="display: flex; height: 100%; padding-top: 10px; padding-left: 30px; padding-right: 30px">
            <span style="font-size: 28px; font-style: italic; font-weight: bold">GEOMINERABA MONTHLY REPORT</span>
            <v-spacer/>
            REPORT DATED:
          </div>
        </div>
        <div style="height: 100%; margin: 15px; display: flex; flex-direction: column; border: 1px solid lightgray">
          <div style="flex: none; width: 100%; height: 40px; background-color: rgba(186,191,201,0.47); padding-left: 32px">
            <div style="height: 100%; display: flex; align-items: center">
              GREEN COVER ANALYSIS -  GREEN & NON GREEN AREAS
            </div>
          </div>
          <div style="flex: none; height: 30px; width: 100%; padding-left: 40px; text-transform: uppercase; color: black; font-size: 12px; padding-top: 10px">
            preview of Green Cover boundary overlay the month mosaic
          </div>
          <div style="height: 100%; width: 100%; display: flex; padding: 15px;">
            <img alt="greenCover" :src="green ? green : ''"/>
            {{green}}
<!--            <green-cover @loadNextMap="greenCover2 = true" v-if="greenCover1"/>-->
          </div>
          <div style="flex: none; height: 40px; width: 100%; border: 2px #893FF2 solid; margin-top: 5px">
            <div style="display: flex; height: 100%; padding-top: 10px; padding-left: 30px; padding-right: 30px">
              <span style="font-size: 16px; font-style: italic; font-weight: bold; color: #893FF2">GREEN COVER AREA</span>
            </div>
          </div>
          <div style="flex: none; height: 30px; width: 100%; padding-left: 40px; text-transform: uppercase; color: black; font-size: 12px; padding-top: 10px">
            preview of Non-Green Cover boundary overlay the month mosaic (only showing non-green)
          </div>
          <div style="height: 100%; width: 100%; display: flex; padding: 15px;">
            <img alt="greenCover2" :src="greenV2 ? greenV2 : ''"/>
<!--            <green-cover-v2 @loadNextMap="plantHealth = true" v-if="greenCover2"/>-->
          </div>
          <div style="flex: none; height: 40px; width: 100%; border: 2px #893FF2 solid; margin-top: 5px">
            <div style="display: flex; height: 100%; padding-top: 10px; padding-left: 30px; padding-right: 30px">
              <span style="font-size: 16px; font-style: italic; font-weight: bold; color: #893FF2">NON GREEN AREA</span>
            </div>
          </div>
        </div>
      </div>
      <div style="flex-direction: column; display: flex; height: 1122px; width: 100%" class="pdf-item">
        <div style="flex: none; height: 50px; width: 100%; background-color: #EBE3ED">
          <div style="display: flex; height: 100%; padding-top: 10px; padding-left: 30px; padding-right: 30px">
            <span style="font-size: 28px; font-style: italic; font-weight: bold">GEOMINERABA MONTHLY REPORT</span>
            <v-spacer/>
            REPORT DATED:
          </div>
        </div>
        <div style="height: 100%; display: flex; flex-direction: column; margin: 15px; border: 1px solid lightgray">
          <div style="flex: none; width: 100%; height: 40px; background-color: rgba(186,191,201,0.47); padding-left: 32px">
            <div style="height: 100%; display: flex; align-items: center">
              PLANT HEALTH ANALYSIS
            </div>
          </div>
          <div style="flex: none; height: 30px; width: 100%; padding-left: 40px; text-transform: uppercase; color: black; font-size: 12px; padding-top: 10px">
            preview of Green Cover boundary overlay the month mosaic
          </div>
          <div style="height: 100%; width: 100%; display: flex; padding: 15px">
            <img alt="plantHealth" :src="plantHealthImg ? plantHealthImg : ''"/>
<!--            <plant-health @loadNextMap="plantHealth2 = true" v-if="plantHealth"/>-->
          </div>
          <div style="flex: none; height: 40px; width: 100%; border: 2px #893FF2 solid; margin-top: 5px">
            <div style="display: flex; height: 100%; padding-top: 10px; padding-left: 30px; padding-right: 30px">
              <span style="font-size: 16px; font-style: italic; font-weight: bold; color: #893FF2">GREEN COVER AREA</span>
            </div>
          </div>
          <div style="flex: none; height: 30px; width: 100%; padding-left: 40px; text-transform: uppercase; color: black; font-size: 12px; padding-top: 10px">
            preview of Green Cover boundary overlay the month mosaic
          </div>
          <div style="height: 100%; width: 100%; display: flex; padding: 15px;">
            <img alt="plantHealthV2" :src="plantHealthImgV2 ? plantHealthImgV2 : ''"/>
<!--            <plant-health-v2 @loadNextMap="plantHealth3 = true" v-if="plantHealth2"/>-->
          </div>
          <div style="flex: none; height: 40px; width: 100%; border: 2px #893FF2 solid; margin-top: 5px">
            <div style="display: flex; height: 100%; padding-top: 10px; padding-left: 30px; padding-right: 30px">
              <span style="font-size: 16px; font-style: italic; font-weight: bold; color: #893FF2">NON GREEN AREA</span>
            </div>
          </div>
        </div>
      </div>
      <div style="display: flex; flex-direction: column; height: 1122px; width: 100%" class="pdf-item">
        <div style="flex: none; height: 60px; width: 100%; background-color: #EBE3ED">
          <div style="display: flex; height: 100%; padding-top: 10px; padding-left: 30px; padding-right: 30px">
            <span style="font-size: 28px; font-style: italic; font-weight: bold">GEOMINERABA MONTHLY REPORT</span>
            <v-spacer/>
            REPORT DATED:
          </div>
        </div>
        <div class="flex" style="flex: 1; margin: 15px; display: flex; flex-direction: column; border: 1px solid lightgray">
          <div style="width: 100%; height: 40px; background-color: rgba(186,191,201,0.47); padding-left: 32px">
            <div style="height: 100%; display: flex; align-items: center; text-transform: uppercase">
              plant health analysis
            </div>
          </div>
          <div style="height: 100%; width: 100%; display: flex; padding: 15px;">
            <img alt="plantHealthV3" :src="plantHealthImgV3 ? plantHealthImgV3 : ''"/>
<!--            <plant-health-v3 v-if="plantHealth3"/>-->
          </div>
          <div style="flex: none; height: 40px; width: 100%; border: 2px #893FF2 solid; margin-top: 5px">
            <div style="display: flex; height: 100%; padding-top: 10px; padding-left: 30px; padding-right: 30px">
              <span style="font-size: 16px; font-style: italic; font-weight: bold; color: #893FF2">NON GREEN AREA</span>
            </div>
          </div>
        </div>
        <div class="flex" style="flex: 1; margin: 15px; display: flex; flex-direction: column; border: 1px solid lightgray">
          <div style="width: 100%; height: 40px; background-color: rgba(186,191,201,0.47); padding-left: 32px">
            <div style="height: 100%; display: flex; align-items: center; text-transform: uppercase">
              plant health analysis
            </div>
          </div>
          <div style="height: 100%; width: 100%; display: flex; padding: 15px;">
            <canvas style="height: 100%; width: 100%; flex: none" id="monthChart"></canvas>
          </div>
        </div>
      </div>
      <div style="display: flex; flex-direction: column; height: 1122px; width: 100%" class="pdf-item">
        <div style="flex: none; height: 60px; width: 100%; background-color: #EBE3ED">
          <div style="display: flex; height: 100%; padding-top: 10px; padding-left: 30px; padding-right: 30px">
            <span style="font-size: 28px; font-style: italic; font-weight: bold">GEOMINERABA MONTHLY REPORT</span>
            <v-spacer/>
            REPORT DATED:
          </div>
        </div>
        <div class="flex" style="flex: 1; padding: 10px">
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Datasource from "@/components/home/dataSource";
import VueHtml2pdf from "vue-html2pdf";
import Chart from "chart.js/auto";
import {getAOI, getDataDashBoard} from "@/backend";
import MonthTransform from "@/ultis/converMonth";
import numberFormat from "@/ultis/comma";

export default {
  name: "PdfContent",
  components: {
    Datasource,
    VueHtml2pdf
  },
  data() {
    return {
      loading: false,
      greenCover1: false,
      greenCover2: false,
      plantHealth: false,
      plantHealth2: false,
      plantHealth3: false,
      chartLegend: [],
      chartMonth: undefined,
      myChart: undefined,
      greenCoverChart: undefined,
      greenDensityChart: undefined,
      aoi: undefined,
      greenCoverData: {},
      chartData: [],
      labels: [],
      dataTableGreenDensity: [],
      dataTablePlantHealth: [],
      aois: [],
    }
  },
  props: {
    AOI: {
      type: String
    },
    green: {
      type: String
    },
    greenV2: {
      type: String
    },
    plantHealthImg: {
      type: String
    },
    plantHealthImgV2: {
      type: String
    },
    plantHealthImgV3: {
      type: String
    },
  },
  mounted() {
    this.getData()
  },
  computed: {
    groupLegend () {
      if (!this.myChart) return []
      let groups = []
      this.myChart.data.datasets.forEach(dataset => {
        this.chartLegend.forEach(legend => {
          if (dataset.label.toUpperCase() === legend.text.toUpperCase()) {
            if (!groups.length) {
              groups.push({
                name: dataset.stack,
                stack: dataset.stack,
                legend: [legend]
              })
            } else {
              let index = groups.findIndex(val => val.stack === dataset.stack)
              if (index >= 0) groups[index].legend.push(legend)
              else  groups.push({
                name: dataset.stack,
                stack: dataset.stack,
                legend: [legend]
              })
            }
          }
        })
      })
      return groups
    }
  },
  methods: {
    displayChartMonth () {
      if (!this.greenCoverData) return
      this.transformData()
      const data = {
        labels: this.convertMonth(this.greenCoverData.labels),
        datasets: this.chartData
      }
      let ctx = document.getElementById('monthChart')
      this.chartMonth = new Chart(ctx, {
        data: data,
        options: {
          plugins: {
            htmlLegend: {
              containerID: 'legend-container',
            },
            legend: {
              display: false,
              position: window.innerHeight > 850 ? 'right' : 'top'
            }
          },
          pointBorderColor: '#893FF2',
          pointBorderWidth: 3,
          pointBackgroundColor: '#fff',
          scales: {
            x: {
              stacked: true,
              grid: {
                display: false
              }
            },
            y: {
              title: {
                text: 'GREEN COVER AREA SQKM',
                display: true
              },
              stacked: true,
              grid: {
                display: true
              }
            }
          },
          tooltips: {
            callbacks: {
              label: function(tooltipItem) {
                return tooltipItem.yLabel;
              }
            }
          }
        }
      })
    },
    refreshChart () {
      this.updateChartData(this.chartData, this.convertMonth(this.greenCoverData.labels))
    },
    transformData () {
      this.chartData = []
      this.chartData.push({
        type: 'line',
        label:  this.greenCoverData.area_change.label,
        data:  this.greenCoverData.area_change.data,
        fill: true,
        pointRadius: 7,
        pointHoverRadius: 7,
        backgroundColor: 'rgba(137,63,242,0.35)',
        stack: 'Green area change'
      })
      this.chartData.push({
        type: 'bar',
        label:  this.greenCoverData.green_area.label,
        data:  this.greenCoverData.green_area.data,
        barPercentage: 0.8,
        backgroundColor:  'rgb(54, 162, 235)',
        borderColor:  'rgb(54, 162, 235)',
        stack: 'Green area'
      })
      this.greenCoverData.plant_health.forEach(val => {
        this.chartData.push({
          type: 'bar',
          label: val.label,
          data: val.data,
          barPercentage: 0.8,
          backgroundColor: val.color,
          borderColor: '#6300f7',
          borderWidth: 1,
          stack: 'Plant health'
        })
      })
      this.greenCoverData.green_density.forEach(val => {
        this.chartData.push({
          type: 'bar',
          label: val.label,
          data: val.data,
          barPercentage: 0.8,
          backgroundColor: val.color,
          borderColor: '#f3f3f3',
          borderWidth: 1,
          stack: 'Density'
        })
      })
    },
    changeDisplayChart (legend) {
      this.myChart.setDatasetVisibility(legend.datasetIndex, !this.myChart.isDatasetVisible(legend.datasetIndex));
      this.myChart.update()
    },
    updateChartData (newData, labels) {
      this.myChart.data.datasets = newData
      this.myChart.data.labels = labels
      this.myChart.update()
    },
    monthChart () {
      this.chartData.push({
        type: 'line',
        label:  this.greenCoverData.area_change.label,
        data:  [this.greenCoverData.area_change.data[1]],
        fill: true,
        pointRadius: 7,
        pointHoverRadius: 7,
        backgroundColor: 'rgba(137,63,242,0.35)',
        stack: 'Green area change'
      })
      this.chartData.push({
        type: 'bar',
        label:  this.greenCoverData.green_area.label,
        data:  [this.greenCoverData.green_area.data[1]],
        barPercentage: 0.8,
        backgroundColor:  'rgb(54, 162, 235)',
        borderColor:  'rgb(54, 162, 235)',
        stack: 'Green area'
      })
      this.greenCoverData.plant_health.forEach(val => {
        this.chartData.push({
          type: 'bar',
          label: val.label,
          data: [val.data[1]],
          barPercentage: 0.8,
          backgroundColor: val.color,
          borderColor: val.color,
          stack: 'Plant health'
        })
      })
      this.greenCoverData.green_density.forEach(val => {
        this.chartData.push({
          type: 'bar',
          label: val.label,
          data: [val.data[1]],
          barPercentage: 0.8,
          backgroundColor: val.color,
          borderColor: val.color,
          stack: 'Density'
        })
      })
    },
    displayChart () {
      this.displayChartGreenArea()
      this.displayChartGreenCover()
      this.displayChartGreenDensity()
    },
    displayChartGreenArea() {
      if (!this.greenCoverData) return
      this.monthChart()
      const data = {
        labels: this.convertMonth([this.greenCoverData.labels[1]]),
        datasets: this.chartData
      }
      let ctx = document.getElementById('greenCoverChartPrint')
      this.myChart = new Chart(ctx, {
        data: data,
        options: {
          plugins: {
            legend: {
              display: false
            }
          },
          pointBorderColor: '#893FF2',
          pointBorderWidth: 3,
          pointBackgroundColor: '#fff',
          scales: {
            x: {
              stacked: true,
              grid: {
                display: false
              }
            },
            y: {
              title: {
                display: false
              },
              stacked: true,
              grid: {
                display: true
              }
            }
          },
          tooltips: {
            callbacks: {
              label: function(tooltipItem) {
                return tooltipItem.yLabel;
              }
            }
          }
        }
      })
    },
    displayChartGreenCover() {
      let datasetPlantHealth = []
      this.greenCoverData.plant_health.forEach(val => {
        datasetPlantHealth.push({
          type: 'bar',
          label: val.label,
          data: val.data,
          barPercentage: 0.8,
          backgroundColor: val.color,
          borderColor: '#6300f7',
          borderWidth: 1,
        })
      })
      const dataPlantHealth = {
        labels: this.convertMonth([this.greenCoverData.labels[1]]),
        datasets: datasetPlantHealth
      }
      let ctx1 = document.getElementById('greenCoverDataChart')
      this.greenCoverChart = new Chart(ctx1, {
        data: dataPlantHealth,
        options: {
          plugins: {
            legend: {
              display: false
            }
          },
          pointBorderColor: '#893FF2',
          pointBorderWidth: 3,
          pointBackgroundColor: '#fff',
          scales: {
            x: {
              stacked: false,
              grid: {
                display: false
              }
            },
            y: {
              title: {
                display: false
              },
              stacked: false,
              grid: {
                display: true
              }
            }
          },
          tooltips: {
            callbacks: {
              label: function(tooltipItem) {
                return tooltipItem.yLabel;
              }
            }
          }
        }
      })
    },
    displayChartGreenDensity() {
      let datasetGreenDensity = []
      this.greenCoverData.green_density.forEach(val => {
        datasetGreenDensity.push({
          type: 'bar',
          label: val.label,
          data: val.data,
          barPercentage: 0.8,
          backgroundColor: val.color,
          borderColor: '#6300f7',
          borderWidth: 1,
        })
      })
      const dataGreenDensity = {
        labels: this.convertMonth([this.greenCoverData.labels[1]]),
        datasets: datasetGreenDensity
      }
      let ctx1 = document.getElementById('greenDensity')
      this.greenCoverChart = new Chart(ctx1, {
        data: dataGreenDensity,
        options: {
          plugins: {
            legend: {
              display: false,
            }
          },
          pointBorderColor: '#893FF2',
          pointBorderWidth: 3,
          pointBackgroundColor: '#fff',
          scales: {
            x: {
              stacked: false,
              grid: {
                display: false
              }
            },
            y: {
              title: {
                display: false
              },
              stacked: false,
              grid: {
                display: true
              }
            }
          },
          tooltips: {
            callbacks: {
              label: function(tooltipItem) {
                return tooltipItem.yLabel;
              }
            }
          }
        }
      })
    },
    async getAOI () {
      try {
        this.loading = true
        const res = await getAOI()
        this.aois = res.data.data
        if (!this.aoi) {
          let sing = this.aois.find(val => val.name === 'AOI Entire Singapore')
          if (sing) this.aoi = sing
          else this.aoi = this.aois[1]
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async getData () {
      // try {
        this.loading = true
        if (!this.aoi) await this.getAOI()
        let month = '04'
        const res = await getDataDashBoard({
          month: month,
          compare_month: '01',
          year: '2021',
          source: 'sentinel',
          overview_type: 'overall_green_cover',
          aoi_id: this.aoi.id
        })
        this.dataTablePlantHealth = []
        this.dataTableGreenDensity = []
        this.greenCoverData = {}
        // let data = res.data.data
        let demoData = res.data.data
        this.dataTablePlantHealth = demoData.plan_health
        this.dataTableGreenDensity = demoData.green_density
        this.greenCoverData = demoData.green_cover_change_in_year
        this.displayChartMonth()
        this.labels= []
        if (!this.myChart) this.displayChart()
        else {
          this.transformData()
          this.updateChartData(this.chartData, this.convertMonth(this.greenCoverData.labels))
        }
      // } catch (e) {
      //   console.log(e.message)
      // } finally {
      //   this.loading = false
      // }
    },
    convertMonth (monthArray) {
      return MonthTransform.transformMonthTextArray(monthArray)
    },
    transformNumber (number) {
      return numberFormat.numberWithCommas(number)
    }
  }
}
</script>

<style scoped>

</style>
