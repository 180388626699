<template>
  <div style="position: relative; height: 100%; width:100%; overflow: hidden">
    <div id="mapAOI"/>
  </div>
</template>

<script>
import MapboxDraw from '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw'
import DrawRectangleAssisted from '@geostarters/mapbox-gl-draw-rectangle-assisted-mode'
import DrawRectangle from 'mapbox-gl-draw-rectangle-mode'
import {CircleMode, DirectMode, DragCircleMode, SimpleSelectMode,} from '@/ultis/draw'
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import DrawTool from "@/components/home/draw/DrawTool";
import {RulerControl, CompassControl, ZoomControl} from 'mapbox-gl-controls'
import bbox from '@turf/bbox'
import LayerControl from "@/components/home/dataSource/LayerControl";
import numberFormat from "@/ultis/comma";
import {inspectImageData} from "@/backend";
import randomColor from "@/ultis/randomColor";
let map
let draw
export default {
  components: {LayerControl, DrawTool},
  name: "BaseMap",
  data() {
    return {
      center: {lng: 0, lat: 0},
      isBaseMap: true,
      zoom: 0,
      layers: [],
      listImage: [],
      currentAOI: undefined,
      addedLayer: undefined
    }
  },
  props: {
    dataSource: {
    }
  },
  mounted() {
    window.mapboxgl.accessToken = 'pk.eyJ1IjoiaG9hdGllbnR1IiwiYSI6ImNrYXMwNmt4ZzA4YTIyeXAzcjZicmhsNXMifQ.9hvfCuoiO1-1cFmikE14LA'
    map = new window.mapboxgl.Map({
      container: 'mapAOI', // container id
      style: 'https://tiles.eofactory.ai/styles/basic/style.json',
      center: [103.816945, 1.355359], // starting position
      zoom: 9.5, // starting zoom,
      attributionControl: false,
      preserveDrawingBuffer: true
    })

    draw = new MapboxDraw({
      keybindings: true,
      displayControlsDefault: false,
      userProperties: true,
      controls: {
        line_string: false,
        polygon: false,
        trash: false
      },
      modes: {
        ...MapboxDraw.modes,
        draw_assisted_rectangle: DrawRectangleAssisted,
        draw_rectangle: DrawRectangle,
        draw_circle: CircleMode,
        drag_circle: DragCircleMode,
        direct_select: DirectMode,
        simple_select: SimpleSelectMode
      }
    })
    // map.addControl(new window.mapboxgl.ScaleControl({
    //   maxWidth: 80,
    //   unit: 'metric'
    // }), 'bottom-right')
    // map.addControl(new ZoomControl(), 'bottom-right')
    // map.addControl(new CompassControl(), 'bottom-right')
    // // map.addControl(new RulerControl(), 'bottom-right')
    // map.addControl(draw)
    map.on('style.load', () => {
      map.resize()
      this.center = map.getCenter()
      this.zoom = map.getZoom().toFixed(2)
      this.addSingaporeBoundary()
      this.$emit('loadNextMap')
    })
    map.on('draw.create', this.changeAOI)
    map.on('mousemove', (e) => {
      this.center = e.lngLat.wrap()
    })
    map.on('zoom', _ => {
      this.zoom = map.getZoom().toFixed(2)
    })
    map.on('click', this.onMapClick)
  },
  watch: {
    dataSource (dataSource) {
      if (dataSource){
        switch (this.dataSource) {
          case 'sentinel': this.submitZoom([103.60570070513, 1.1586987006352, 104.08848306516, 1.4707748320846])
            break
          case 'planet':
          case 'jilin': this.submitZoom([103.91033, 1.36177, 104.09282, 1.4416])
            break
        }
      }
    }
  },
  destroyed() {
    if (map) {
      map.remove()
      draw = undefined
      map = undefined
    }

  },
  methods: {
    getImage () {
      const mapCanvas = map.getCanvas()
      const baseCanvas = document.createElement('canvas')
      baseCanvas.width = mapCanvas.width
      baseCanvas.height = mapCanvas.height
      return mapCanvas.toDataURL()
    },
    addSingaporeBoundary () {
      this.currentAOI = {
        "id": 185,
        "name": "ADMIRALTY_PK_kml_288",
        "region_name": null,
        "geometry": {
          "type": "FeatureCollection",
          "features": [
            {
              "id": "0",
              "type": "Feature",
              "properties": {
                "Description": "Unknown Area Type",
                "Name": "ADMIRALTY_PK_kml_288"
              },
              "geometry": {
                "type": "Polygon",
                "coordinates": [
                  [
                    [
                      103.7852274078,
                      1.4416628932,
                      0
                    ],
                    [
                      103.7852870192,
                      1.4417362745,
                      0
                    ],
                    [
                      103.7853116491,
                      1.4417647173,
                      0
                    ],
                    [
                      103.7848426673,
                      1.4427939994,
                      0
                    ],
                    [
                      103.7841152857,
                      1.4433294844,
                      0
                    ],
                    [
                      103.7834496077,
                      1.4439913101,
                      0
                    ],
                    [
                      103.7826601357,
                      1.4447763342,
                      0
                    ],
                    [
                      103.7825473147,
                      1.4460756766,
                      0
                    ],
                    [
                      103.7825471799,
                      1.4460772312,
                      0
                    ],
                    [
                      103.7822841385,
                      1.4458291864,
                      0
                    ],
                    [
                      103.7819394266,
                      1.4454420568,
                      0
                    ],
                    [
                      103.7814602757,
                      1.4446492629,
                      0
                    ],
                    [
                      103.7812595516,
                      1.4440420235,
                      0
                    ],
                    [
                      103.78125248,
                      1.4440244054,
                      0
                    ],
                    [
                      103.781251941,
                      1.444019142,
                      0
                    ],
                    [
                      103.7811865903,
                      1.4438263754,
                      0
                    ],
                    [
                      103.7812537279,
                      1.4437989856,
                      0
                    ],
                    [
                      103.7814649067,
                      1.44371283,
                      0
                    ],
                    [
                      103.7819414458,
                      1.4435597774,
                      0
                    ],
                    [
                      103.7824046228,
                      1.4434007556,
                      0
                    ],
                    [
                      103.7827622146,
                      1.4432360248,
                      0
                    ],
                    [
                      103.7829197585,
                      1.4431571678,
                      0
                    ],
                    [
                      103.7834005151,
                      1.4428555009,
                      0
                    ],
                    [
                      103.7835018426,
                      1.4427821503,
                      0
                    ],
                    [
                      103.783760823,
                      1.4425722351,
                      0
                    ],
                    [
                      103.7840674304,
                      1.4423047764,
                      0
                    ],
                    [
                      103.7846025196,
                      1.4418271756,
                      0
                    ],
                    [
                      103.7847071714,
                      1.4417740374,
                      0
                    ],
                    [
                      103.7849293352,
                      1.4416054055,
                      0
                    ],
                    [
                      103.7849673551,
                      1.4415949157,
                      0
                    ],
                    [
                      103.785000612,
                      1.441604765,
                      0
                    ],
                    [
                      103.7850182152,
                      1.4416185478,
                      0
                    ],
                    [
                      103.7850426655,
                      1.4416451276,
                      0
                    ],
                    [
                      103.7851548391,
                      1.4415638187,
                      0
                    ],
                    [
                      103.7851633665,
                      1.4415754037,
                      0
                    ],
                    [
                      103.7851653164,
                      1.4415780717,
                      0
                    ],
                    [
                      103.7851710224,
                      1.4415858041,
                      0
                    ],
                    [
                      103.7852274078,
                      1.4416628932,
                      0
                    ]
                  ]
                ]
              }
            },
            {
              "id": "1",
              "type": "Feature",
              "properties": {
                "Description": "Unknown Area Type",
                "Name": "ADMIRALTY_PK_kml_288"
              },
              "geometry": {
                "type": "Polygon",
                "coordinates": [
                  [
                    [
                      103.7809396609,
                      1.4438233663,
                      0
                    ],
                    [
                      103.7809466196,
                      1.4438247782,
                      0
                    ],
                    [
                      103.7809813944,
                      1.4438667876,
                      0
                    ],
                    [
                      103.7810102383,
                      1.4439547476,
                      0
                    ],
                    [
                      103.7810368161,
                      1.4440357954,
                      0
                    ],
                    [
                      103.7808631497,
                      1.4441782829,
                      0
                    ],
                    [
                      103.780725221,
                      1.4443019152,
                      0
                    ],
                    [
                      103.7805501427,
                      1.4444932834,
                      0
                    ],
                    [
                      103.7803321512,
                      1.4449019329,
                      0
                    ],
                    [
                      103.7802650765,
                      1.4450395393,
                      0
                    ],
                    [
                      103.7801188837,
                      1.4454172884,
                      0
                    ],
                    [
                      103.7800711373,
                      1.4455804706,
                      0
                    ],
                    [
                      103.780014537,
                      1.4458617896,
                      0
                    ],
                    [
                      103.779868467,
                      1.4463622969,
                      0
                    ],
                    [
                      103.7798127516,
                      1.4464607446,
                      0
                    ],
                    [
                      103.7796041463,
                      1.4466492903,
                      0
                    ],
                    [
                      103.779533434,
                      1.446713209,
                      0
                    ],
                    [
                      103.7794775209,
                      1.4468079669,
                      0
                    ],
                    [
                      103.779005139,
                      1.4478193683,
                      0
                    ],
                    [
                      103.7789437081,
                      1.4479324755,
                      0
                    ],
                    [
                      103.7788644489,
                      1.4480425527,
                      0
                    ],
                    [
                      103.7787693387,
                      1.4481454398,
                      0
                    ],
                    [
                      103.7784697638,
                      1.4485043472,
                      0
                    ],
                    [
                      103.7783271061,
                      1.4487053385,
                      0
                    ],
                    [
                      103.7782105977,
                      1.4489087269,
                      0
                    ],
                    [
                      103.7781934685,
                      1.4489887988,
                      0
                    ],
                    [
                      103.7781796798,
                      1.4491599673,
                      0
                    ],
                    [
                      103.7781321282,
                      1.4494327492,
                      0
                    ],
                    [
                      103.778106208,
                      1.4494754725,
                      0
                    ],
                    [
                      103.7780777338,
                      1.4495224065,
                      0
                    ],
                    [
                      103.7780774552,
                      1.4495223893,
                      0
                    ],
                    [
                      103.778030668,
                      1.4494033007,
                      0
                    ],
                    [
                      103.7779795587,
                      1.4492732158,
                      0
                    ],
                    [
                      103.7779234292,
                      1.4490255012,
                      0
                    ],
                    [
                      103.777950515,
                      1.4490192862,
                      0
                    ],
                    [
                      103.7779514118,
                      1.44901908,
                      0
                    ],
                    [
                      103.7778849861,
                      1.4486949727,
                      0
                    ],
                    [
                      103.7776970821,
                      1.4478636502,
                      0
                    ],
                    [
                      103.7775050731,
                      1.4470122661,
                      0
                    ],
                    [
                      103.7775044432,
                      1.4470094752,
                      0
                    ],
                    [
                      103.7774924033,
                      1.4469554926,
                      0
                    ],
                    [
                      103.7774924572,
                      1.4469554745,
                      0
                    ],
                    [
                      103.7775314037,
                      1.4469082415,
                      0
                    ],
                    [
                      103.7776131049,
                      1.4468852627,
                      0
                    ],
                    [
                      103.7780872536,
                      1.4467735322,
                      0
                    ],
                    [
                      103.7782011429,
                      1.4467407428,
                      0
                    ],
                    [
                      103.7784492578,
                      1.4466311662,
                      0
                    ],
                    [
                      103.7786197514,
                      1.4465341593,
                      0
                    ],
                    [
                      103.7788080921,
                      1.4463953079,
                      0
                    ],
                    [
                      103.7789789376,
                      1.4462350507,
                      0
                    ],
                    [
                      103.7790561654,
                      1.4461468772,
                      0
                    ],
                    [
                      103.7791298706,
                      1.4460554288,
                      0
                    ],
                    [
                      103.7792586715,
                      1.4458597286,
                      0
                    ],
                    [
                      103.7793597147,
                      1.4456580481,
                      0
                    ],
                    [
                      103.7793743627,
                      1.4456250129,
                      0
                    ],
                    [
                      103.7796193701,
                      1.4450530271,
                      0
                    ],
                    [
                      103.7796759217,
                      1.4449373061,
                      0
                    ],
                    [
                      103.7797202966,
                      1.4448461383,
                      0
                    ],
                    [
                      103.7798360855,
                      1.4446572277,
                      0
                    ],
                    [
                      103.7799157757,
                      1.4445516542,
                      0
                    ],
                    [
                      103.7799695944,
                      1.4444806629,
                      0
                    ],
                    [
                      103.7801192687,
                      1.4443172661,
                      0
                    ],
                    [
                      103.7802286844,
                      1.4442191905,
                      0
                    ],
                    [
                      103.7802840572,
                      1.4441695784,
                      0
                    ],
                    [
                      103.7804620007,
                      1.4440380158,
                      0
                    ],
                    [
                      103.7804633297,
                      1.4440372218,
                      0
                    ],
                    [
                      103.7805243499,
                      1.4440007466,
                      0
                    ],
                    [
                      103.7805286416,
                      1.4439981802,
                      0
                    ],
                    [
                      103.7806515536,
                      1.4439235369,
                      0
                    ],
                    [
                      103.7807534648,
                      1.44387645,
                      0
                    ],
                    [
                      103.7808576494,
                      1.4438271194,
                      0
                    ],
                    [
                      103.7808911045,
                      1.4438135176,
                      0
                    ],
                    [
                      103.7809396609,
                      1.4438233663,
                      0
                    ]
                  ]
                ]
              }
            },
            {
              "id": "2",
              "type": "Feature",
              "properties": {
                "Description": "Unknown Area Type",
                "Name": "ADMIRALTY_PK_kml_288"
              },
              "geometry": {
                "type": "Polygon",
                "coordinates": [
                  [
                    [
                      103.7776662439,
                      1.4492675115,
                      0
                    ],
                    [
                      103.7777123917,
                      1.4494445408,
                      0
                    ],
                    [
                      103.7778053008,
                      1.4496977838,
                      0
                    ],
                    [
                      103.7778556197,
                      1.4498107663,
                      0
                    ],
                    [
                      103.7778407717,
                      1.4498282029,
                      0
                    ],
                    [
                      103.7768471178,
                      1.4509949958,
                      0
                    ],
                    [
                      103.7766284352,
                      1.4509561569,
                      0
                    ],
                    [
                      103.7761232982,
                      1.4508538335,
                      0
                    ],
                    [
                      103.7773472191,
                      1.4494184228,
                      0
                    ],
                    [
                      103.7775491224,
                      1.4491822262,
                      0
                    ],
                    [
                      103.7775488169,
                      1.4491820363,
                      0
                    ],
                    [
                      103.7775787411,
                      1.4491469025,
                      0
                    ],
                    [
                      103.7775802391,
                      1.4491451752,
                      0
                    ],
                    [
                      103.777580781,
                      1.4491445512,
                      0
                    ],
                    [
                      103.7776374828,
                      1.4491407091,
                      0
                    ],
                    [
                      103.7776662439,
                      1.4492675115,
                      0
                    ]
                  ]
                ]
              }
            },
            {
              "id": "3",
              "type": "Feature",
              "properties": {
                "Description": "Unknown Area Type",
                "Name": "ADMIRALTY_PK_kml_288"
              },
              "geometry": {
                "type": "Polygon",
                "coordinates": [
                  [
                    [
                      103.778037901,
                      1.4501713404,
                      0
                    ],
                    [
                      103.7781713925,
                      1.4503636022,
                      0
                    ],
                    [
                      103.7783019552,
                      1.4505300897,
                      0
                    ],
                    [
                      103.7783434874,
                      1.450577841,
                      0
                    ],
                    [
                      103.7778971836,
                      1.4511379113,
                      0
                    ],
                    [
                      103.7775288928,
                      1.4511031023,
                      0
                    ],
                    [
                      103.7774482796,
                      1.4510909004,
                      0
                    ],
                    [
                      103.777260221,
                      1.4510624264,
                      0
                    ],
                    [
                      103.7771419566,
                      1.4510458917,
                      0
                    ],
                    [
                      103.7779623492,
                      1.4500699051,
                      0
                    ],
                    [
                      103.7779832692,
                      1.4500450176,
                      0
                    ],
                    [
                      103.778037901,
                      1.4501713404,
                      0
                    ]
                  ]
                ]
              }
            },
            {
              "id": "4",
              "type": "Feature",
              "properties": {
                "Description": "Unknown Area Type",
                "Name": "ADMIRALTY_PK_kml_288"
              },
              "geometry": {
                "type": "Polygon",
                "coordinates": [
                  [
                    [
                      103.7813752107,
                      1.4448075417,
                      0
                    ],
                    [
                      103.7815082784,
                      1.4450642573,
                      0
                    ],
                    [
                      103.7818031514,
                      1.4455588875,
                      0
                    ],
                    [
                      103.7819006285,
                      1.4456905017,
                      0
                    ],
                    [
                      103.7815939236,
                      1.44586756,
                      0
                    ],
                    [
                      103.7814845533,
                      1.445948914,
                      0
                    ],
                    [
                      103.7814013411,
                      1.4460326561,
                      0
                    ],
                    [
                      103.7812800798,
                      1.4462216834,
                      0
                    ],
                    [
                      103.7812158811,
                      1.4463437167,
                      0
                    ],
                    [
                      103.7811611977,
                      1.4465040319,
                      0
                    ],
                    [
                      103.7811279006,
                      1.4466787089,
                      0
                    ],
                    [
                      103.7811281915,
                      1.4469238275,
                      0
                    ],
                    [
                      103.7810285719,
                      1.4469630113,
                      0
                    ],
                    [
                      103.7810282753,
                      1.4469643497,
                      0
                    ],
                    [
                      103.7808359912,
                      1.4470406645,
                      0
                    ],
                    [
                      103.7806505812,
                      1.4471256432,
                      0
                    ],
                    [
                      103.7805490737,
                      1.4471988942,
                      0
                    ],
                    [
                      103.780438778,
                      1.4472680483,
                      0
                    ],
                    [
                      103.7802320059,
                      1.4474535012,
                      0
                    ],
                    [
                      103.7801352241,
                      1.4475665896,
                      0
                    ],
                    [
                      103.7800714563,
                      1.4477015733,
                      0
                    ],
                    [
                      103.7799692069,
                      1.4479893845,
                      0
                    ],
                    [
                      103.7798681326,
                      1.4483648738,
                      0
                    ],
                    [
                      103.7798525723,
                      1.4485995647,
                      0
                    ],
                    [
                      103.7798669259,
                      1.4488552557,
                      0
                    ],
                    [
                      103.7799161909,
                      1.4490798735,
                      0
                    ],
                    [
                      103.7799846953,
                      1.4492880053,
                      0
                    ],
                    [
                      103.7800909321,
                      1.4494916976,
                      0
                    ],
                    [
                      103.7802329693,
                      1.4496879568,
                      0
                    ],
                    [
                      103.7802946203,
                      1.4497731312,
                      0
                    ],
                    [
                      103.7803850885,
                      1.4498989936,
                      0
                    ],
                    [
                      103.7806810691,
                      1.4503107481,
                      0
                    ],
                    [
                      103.7811817211,
                      1.4508527627,
                      0
                    ],
                    [
                      103.7812495278,
                      1.4509292734,
                      0
                    ],
                    [
                      103.7812734111,
                      1.4509969204,
                      0
                    ],
                    [
                      103.7812818201,
                      1.4510793352,
                      0
                    ],
                    [
                      103.7812520029,
                      1.4511518554,
                      0
                    ],
                    [
                      103.781153343,
                      1.4512583328,
                      0
                    ],
                    [
                      103.7810703478,
                      1.451276581,
                      0
                    ],
                    [
                      103.7808422814,
                      1.451337385,
                      0
                    ],
                    [
                      103.7805994519,
                      1.4513550958,
                      0
                    ],
                    [
                      103.7803792773,
                      1.4513295695,
                      0
                    ],
                    [
                      103.7801741365,
                      1.4512905685,
                      0
                    ],
                    [
                      103.7799848024,
                      1.4512412853,
                      0
                    ],
                    [
                      103.7798919955,
                      1.4512117556,
                      0
                    ],
                    [
                      103.779717052,
                      1.4511454716,
                      0
                    ],
                    [
                      103.7797105336,
                      1.4511430016,
                      0
                    ],
                    [
                      103.7796604434,
                      1.4511203371,
                      0
                    ],
                    [
                      103.7796183992,
                      1.4511013137,
                      0
                    ],
                    [
                      103.7794753542,
                      1.4510365886,
                      0
                    ],
                    [
                      103.779398345,
                      1.4509988658,
                      0
                    ],
                    [
                      103.7793886591,
                      1.4509930487,
                      0
                    ],
                    [
                      103.7792677622,
                      1.4509204453,
                      0
                    ],
                    [
                      103.7792632333,
                      1.4509175241,
                      0
                    ],
                    [
                      103.7791781551,
                      1.4508627629,
                      0
                    ],
                    [
                      103.7791099256,
                      1.4508087345,
                      0
                    ],
                    [
                      103.7790098865,
                      1.4507187118,
                      0
                    ],
                    [
                      103.7788708579,
                      1.4505936079,
                      0
                    ],
                    [
                      103.7785602273,
                      1.4503140798,
                      0
                    ],
                    [
                      103.778487982,
                      1.4502216349,
                      0
                    ],
                    [
                      103.7783464123,
                      1.4500210075,
                      0
                    ],
                    [
                      103.7782915995,
                      1.4499446508,
                      0
                    ],
                    [
                      103.7781923165,
                      1.4498053484,
                      0
                    ],
                    [
                      103.7782480974,
                      1.4497541365,
                      0
                    ],
                    [
                      103.7782481567,
                      1.4497540814,
                      0
                    ],
                    [
                      103.7782954783,
                      1.4497024976,
                      0
                    ],
                    [
                      103.7783426296,
                      1.4496324649,
                      0
                    ],
                    [
                      103.7783633256,
                      1.4495810976,
                      0
                    ],
                    [
                      103.7783912737,
                      1.4495117155,
                      0
                    ],
                    [
                      103.7784435885,
                      1.4492126167,
                      0
                    ],
                    [
                      103.778459677,
                      1.4490628727,
                      0
                    ],
                    [
                      103.7784959019,
                      1.4489661609,
                      0
                    ],
                    [
                      103.7785687276,
                      1.4488352833,
                      0
                    ],
                    [
                      103.7786433138,
                      1.4487364563,
                      0
                    ],
                    [
                      103.7787859711,
                      1.4485474297,
                      0
                    ],
                    [
                      103.7790175824,
                      1.4482921788,
                      0
                    ],
                    [
                      103.7790831732,
                      1.4482076586,
                      0
                    ],
                    [
                      103.7791624771,
                      1.4481054765,
                      0
                    ],
                    [
                      103.7792180757,
                      1.4480092988,
                      0
                    ],
                    [
                      103.7792687768,
                      1.4479216129,
                      0
                    ],
                    [
                      103.7793399578,
                      1.4477984946,
                      0
                    ],
                    [
                      103.7795888024,
                      1.4472340874,
                      0
                    ],
                    [
                      103.7797913464,
                      1.4468644157,
                      0
                    ],
                    [
                      103.779841651,
                      1.4468246339,
                      0
                    ],
                    [
                      103.7799466812,
                      1.4467415706,
                      0
                    ],
                    [
                      103.7800152102,
                      1.4466643216,
                      0
                    ],
                    [
                      103.7800746541,
                      1.4465973277,
                      0
                    ],
                    [
                      103.7801143028,
                      1.446493137,
                      0
                    ],
                    [
                      103.7801317183,
                      1.4464633296,
                      0
                    ],
                    [
                      103.7802952312,
                      1.4459151084,
                      0
                    ],
                    [
                      103.780380696,
                      1.4455663427,
                      0
                    ],
                    [
                      103.7805519975,
                      1.4451110992,
                      0
                    ],
                    [
                      103.7806857241,
                      1.4448458074,
                      0
                    ],
                    [
                      103.7808283821,
                      1.4446065343,
                      0
                    ],
                    [
                      103.7809805453,
                      1.444453402,
                      0
                    ],
                    [
                      103.7811517312,
                      1.4443289746,
                      0
                    ],
                    [
                      103.7813752107,
                      1.4448075417,
                      0
                    ]
                  ]
                ]
              }
            }
          ]
        },
        "created_at": "2021-10-15T10:05:51.000000Z",
        "updated_at": "2021-10-15T10:05:51.000000Z",
        "code": "ADMIRALTY_PK_kml_288"
      }
      let check = false
      if (map.getStyle().layers.some(val => val.id === 'singapore-boundary')) {
        check = true
        map.removeLayer('singapore-boundary')
      }
      if (check) map.removeSource('singapore-boundary')
      map.addSource('singapore-boundary', {
        'type': 'geojson',
        'data': this.currentAOI.geometry
      })
      map.addLayer({
        'id': 'singapore-boundary',
        'type': 'line',
        'source': 'singapore-boundary',
        'layout': {},
        'paint': {
          'line-color': '#9003fc',
          'line-width': 1
        }
      })
      this.submitZoom(bbox(this.currentAOI.geometry))
    },
    submitZoom(bbox) {
      map.fitBounds(bbox, {
        'duration': 0,
        'padding': 20
      })
    }
  }
}
</script>

<style scoped>
.map-info {
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  border-bottom-left-radius: 25px;
  z-index: 2;
  background-color: #fcfaf9;
  position: absolute;
  bottom: 10px;
  right: 90px;
  width: 220px;
  height: 30px;
}

#mapAOI {
  width: 100%;
  height: 100%;
}

/deep/
.mapbox-compass {
  border-radius: 50%;
}

/deep/
.mapbox-compass button {
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

/deep/
.mapbox-compass svg {
  margin-right: 2px;
  margin-top: 4px;
  width: 25px;
  height: 25px;
}

/deep/
.mapbox-zoom {
  border-radius: 30px;
  box-shadow: none;
}

/deep/
.mapbox-zoom button {
  background-color: white;
  width: 30px;
  height: 30px;
}

/deep/
.mapbox-zoom button:nth-child(1) {
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
}

/deep/
.mapbox-zoom button:nth-child(2) {
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
}
/deep/
.mapboxgl-ctrl-scale {
  font-size: 12px;
  border: 1px solid lightgrey !important;
  text-align: center;
  padding-top: 5px;
  height: 30px;
  width: 75px !important;
  background-color: white;
  border-radius: 30px;

}
/deep/
.mapboxgl-popup-close-button {
  font-size: 20px !important;
  height: 30px !important;
  width: 30px !important;
}
</style>
