import api from '@/api'
import config from '@/config.json'

export async function authLogin(data) {
  let result = await api.post(`${config.backend}/login`, data)
  api.setToken(result.data.data.token)
  return result
}

export async function checkToken() {
  return await api.get(`${config.backend}/me`)
}

export async function authLogout() {
  await api.post(`${config.backend}/logout`)
  api.deleteToken()
  window.location.href = '/'
}
export async function resetPassword(data) {
  return await api.post(`${config.backend}/password/reset`, data)
}
export async function getAllUser(data) {
  return await api.get(`${config.backend}/users`, data)
}
export async function getRole(data) {
  return await api.get(`${config.backend}/roles`, data)
}
export async function createUser(data) {
  return await api.post(`${config.backend}/users`, data)
}
export async function updateUser(form) {
  return await api.put(`${config.backend}/users/${form.id}`, form.data)
}
export async function deleteUser(id) {
  return await api.delete(`${config.backend}/users/${id}`)
}
export async function getDataDashBoard (data) {
  return await api.get(`${config.backend}/imageries/statistics`, data)
}
export async function forgotPassword(data) {
  return await api.post(`${config.backend}/password/email`, data)
}
export async function getImageries (data) {
  return await api.get(`${config.backend}/imageries`, data)
}
export async function getVectors () {
  return await api.get(`${config.backend}/vectors`)
}
export async function inspectData (data) {
  return await api.get(`${config.backend}/imageries/inspects`, data)
}
export async function inspectImageData (data) {
  return await api.get(`${config.backend}/imageries/${data.imageId}/inspects`, data.coordinates)
}
export async function downloadImages (data) {
  return await api.post(`${config.backend}/imageries/download`, data)
}
export async function downloadVectors (data) {
  return await api.post(`${config.backend}/vectors/download`, data)
}
export async function getAOI (data) {
  return await api.get(`${config.backend}/aois`, data)
}

export async function getAOIResults (data) {
  return await api.get(`${config.backend}/aois/results`, data)
}

export async function getGeom (id) {
  return await api.get(`${config.backend}/vectors/${id}/geometry`)
}
export async function getFalseColor (form) {
  return await api.get(`${config.backend}/imageries/false_color`, form)
}
export async function getListReport () {
  return await api.get(`${config.backend}/reports`)
}

export async function genCSV (yymm,filename) {
  return await api.get(`${config.backend}/detection/csv?yymm=` + yymm + '&filename=' + filename)
}

export async function getDetections_Count(yymm,aoi) {
	return await api.get(`${config.backend}/detection/data?yymm=` + yymm + '&aoi=' + aoi.replace('&','%26') )  
}   
export async function getDetections(yymm,aoi,skip,take) {
	return await api.get(`${config.backend}/detection/data?yymm=` + yymm + '&aoi=' + aoi.replace('&','%26') + '&skip=' + skip + '&take=' + take)  
} 
  
export async function detectidfrompt(yymm,lng,lat) {
	return await api.get(`${config.backend}/detections/idfrompt?yymm=` + yymm  + '&lng=' + lng + '&lat=' + lat)  
} 

