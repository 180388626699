<template>
  <v-menu
    ref="menu"
    v-model="dateMenu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    :left="$attrs['left']"
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        style="border-bottom: 2px #893FF2 solid"
        class="month-picker"
        dense
        :label="label"
        hide-details
        v-model="date"
        prepend-inner-icon=""
        readonly
        v-bind="attrs"
        v-on="on"
        :rules="rules"
        :disabled="disabled"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      :min="min"
      :max="max"
      type="month"
      @change="dateMenu = false"
    ></v-date-picker>
  </v-menu>
</template>

<script>
export default {
  props: {
    value: {type: String, default: undefined},
    min: {type: String, default: undefined},
    max: {type: String, default: undefined},
    label: {type: String, default: undefined},
    rules: {type: Array, default: undefined},
    disabled: {type: Boolean, default: false}
  },
  watch: {
    value (val) {
      this.dateModel = this.value
    }
  },
  computed: {
    date: {
      get () {
        return this.dateModel
      },
      set (val) {
        this.dateModel = val
        this.$emit('input', this.dateModel)
      }
    }
  },
  data: () => ({
    dateMenu: false,
    dateModel: undefined
  }),
  created () {
    this.dateModel = this.value
  },
  mounted() {
  }
}
</script>
<style>
/deep/
.month-picker .v-input__slot {
  min-height: 0 !important;
}
/deep/
.month-picker .v-input__append-inner {
  margin-top: 0 !important;
}
/deep/
.month-picker {
  border-bottom: 2px #893FF2 solid !important;
}
</style>
