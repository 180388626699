<template>
  <v-dialog
      v-model="downloadDialog"
      max-width="500"
  >
    <v-card>
      <v-overlay :value="loading" absolute>
        <v-progress-circular
            indeterminate
            size="64"
        ></v-progress-circular>
      </v-overlay>
      <v-card-title style="height: 64px; box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.22);">
        <span style="font-size: 16px">DOWNLOAD SHAPEFILE</span>
        <v-spacer/>
        <v-btn icon small @click="downloadDialog = false">
          <img alt="download" :src="require('@/assets/icon/close.svg')">
        </v-btn>
      </v-card-title>

      <v-card-text style="max-height: 70vh; overflow-y: auto" class="pa-0">
        <v-layout class="fill-height">
		<v-col>
       <v-btn @click="download('2021-10')" style="color: #893ff2; border: solid 1px #893ff2; border-radius: 5px; background-color: #e4d7ff;">2021-09 to 2021-10</v-btn>
		<br>
		<br>

	   <v-btn @click="download('2021-11' )" style="color: #893ff2; border: solid 1px #893ff2; border-radius: 5px; background-color: #e4d7ff;">2021-10 to 2021-11</v-btn>
	   </v-col>
        </v-layout>
      </v-card-text>

    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: "ShapefileDownload",
  data () {
    return {

      loading: false,
      downloadDialog: false,

    }
  },
  computed: {
  },
  methods: {
    openDialog (type) {

      this.downloadDialog = true
    },
download(ym) {

if (ym == '2021-10'){
window.open("http://119.75.24.70:8080/geoserver/wfs?service=wfs&version=1.0.0&request=getfeature&typename=timah:detect&PROPERTYNAME=aoi,before_del_mth,after_del_mth,area,change_type,qa_id,ullr&CQL_FILTER=after_del_mth='2021-10'&outputFormat=shape-zip&format_options=filename:2021-09_to_2021-10.zip")
}
else
{
window.open("http://119.75.24.70:8080/geoserver/wfs?service=wfs&version=1.0.0&request=getfeature&typename=timah:detect&PROPERTYNAME=aoi,before_del_mth,after_del_mth,area,change_type,qa_id,ullr&CQL_FILTER=after_del_mth='2021-11'&outputFormat=shape-zip&format_options=filename:2021-10_to_2021-11.zip")
}

}
  }
}
</script>

<style scoped>

</style>
