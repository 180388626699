<template>
  <v-menu
    :close-on-content-click="false"
    :offset-x="isOffsetX"
    :offset-y="isOffsetY"
    :open-on-hover="isOpenOnHover"
    :transition="transition"
    :value="openMenu"
  >
    <template v-slot:activator="{ on }">
      <v-btn v-if="icon" :color="color" v-on="on">
        <v-icon>{{ icon }}</v-icon>
      </v-btn>


      <v-list-item
        v-else-if="isSubMenu"
        class="d-flex justify-space-between"
        v-on="on"
      >
        {{ name }}
        <div class="flex-grow-1"></div>
        <v-icon>mdi-chevron-right</v-icon>
      </v-list-item>
      <v-btn v-else :color="color" v-on="on" @click="openMenu = true" text style="width: 100%; overflow:hidden; justify-content: start; padding:0 0; width: 210px; color:#8539F0;" class="justify-space-between text-truncate">{{
        name
      }}
        <slot></slot>
      </v-btn>
    </template>
    <v-list>
      <template v-for="(item, index) in menuItems">
        <v-divider v-if="item.isDivider" :key="index" />
        <subMenu
          v-else-if="item.menu"
          :key="index"
          :name="item.name"
          :menu-items="item.menu"
          @itemClick="emitClickEvent"
          :is-open-on-hover="false"
          :is-offset-x="true"
          :is-offset-y="false"
          :is-sub-menu="true"
        />
        <v-list-item v-else :key="index" @click="emitClickEvent(item)">
          <v-list-item-title>
            {{ item.name }}
          </v-list-item-title>
        </v-list-item>
      </template>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "subMenu",
  props: {
    name: String,
    icon: String,
    menuItems: Array,
    color: { type: String, default: "secondary" },
    isOffsetX: { type: Boolean, default: false },
    isOffsetY: { type: Boolean, default: true },
    isOpenOnHover: { type: Boolean, default: false },
    isSubMenu: { type: Boolean, default: false },
    transition: { type: String, default: "scale-transition" },
  },
  data: () => ({
    openMenu: false,
  }),
  methods: {
    emitClickEvent(item) {
      // this.closeAllMenus() // Theoretically, create a method that does this as a workaround
      console.log('Emit Clicked', item)
      this.$emit("itemClick", item);
      this.openMenu = false;
    },
  },
};
</script>
<style scoped>
.v-menu__content.theme--light.menuable__content__active{
  max-height: 600px;
  overflow-y: auto;
}
</style>