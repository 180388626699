import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { langs, texts } from './message'
import { vuetify } from "@/plugins/vuetify";
import axios from 'axios'

Vue.use(VueI18n)
let messages = {}

langs.forEach(e => messages[e.code] = {})
const langs_length = langs.length

for (let i = 0, texts_length = texts.length; i < texts_length; i++) {
  const texts_length = texts[i].length
  if (texts_length > langs_length) {
    for (let j = 1; j < texts_length; j++) {
      messages[langs[j - 1].code][texts[i][0]] = texts[i][j]
    }
  } else {
    for (let j = 0; j < texts_length; j++) {
      messages[langs[j].code][texts[i][0]] = texts[i][j]
    }
  }
}
localStorage.setItem('locale', 'en')
const currentLocale = localStorage.getItem('locale') || 'en'

const dateTimeFormats = {
  'en': {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      weekday: 'long',
    }
  },
  'vi': {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      weekday: 'long',
    }
  }
}

export const vueI18n = new VueI18n({
  locale: currentLocale,
  fallbackLocale: 'en',
  silentFallbackWarn: true,
  messages,
  dateTimeFormats
})

export function setLocale(val) {
  vueI18n.locale = val
  localStorage.setItem('locale', val)
  axios.defaults.headers.common['Accept-Language'] = val
  vuetify.framework.lang.current = val
}

if (currentLocale) {
  setLocale(currentLocale)
}
