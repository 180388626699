import PlanetApi from '@/api/PlanetApi'
import dayjs from 'dayjs'
import EventBus from '@/event-bus'

const state = {

  sunElevation: [0, 90],

  loading: false,
  images: {},
  imageGroups: [],
  groups : [],
  currentGroup: undefined,
}

const getters = {

}

const actions = {
 

  async getImages({ commit, state} , options) {
    // Should remember existing selection and only remove those not in the new selection
 
    try {
      commit('SET_VALUES', {
        loading: true,
      })


      const res = await PlanetApi.quickSearch(
        [options.aoi.geometry],
        dayjs(options.date.from)
          .startOf('day')
          .format(),
        dayjs(options.date.to)
          .endOf('day')
          .format(),
        options.cloud,
        state.sunElevation
      )
      const groups  = groupImages(res)
	  
	  
	  
	  console.log(groups)

      commit('SET_VALUES', {
       
        groups: groups
      })
    } finally {
      commit('SET_VALUES', {
        loading: false,
      })
    }
  },

  toggleExpandGroup({ commit }, date) {
    commit('TOGGLE_EXPAND_DATE', {
      date,
      expand: !date.expand,
    })
  },

  toggleExpandStrip({ commit }, strip) {
    commit('TOGGLE_EXPAND_STRIP', {
      strip,
      expand: !strip.expand,
    })
  },
}


const mutations = {
	  SET_VALUES(state, data) {
    for (const key in data) {
      state[key] = data[key]
    }
			
  },
}


export function imageconvert(i) {
	
var bbox = []

var x1 = 999999999
var x2 = -999999999
var y1 = 999999999
var y2 = -999999999
var c = i.geometry.coordinates[0]

for (var a = 0; a <5; a++ ) {
	
	if (c[a][0] < x1)
		x1 = c[a][0]
	if (c[a][0] > x2)
		x2 = c[a][0]	
	if (c[a][1] < y1)
		y1 = c[a][1]
	if (c[a][1] > y2)
		y2 = c[a][1]		
}
	
return  {
                "id": i.id,
                "name": i.id,
				"properties":i.properties,
                "tile_url": i.properties.tile_url,
				"thumb" : i._links.thumbnail + '?api_key=3838428973684e54935f18d5080581e7',
                "min_zoom": 9,
                "max_zoom": 18,
                "list_values": [],
				"geometry" : i.geometry,
                "bbox": [
                    x1,
                    y1,
                    x2,
                    y2
                ],
                "type": "Planet Image",
                "month": i.id.substr(4,2),
                "year": Number(i.id.substr(0,4)),
                "data_type": "raster_image",
                "selected": true,
                "layoutRight": true,
                "opacity": 1,
                "layoutLeft": true,
                "displayLabel": true,
                "map_type": "raster_image",
                "color": "#536DFE"
            }

}

export function groupsconvert(g) {

var groups = []	
	
for (const [key, e] of Object.entries(g)) {
	
	var images = []
	
	e.images.forEach((i) => images.push(imageconvert(i)))
	
  groups.push({name:key , type:'Planet Image', images : images})
}

return groups
}

export function groupImages(features) {
 
  let groups = {}

var a = 0
  features.forEach((image) => {
	  
	image.properties.tile_url = image.properties.tile_url.replace('{s}',String(a))

	a = a + 1
	
	if (a > 3)
		a = 0
	
    image.visible = false
   // images[image.id] = image

    const imageDate = dayjs(image.properties.acquired).format('DD-MM-YYYY')
  
    // Group by date
    if (!groups[imageDate]) {
      groups[imageDate] = {
		time: image.properties.acquired,
        images: [image]
      }
    }
	else {
      groups[imageDate].images.push(image)
    }

 
  })

  groups = sortObject(groups)
  return groupsconvert(groups)
}

export function sortObject(unordered) {
  let ordered = {}

  Object.keys(unordered)
    .sort((a, b) => {
		var ymd1 = a.substr(6,4) + a.substr(3,2) + a.substr(0,2)
		var ymd2 = b.substr(6,4) + b.substr(3,2) + b.substr(0,2)
		
		return ymd1 > ymd2 ? 1 : -1
	
	})
    .forEach((key) => {
      // unordered[key].strips = sortObjectByTime(unordered[key], 'time')
      ordered[key] = unordered[key]
    })
  return ordered
}

// export function sortObjectByValue(unordered, order = 'ascending') {
//   var sortable = []
//   for (var key in unordered) {
//     sortable.push([key, unordered[key]])
//   }
//   if (order === 'ascending') {
//     sortable.sort(function(a, b) {
//       return a[1] - b[1]
//     })
//   } else {
//     sortable.sort(function(a, b) {
//       return b[1] - a[1]
//     })
//   }

//   var ordered = {}
//   sortable.forEach(function(item) {
//     ordered[item[0]] = item[1]
//   })

//   return ordered
// }

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
