<template>
  <div style="position: relative; height: 100%; width:100%; overflow: hidden">
    <div id="plant-health"/>
    <div class="legend">
      <div style="height: 100%; padding-top: 20px; display: flex; flex-direction: column">
        <div style="width: 100%; height: 80px; flex:  none; padding-left: 20px; font-weight: 600; text-transform: uppercase; color: #893FF2">
          <div style="height: 100%; padding-top: 10px; display: flex; flex-direction: column">
            <div style="flex: none;">
              Plant health
            </div>
            <div style="flex: none;">
              stressed
            </div>
            <div style="height: 100%; display: flex; align-content: end; font-size: 10px; padding-top: 20px">
              Legend
            </div>
          </div>
        </div>
        <div style="display: flex; height: 100%; font-size: 11px; padding-left: 20px; padding-top: 20px">
          <div style="display: flex; flex-direction: column; ">
            <div style="display: flex; flex: 1">
              <div style="width: 15px; height: 15px; background-color: white; border: 1px solid #777c85; margin-right: 10px"></div>
              Less Thanh or Equal to 0
              <v-spacer/>
              <div style="color: grey; margin-left: 20px">No Color</div>
            </div>
            <div style="display: flex; flex: 1">
              <div style="width: 15px; height: 15px; background-color: #FF9800; margin-right: 10px"></div>
              0 to 0.2 Stressed
              <v-spacer/>
              <div style="color: #FF9800; margin-left: 20px">Orange</div>
            </div>
            <div style="display: flex; flex: 1">
              <div style="width: 15px; height: 15px; background-color: #FFEB3B; margin-right: 10px"></div>
              0.2 to 0.4 Slightly Stressed
              <v-spacer/>
              <div style="color: #FFEB3B; margin-left: 20px">Yellow</div>
            </div>
            <div style="display: flex; flex: 1">
              <div style="width: 15px; height: 15px; background-color: #8BC34A; margin-right: 10px"></div>
              Healthy
              <v-spacer/>
              <div style="color: #8BC34A; margin-left: 20px">Light Green</div>
            </div>
            <div style="display: flex; flex: 1">
              <div style="width: 15px; height: 15px; background-color: #4CAF50; margin-right: 10px"></div>
              Very Healthy
              <v-spacer/>
              <div style="color: #4CAF50; margin-left: 20px">Dark Green</div>
            </div>
          </div>
        </div>
        <div style="flex: none; height: 20px; width: 100%"></div>
      </div>
    </div>
  </div>
</template>

<script>
import MapboxDraw from '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw'
import DrawRectangleAssisted from '@geostarters/mapbox-gl-draw-rectangle-assisted-mode'
import DrawRectangle from 'mapbox-gl-draw-rectangle-mode'
import {CircleMode, DirectMode, DragCircleMode, SimpleSelectMode,} from '@/ultis/draw'
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import DrawTool from "@/components/home/draw/DrawTool";
import {RulerControl, CompassControl, ZoomControl} from 'mapbox-gl-controls'
import bbox from '@turf/bbox'
import LayerControl from "@/components/home/dataSource/LayerControl";
import numberFormat from "@/ultis/comma";
import {inspectImageData} from "@/backend";
import randomColor from "@/ultis/randomColor";
let map
let draw
export default {
  components: {LayerControl, DrawTool},
  name: "BaseMap",
  data() {
    return {
      center: {lng: 0, lat: 0},
      isBaseMap: true,
      zoom: 0,
      layers: [],
      listImage: [],
      addedLayer: undefined
    }
  },
  props: {
    dataSource: {
    }
  },
  mounted() {
    window.mapboxgl.accessToken = 'pk.eyJ1IjoiaG9hdGllbnR1IiwiYSI6ImNrYXMwNmt4ZzA4YTIyeXAzcjZicmhsNXMifQ.9hvfCuoiO1-1cFmikE14LA'
    map = new window.mapboxgl.Map({
      container: 'plant-health', // container id
      style: 'https://tiles.eofactory.ai/styles/basic/style.json',
      center: [103.816945, 1.355359], // starting position
      zoom: 9.5, // starting zoom,
      attributionControl: false,
      preserveDrawingBuffer: true
    })

    draw = new MapboxDraw({
      keybindings: true,
      displayControlsDefault: false,
      userProperties: true,
      controls: {
        line_string: false,
        polygon: false,
        trash: false
      },
      modes: {
        ...MapboxDraw.modes,
        draw_assisted_rectangle: DrawRectangleAssisted,
        draw_rectangle: DrawRectangle,
        draw_circle: CircleMode,
        drag_circle: DragCircleMode,
        direct_select: DirectMode,
        simple_select: SimpleSelectMode
      }
    })
    map.on('style.load', () => {
      map.resize()
      this.center = map.getCenter()
      this.zoom = map.getZoom().toFixed(2)
      this.addSatellite()
      if (this.dataSource){
        switch (this.dataSource) {
          case 'sentinel': this.submitZoom([103.60570070513, 1.1586987006352, 104.08848306516, 1.4707748320846])
            break
          case 'planet':
          case 'jilin': this.submitZoom([103.91033, 1.36177, 104.09282, 1.4416])
            break
        }
      }
      this.$emit('loadNextMap')
    })
    map.on('draw.create', this.changeAOI)
    map.on('mousemove', (e) => {
      this.center = e.lngLat.wrap()
    })
    map.on('zoom', _ => {
      this.zoom = map.getZoom().toFixed(2)
    })
  },
  watch: {
    dataSource (dataSource) {
      if (dataSource){
        switch (this.dataSource) {
          case 'sentinel': this.submitZoom([103.60570070513, 1.1586987006352, 104.08848306516, 1.4707748320846])
            break
          case 'planet':
          case 'jilin': this.submitZoom([103.91033, 1.36177, 104.09282, 1.4416])
            break
        }
      }
    }
  },
  destroyed() {
    if (map) {
      map.remove()
      draw = undefined
      map = undefined
    }

  },
  methods: {
    getImage () {
      const mapCanvas = map.getCanvas()
      const baseCanvas = document.createElement('canvas')
      baseCanvas.width = mapCanvas.width
      baseCanvas.height = mapCanvas.height
      // const mapImage = new window.Image()
      // mapImage.src = mapCanvas.toDataURL()
      // console.log(mapCanvas.toDataURL())
      return mapCanvas.toDataURL()
    },
    visibleLayer (currentLayer) {
      let layer = this.layers.find(layer => layer.display)
      if (layer) {
        layer.display = !layer.display
        map.removeLayer(layer.id)
      }
      currentLayer.display = !currentLayer.display
      this.addToMap(currentLayer)
    },
    addSingaporeBoundary (geometry) {
      let check = false
      if (map.getStyle().layers.some(val => val.id === 'singapore-boundary')) {
        check = true
        map.removeLayer('singapore-boundary')
      }
      if (map.getStyle().layers.some(val => val.id === 'singapore-boundary-highlighted')) {
        check = true
        map.removeLayer('singapore-boundary-highlighted')
      }
      if (check) map.removeSource('singapore-boundary')
      map.addSource('singapore-boundary', {
        'type': 'geojson',
        'data': geometry
      })
      map.addLayer({
        'id': 'singapore-boundary',
        'type': 'line',
        'source': 'singapore-boundary',
        'layout': {},
        'paint': {
          'line-color': '#9003fc',
          'line-width': 1
        }
      })
      map.addLayer({
        'id': 'singapore-boundary-highlighted',
        'type': 'line',
        'source': 'singapore-boundary',
        'layout': {},
        'paint': {
          'line-color': '#0335fc',
          'line-width': 4
        },
        'filter': ['in', 'name', '']
      })
    },
    addSatellite() {
      map.addLayer({
        "id": "satellite_bing",
        "type": "raster",
        "source": {
          "type": "raster",
          "tiles": [
            "https://ecn.t0.tiles.virtualearth.net/tiles/a{quadkey}.jpeg?g=7505",
            "https://ecn.t1.tiles.virtualearth.net/tiles/a{quadkey}.jpeg?g=7505",
            "https://ecn.t2.tiles.virtualearth.net/tiles/a{quadkey}.jpeg?g=7505",
            "https://ecn.t3.tiles.virtualearth.net/tiles/a{quadkey}.jpeg?g=7505"
          ],
          "tileSize": 256,
          "maxzoom": 18,
          "minzoom": 1,
          "bounds": [
            -180,
            -85.051129,
            180,
            85.051129
          ]
        },
        "layout": {
          "visibility": "none"
        },
        "paint": {
          "raster-opacity": 1,
          "raster-contrast": 0,
          "raster-brightness-min": 0,
          "raster-brightness-max": 1
        }
      })
    },
    removeLayer () {
      this.layers.forEach(layer => {
        if(layer.display) map.removeLayer(layer.id).removeSource(layer.id)
        else map.removeSource(layer.id)
      })
      this.layers = []
    },
    createImageLayer (image, visible) {
      map.addSource(image.id, {
        'type': 'raster',
        'tiles': [image.tile_url],
        'tileSize': 256,
        'maxzoom': image.max_zoom || 18,
        'minzoom': image.min_zoom || 1,
        'bounds': image.bbox
      })
      const layer = {
        id: image.id,
        'color': randomColor.getColor(),
        'type': 'raster',
        'source': image.id,
        'name': image.name,
        'display': visible,
        'bounds': image.bbox,
        'layout': {
        },
        'paint': {
          'raster-fade-duration': 0
        }
      }
      this.layers.unshift(layer)
      if (visible) this.addToMap(layer)
    },
    addToMap (layer) {
      map.addLayer(layer, 'singapore-boundary')
      this.submitZoom(layer.bounds)
    },
    zoomToBound(currentImage) {
      // this.submitZoom(bbox(currentImage.geometry))
    },
    submitZoom(bbox) {
      map.fitBounds(bbox, {
        'duration': 0,
        'padding': 20
      })
    },
    highlight (label) {
      map.setFilter('singapore-boundary-highlighted', ['in', 'name', label])
    }
  },
}
</script>

<style scoped>
.map-info {
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  border-bottom-left-radius: 25px;
  z-index: 2;
  background-color: #fcfaf9;
  position: absolute;
  bottom: 10px;
  right: 90px;
  width: 220px;
  height: 30px;
}

#plant-health {
  width: 100%;
  height: 100%;
}

/deep/
.mapbox-compass {
  border-radius: 50%;
}

/deep/
.mapbox-compass button {
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

/deep/
.mapbox-compass svg {
  margin-right: 2px;
  margin-top: 4px;
  width: 25px;
  height: 25px;
}

/deep/
.mapbox-zoom {
  border-radius: 30px;
  box-shadow: none;
}

/deep/
.mapbox-zoom button {
  background-color: white;
  width: 30px;
  height: 30px;
}

/deep/
.mapbox-zoom button:nth-child(1) {
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
}

/deep/
.mapbox-zoom button:nth-child(2) {
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
}
/deep/
.mapboxgl-ctrl-scale {
  font-size: 12px;
  border: 1px solid lightgrey !important;
  text-align: center;
  padding-top: 5px;
  height: 30px;
  width: 75px !important;
  background-color: white;
  border-radius: 30px;

}
/deep/
.mapboxgl-popup-close-button {
  font-size: 20px !important;
  height: 30px !important;
  width: 30px !important;
}
.legend {
  width: 250px;
  height: 260px;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  background: linear-gradient(90deg, #FFFFFF 4.71%, rgba(255, 255, 255, 0.85) 115.19%);
  position: absolute;
  top: 20%;
  right: 0;
}
</style>
