<template>
  <div style="position: relative; height: 100%; width:100%; overflow: hidden">
    <div id="mapSearch" @mouseleave="deSelect"/>
    <v-btn style="position: absolute; top: 10px; right: 10px" color="white" x-small fab @click="handleScreenshot"><v-icon>mdi-camera</v-icon></v-btn>
    <LayerControl ref="layerControl" @toggleLabel="toggleLabel" @visibleLayer="visibleLayer" :layers.sync="layers" style="position: absolute; bottom: 90px; left: -70px"></LayerControl>
    <v-card class="map-info">
      <v-layout class="fill-height" align-center justify-center>
        <v-icon x-small class="mr-2">mdi-crosshairs-gps</v-icon>
        <div style="font-size: 12px">{{ center.lng.toFixed(4) + ' ' + center.lat.toFixed(4) }}</div>
        <v-icon class="mx-2" x-small>mdi-magnify</v-icon>
        <div style="font-size: 12px">{{ zoom }}</div>
      </v-layout>
    </v-card>
  </div>
</template>

<script>
import MapboxDraw from '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw'
import DrawRectangleAssisted from '@geostarters/mapbox-gl-draw-rectangle-assisted-mode'
import DrawRectangle from 'mapbox-gl-draw-rectangle-mode'
import {CircleMode, DirectMode, DragCircleMode, SimpleSelectMode,} from '@/ultis/draw'
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import DrawTool from "@/components/home/draw/DrawTool";
import {RulerControl, CompassControl, ZoomControl} from 'mapbox-gl-controls'
import bbox from '@turf/bbox'
import LayerControl from "@/components/home/dataSource/LayerControl"
import numberFormat from "@/ultis/comma"
import {getGeom, inspectImageData} from "@/backend"
import randomColor from "@/ultis/randomColor"
import Store from '@/store'
import aois_geojson from '@/assets/aoisx.json'

let map
let draw
let popup
export default {
  components: {LayerControl, DrawTool},
  name: "BaseMap",
  data() {
    return {
	  aois : undefined,
      center: {lng: 0, lat: 0},
      isBaseMap: true,
      zoom: 0,
      layers: [],
      listImage: [],
      addedLayer: undefined
    }
  },
  props: {
    dataSource: {
    }
  },
  mounted() {
    window.mapboxgl.accessToken = 'pk.eyJ1IjoiaG9hdGllbnR1IiwiYSI6ImNrYXMwNmt4ZzA4YTIyeXAzcjZicmhsNXMifQ.9hvfCuoiO1-1cFmikE14LA'
    map = new window.mapboxgl.Map({
      container: 'mapSearch', // container id
      style: require('@/assets/MapStyle/mapstyle.json'),
      center: [113.4171, -0.5533], // starting position
      zoom: 4.02, // starting zoom,
      attributionControl: false,
      preserveDrawingBuffer: true
    })

    draw = new MapboxDraw({
      keybindings: true,
      displayControlsDefault: false,
      userProperties: true,
      controls: {
        line_string: false,
        polygon: false,
        trash: false
      },
      modes: {
        ...MapboxDraw.modes,
        draw_assisted_rectangle: DrawRectangleAssisted,
        draw_rectangle: DrawRectangle,
        draw_circle: CircleMode,
        drag_circle: DragCircleMode,
        direct_select: DirectMode,
        simple_select: SimpleSelectMode
      }
    })
    map.addControl(new window.mapboxgl.ScaleControl({
      maxWidth: 80,
      unit: 'metric'
    }), 'bottom-right')
    map.addControl(new ZoomControl(), 'bottom-right')
    map.addControl(new CompassControl(), 'bottom-right')
    // map.addControl(new RulerControl(), 'bottom-right')
    map.addControl(draw)
    map.on('style.load', () => {
      map.resize()
      this.center = map.getCenter()
      this.zoom = map.getZoom().toFixed(2)
      this.addLabelLayer()
      if (this.dataSource){
        switch (this.dataSource) {
          case 'sentinel': this.submitZoom([103.60570070513, 1.1586987006352, 104.08848306516, 1.4707748320846])
            break
          case 'planet':
          case 'jilin': this.submitZoom([103.91033, 1.36177, 104.09282, 1.4416])
            break
        }
      }
      this.$emit('getData')
    })
    map.on('draw.create', this.changeAOI)
    map.on('mousemove', (e) => {
      this.center = e.lngLat.wrap()
    })
    map.on('zoom', _ => {
      this.zoom = map.getZoom().toFixed(2)
    })
    map.on('click', this.onMapClick)
    
setTimeout(this.load_aois , 2000)
  },
  watch: {
    dataSource (dataSource) {
      if (dataSource){
        switch (this.dataSource) {
          case 'sentinel': this.submitZoom([103.60570070513, 1.1586987006352, 104.08848306516, 1.4707748320846])
            break
          case 'planet':
          case 'jilin': this.submitZoom([103.91033, 1.36177, 104.09282, 1.4416])
            break
        }
      }
    }
  },
  destroyed() {
    if (map) {
      map.remove()
      draw = undefined
      map = undefined
    }
    if(popup) {
      popup.remove()
      popup = undefined
    }
  },
  methods: {
  load_aois() {
  this.addAOI(aois_geojson)
  },  
    async handleScreenshot () {
      const mapCanvas = map.getCanvas()
      const baseCanvas = document.createElement('canvas')
      baseCanvas.width = mapCanvas.width
      baseCanvas.height = mapCanvas.height
      const context = baseCanvas.getContext('2d')

      const mapImage = new window.Image()
      mapImage.src = mapCanvas.toDataURL()
      mapImage.onload = () => {
        context.drawImage(mapImage, 0, 0)
        baseCanvas.toBlob((result) => {
          const blobUrl = URL.createObjectURL(result)
          const link = document.createElement('a')
          link.href = blobUrl
          link.setAttribute('download', `${Date.now().toString()}`)
          link.click()
          this.sheet = false
        })
      }
    },
    async onMapClick (e) {
      try {
        if (this.layers.find(layer => layer.display).type === 'fill') return
        const res = await inspectImageData({
          imageId: this.layers.find(layer => layer.display).id,
          coordinates: {
            longitude: e.lngLat.lng,
            latitude: e.lngLat.lat
          }
        })
        popup = new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: true,
          closeOnMove: false,
          maxWidth: "auto"
        })
          .setLngLat([e.lngLat.lng, e.lngLat.lat])
          .setHTML('<div style="width: 250px; height: 110px; border-radius: 15px"> <div style="width: 100%; height: 20px">' +
            ' <span style="font-size: 14px; font-weight: bold; color: #893FF2; text-transform: uppercase">Point</span> ' +
            '</div>' +
            ' <div style="padding: 10px; width: 100%; height: 60px; border: 1px solid #a86ef3; text-transform: capitalize">' +
            Object.keys(res.data.data)[0] + ': ' + res.data.data[Object.keys(res.data.data)[0]] +
            '<br>' +
            'Latitude/Longtitude: ' + numberFormat.numberWithCommas(e.lngLat.lat.toFixed(2)) +
            '/' + numberFormat.numberWithCommas(e.lngLat.lng.toFixed(2)) +
            '</div>' +
            ' <div style="width: 100%; text-align: end; padding-top: 3px; display: flex">' +
            ' <button class="btn" style="height: 40px; flex: 1; background-color: #fff;"><span style="text-transform: uppercase; color: #8c47f5; font-weight: bold">Clear</span></button>' +
            ' <button class="btn" style="height: 40px; flex: 1; background-color: rgba(137, 63, 242, 0.2); border: 1px #cba8f8 solid; border-top-left-radius: 10px"><span style="text-transform: uppercase; color: #8c47f5; font-weight: bold">Go to map view</span></button>' +
            ' </div>' +
            ' </div>')
            .addTo(map)
        const btnClear = document.getElementsByClassName("btn")[0];
        btnClear.addEventListener("click", () => {
          popup.remove()
        })
        const btn = document.getElementsByClassName("btn")[1];
        btn.addEventListener("click", () => {
          const currentImage = this.$refs.layerControl.getCurrentImage()
          Store.commit('point/SET_POINT', {
            data: res.data.data,
            coordinate: e.lngLat,
            image: currentImage
          })
          this.$emit('gotoMapView')
        })
      } catch (e) {
        console.log(e)
      }
    },
    visibleLayer (currentLayer) {
      let layer = this.layers.find(layer => layer.display)
      if (layer) {
        layer.display = !layer.display
        map.removeLayer(layer.id)
      }
      currentLayer.display = !currentLayer.display
      this.addToMap(currentLayer)
    },
addAOI (geometry) {
      let check = false

      if (check) map.removeSource('aois-boundary')
      map.addSource('aois-boundary', {
        'type': 'geojson',
        'data': geometry
      })
      map.addLayer({
        'id': 'aois-boundary',
        'type': 'line',
        'source': 'aois-boundary',
        'layout': {},
        'paint': {
          'line-color': '#9003fc',
          'line-width': 1
        }
      })
    
    },
    addSingaporeBoundary (geometry) {
	
	
	//this.addAOI(aois_geojson)
	
      let check = false
      if (map.getStyle().layers.some(val => val.id === 'singapore-boundary')) {
        check = true
        map.removeLayer('singapore-boundary')
      }
      if (map.getStyle().layers.some(val => val.id === 'singapore-boundary-highlighted')) {
        check = true
        map.removeLayer('singapore-boundary-highlighted')
      }
      if (check) map.removeSource('singapore-boundary')
      map.addSource('singapore-boundary', {
        'type': 'geojson',
        'data': geometry
      })
      map.addLayer({
        'id': 'singapore-boundary',
        'type': 'line',
        'source': 'singapore-boundary',
        'layout': {},
        'paint': {
          'line-color': '#9003fc',
          'line-width': 1
        }
      })
      map.addLayer({
        'id': 'singapore-boundary-highlighted',
        'type': 'line',
        'source': 'singapore-boundary',
        'layout': {},
        'paint': {
          'line-color': '#0335fc',
          'line-width': 4
        },
        'filter': ['in', 'name', '']
      }, 'label')
    },
    deSelect() {
      draw.changeMode('simple_select')
    },
    toggleLabel(status) {
      let visibility = 'visible'
      if (!status) visibility = 'none'
      map.setLayoutProperty('label', 'visibility', visibility)
    },
    addLabelLayer() {
      map.addLayer(require('@/assets/MapStyle/label.json'))
    },
    removeLayer () {
      this.layers.forEach(layer => {
        if(layer.display) map.removeLayer(layer.id).removeSource(layer.id)
        else map.removeSource(layer.id)
      })
      this.layers = []
    },
    async createVectorLayer (vector, visible) {
      try {
        if (!vector.id) return
        const res = await getGeom(vector.id)
        const layer = {
          'name': vector.name,
          'color': '#d32f2f',
          'id': vector.id,
          'type': 'fill',
          'bbox': bbox(res.data.data),
          'bounds': bbox(res.data.data),
          'source': vector.id,
          'display': visible,
          'layout': {
          },
          'paint': {
            'fill-color': 'red',
            'fill-opacity': 1
          }
        }
        const source = {
          'type': 'geojson',
          'data': res.data.data
        }
        map.addSource(layer.id, source)
        this.layers.unshift(layer)
        if (visible) this.addToMap(layer)
      } catch (e) {
        console.log(e)
      }
    },
    createImageLayer (image, visible) {
      map.addSource(image.id, {
        'type': 'raster',
        'tiles': [image.list_hidden_value ? image.tile_url + '&list_hidden_value=' + image.list_hidden_value : image.tile_url],
        'tileSize': 256,
        'maxzoom': image.max_zoom || 18,
        'minzoom': image.min_zoom || 1,
        'bounds': image.bbox
      })
      const layer = {
        id: image.id,
        'tile_url': image.tile_url,
        'bbox': image.bbox,
        'color': randomColor.getColor(),
        'type': 'raster',
        'source': image.id,
        'name': image.name,
        'display': visible,
        'bounds': image.bbox,
        'layout': {
        },
        'paint': {
          'raster-fade-duration': 0
        }
      }
      if (image.list_values) image.list_values.forEach(value => {
        value['display'] = true
      })
      layer.list_values = image.list_values
      this.layers.unshift(layer)
      if (visible) this.addToMap(layer)
    },
    addToMap (layer) {
      map.addLayer(layer, 'label')
      this.submitZoom(layer.bounds)
    },
    zoomToBound(currentImage) {
      // this.submitZoom(bbox(currentImage.geometry))
    },
    submitZoom(bbox) {
      map.fitBounds(bbox, {
        'duration': 0,
        'padding': 20
      })
    },
    highlight (label) {
      map.setFilter('singapore-boundary-highlighted', ['in', 'name', label])
    }
  },
}
</script>

<style scoped>
.map-info {
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  border-bottom-left-radius: 25px;
  z-index: 2;
  background-color: #fcfaf9;
  position: absolute;
  bottom: 10px;
  right: 90px;
  width: 220px;
  height: 30px;
}

#mapSearch {
  width: 100%;
  height: 100%;
}

/deep/
.mapbox-compass {
  border-radius: 50%;
}

/deep/
.mapbox-compass button {
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

/deep/
.mapbox-compass svg {
  margin-right: 2px;
  margin-top: 4px;
  width: 25px;
  height: 25px;
}

/deep/
.mapbox-zoom {
  border-radius: 30px;
  box-shadow: none;
}

/deep/
.mapbox-zoom button {
  background-color: white;
  width: 30px;
  height: 30px;
}

/deep/
.mapbox-zoom button:nth-child(1) {
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
}

/deep/
.mapbox-zoom button:nth-child(2) {
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
}
/deep/
.mapboxgl-ctrl-scale {
  font-size: 12px;
  border: 1px solid lightgrey !important;
  text-align: center;
  padding-top: 5px;
  height: 30px;
  width: 75px !important;
  background-color: white;
  border-radius: 30px;

}
/deep/
.mapboxgl-popup-close-button {
  font-size: 20px !important;
  height: 30px !important;
  width: 30px !important;
}
</style>
