<template>
  <v-menu :close-on-content-click="false" :nudge-width="240" offset-y>
    <!-- Avatar -->
    <template v-slot:activator="{ attrs, on }">
      <Avatar
          :image="currentUser && currentUser.avatar ? currentUser.avatar : require('@/assets/images/default-avatar.jpg')"
          :name="currentUser && currentUser.email || 'Guest'"
          class="touchable-highlight"
          :class="customClasses"
          v-bind="attrs"
          v-on="on"
      />
    </template>

    <!-- Info -->
    <v-card style="min-width: 12.5em">
      <template v-if="currentUser">
        <v-card-text style="text-align: center">
          <Avatar :image="currentUser && currentUser.avatar ? currentUser.avatar : require('@/assets/images/default-avatar.jpg')"
                  :name="currentUser.email"/>
          <div>
            {{ currentUser.email }}
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer/>

          <LogoutButton text small/>
        </v-card-actions>
      </template>

      <template v-else>
        <v-card-text style="text-align: center">
          <Avatar :image="require('@/assets/images/default-avatar.jpg')" name="Guest"/>

          <div class="my-4">
            Guest
          </div>

          <LoginButton small color="primary"/>
        </v-card-text>
      </template>
    </v-card>
  </v-menu>
</template>

<script>
import LoginButton from "./LoginButton.vue";
import LogoutButton from "./LogoutButton.vue";
import Avatar from "./Avatar.vue";
import {mapState} from '@/store/ults'

export default {
  components: {
    LoginButton,
    LogoutButton,
    Avatar
  },

  props: {
    customClasses: {
      type: String,
      default: undefined
    }
  },

  computed: {
    ...mapState('auth', ['currentUser']),
  }
};
</script>

<style scoped>
.touchable-highlight {
  cursor: pointer;
  transition: filter 0.3s, color 0.3s;
}

.touchable-highlight:hover,
.touchable-highlight:focus {
  filter: brightness(1.2);
}

.touchable-highlight:active {
  filter: brightness(0.8);
}

.touchable-highlight--highlight {
  color: var(--primary);
  font-weight: bold;
}
</style>
