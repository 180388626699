<template>
  <v-layout>
    <!-- <v-btn rounded title="layers" width="40" height="40" min-width="0" v-if="!isShow" @click="isShow = true" color="#BF99F5FF" dark><v-icon>mdi-layers</v-icon></v-btn> -->

    <v-btn
        :outlined="!showLayerControl"
        @click="showLayerControl = !showLayerControl"
        :style="{'background-color': showLayerControl ? '#893FF2' : '#e5d5fc', 'display': showLayerControl ? 'none' : 'block'  }"
        style="position: absolute; bottom: 41; left: -81; transform: rotate(270deg); border-radius: 12px;" 
        :color="showLayerControl ? '#fff' : '#893FF2'"
        >
      
      <span>
        OPEN LAYER TAB
       
      </span>
    </v-btn>


    <v-layout style="width: 250px; height: 240px; position:absolute; left: 80px; bottom: -70px;" v-if="showLayerControl">
      <v-card width="100%">
        <v-card-title class="py-0" style="height: 30px">
          <v-layout align-center style="height: 30px">
            <span style="font-size: 14px; color: #893FF2">Layers</span>
            <v-spacer/>
            <v-btn x-small @click="showLayerControl = false" icon style="border: 1px solid #893FF2"><v-icon>mdi-menu-left</v-icon></v-btn>
          </v-layout>
        </v-card-title>
        <v-divider/>
        <v-card-text style="height: calc(100% - 60px)" class="py-1 overflow-y-auto">
          <v-radio-group v-model="radioGroup" hide-details class="my-0">
            <v-list dense>
              <div class="px-2" :class="{'mb-2': index !== layers.length -1}" style="border-radius: 30px; border: 1px solid #893FF2" height="30" v-for="(layer, index) of layers" :key="index">
                <v-list-item class="px-0" style="height: 30px; min-height: 0">
                  <v-list-item-title style="font-size: 12px;" :style="{'color': layer.color}">
                    <v-icon class="mr-2" size="21">mdi-drag</v-icon>{{layer.name}}
                  </v-list-item-title>
                  <v-list-item-action>
                    <v-layout>
                      <v-radio
                          @click="changeView(layer)"
                          class="small-radio"
                          :value="layer"
                      ></v-radio>
                      <!--                    <v-btn icon x-small @click="() => $emit('visibleLayer', layer)"><v-icon v-if="layer.display" >mdi-eye</v-icon><v-icon v-else>mdi-eye-off</v-icon></v-btn>-->
                    </v-layout>
                  </v-list-item-action>
                </v-list-item>
              </div>
            </v-list>
          </v-radio-group>
        </v-card-text>
        <v-divider/>
        <v-card-actions style="height: 30px" class="pl-4">
          <v-switch
              dense
              color="#893FF2"
              class="switch-label"
              @change="() => $emit('toggleLabel', isDisplayLabel)"
              v-model="isDisplayLabel"
              label="Street layer"
          ></v-switch>
        </v-card-actions>
      </v-card>
    </v-layout>
  </v-layout>
</template>

<script>
export default {
  name: "LayerControl",
  data() {
    return {
      showLayerControl: false,
      isDisplayLabel: true,
      radioGroup: undefined
    }
  },
  props: {
    layers: {
    }
  },
  watch: {
    layers (val) {
      this.radioGroup = val.find(val => val.display)
    }
  },
  methods: {
    changeView (layer) {
      this.$emit('visibleLayer', layer)
    },
    getCurrentImage () {
      return this.radioGroup
    }
  }
}
</script>

<style scoped>
/deep/
.small-radio i {
  font-size: 14px;
}
/deep/
.small-radio .v-input--selection-controls__input:hover .v-input--selection-controls__ripple:before {
  background: currentColor;
  transform: scale(0.7);
  transition: none;
}
/deep/
.switch-label label {
  color: #893FF2;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  font-size: 14px;
}
</style>
