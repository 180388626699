
export default {
  namespaced: true,
  state: {
    menu: [
      { icon: 'mdi-map-search-outline', text: 'Search image', link: '/app' },
      { icon: 'mdi-calendar-text-outline', text: 'Order', link: '/app/orders' }

    ],
  },

  mutations: {
  }
}
