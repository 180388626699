<template>
  <v-dialog
      v-model="downloadDialog"
      max-width="500"
  >
    <v-card>
      <v-overlay :value="loading" absolute>
        <v-progress-circular
            indeterminate
            size="64"
        ></v-progress-circular>
      </v-overlay>
      <v-card-title style="height: 64px; box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.22);">
        <span style="font-size: 16px">IMAGERY</span>
        <v-spacer/>
        <v-btn icon small @click="downloadDialog = false">
          <img alt="download" :src="require('@/assets/icon/close.svg')">
        </v-btn>
      </v-card-title>
      <v-row class="mx-1 mb-2 my-3" style="border-radius: 11px; border: solid 1px #893FF2; height:40px;">
                <v-col cols="5" class="pa-2 pl-1">
                  <dualMonth :disableTime="false" 
                  @changedDate='newDate'>
                  </dualMonth>
                </v-col>
                <v-col cols="7" class="pa-2 searchBoxPosition DownloadBox">
                  <searchBox @aoiSelected="newAOI" style="width: 270px;"></searchBox>
                </v-col>
        </v-row>

      <v-card-text style="max-height: 70vh; overflow-y: auto" class="pa-0">
        <v-layout class="fill-height">
          <v-data-table
              
              style="height: 100%; width: 100%;"
              height="100%"
              v-model="imageSelected"
              :headers="headers"
              :items="availableImage"
              item-key="id"
              show-select
              class="elevation-1"
          >
          </v-data-table>
        </v-layout>
      </v-card-text>
      <v-divider/>
      <v-card-actions>
        <v-spacer/>
        <v-btn dark color="#893ff2" @click="submitDownload"><v-icon>mdi-download</v-icon>Download</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {getImageries, downloadImages} from "@/backend";
import MonthTransform from "@/ultis/converMonth";
import dualMonth from "@/components/dualMonthRangePicker";
import search from "@/components/home/aoiMenuMosiac";

export default {
  name: "SelectImage",
  components: {
    dualMonth, 
    'searchBox' : search
  },
  data () {
    return {
      headers: [
        {
          text: 'Image Name',
          align: 'start',
          sortable: false,
          value: 'name',
        }
      ],
      imageSelected: [],
      allImage: [],
      loading: false,
      downloadDialog: false,
      imageType: '',
      month: {
        from: undefined,
        to: undefined
      },
      aoi: ''
    }
  },
  computed: {
    availableImage () {
      if (this.imageType === 'input') return this.allImage.filter(val => val.type === 'mosaic')
      else return this.allImage.filter(val => val.type !== 'mosaic')
    }
  },
  methods: {
    newAOI(x){
      console.log('new AOI',x)
      // console.log('select aoi but see item:', this.availableImage)
      this.aoi = x;
	  this.getData()
    },
    newDate(date){
          this.month.from = date[0]
          this.month.to = date[1]
          console.log('Test Date: ', this.month)
		this.getData()
    },
    openDialog (type) {
      this.imageType = type
      this.imageSelected = []
     // this.getData()
      this.downloadDialog = true
    },
    convertMonth (month) {
      return MonthTransform.transformMonthText(month)
    },
    async getData () {
      try {
	  
	  if (! this.aoi.id || ! this.month.from)
	  //if (! this.aoi.id)
	  return
	  
        this.loading = true
       // const res = await getImageries()
	   
	    const res = await getImageries({
            dateRanger: [this.month.from, this.month.to],
			//dateRanger: ['', ''],
            source: this.currentDatasource
          })
        this.allImage = []
		res.data.data.forEach(i => {
		
		if (i.aoi_id == this.aoi.id)
		{
		//i.name = i.name.replace('2021_07','2021_09').replace('2021_08','2021_09')
		this.allImage.push(i)
		}
		
		})
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async submitDownload () {
      try {
        this.loading = true
        const imageIds = []
        this.imageSelected.forEach(image => {
          imageIds.push(image.id)
        })
        const res = await downloadImages({image_ids: imageIds})
        window.open(res.data.data, "_blank")
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style>
.DownloadBox button{
  width: 270px !important;
}

</style>
