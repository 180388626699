<template>
  <v-app style="height: 100vh">
    <div style="height: 100%" :style="{'background-image': `url(${require('@/assets/images/bau-troi-day-sao.webp')}`}">
<!--      <div class="header" style="width: 100%">-->
<!--        <img src="https://wallpaperaccess.com/full/123112.jpg" width="100%" class="image-header" style="object-fit: cover">-->
<!--      </div>-->
      <div class="d-flex flex-column overflow-y-auto content">
        <div class="d-flex align-center px-8 elevation-1" style="height: 75px; width: 100%; background-color: rgba(147,147,147,0.36); position: absolute; top: 0; right: 0; z-index: 3">
          <img :src="require('@/assets/images/logo-white-2a642acb6b7ceb83fae385c1c7524d5f.png')" height="50"/>
          <v-spacer/>
          <v-btn text dark @click="login" v-if="!currentUser">Sign In</v-btn>
          <div style="width: 180px" v-else class="d-flex align-center">
            <v-btn style="border: 2px solid #fff" text dark @click="goToLink('/app')" class="mr-4">Get started</v-btn>
            <AvatarPopOver></AvatarPopOver>
          </div>
        </div>
        <div style="width: 100%; height: 800px;; position: relative">
          <div style="width: 100%; background-color: rgba(0,0,0,0.37); position: absolute; top: 0; left: 0" class="mt-2 header d-flex align-center justify-center">
            <v-btn height="40" style="border: 2px solid #fff" dark @click="goToLink('/app')" class="join-button"><span style="font-weight: 800">Let's experience together</span></v-btn>
          </div>
          <div style="position: absolute; top: 120px; left: 15vw; color: white">
            <span style="font-size: 22px; font-weight: 400">Welcome</span>
            <br>
            <br>
            <span style="font-size: 60px; font-weight: 800; color: #ffdd7c;  line-height: 50px">
              Experience
              <span style="font-size: 40px; font-weight: bold; color: #7cc6ff;">
                &
              </span>
              <span style="font-size: 60px; font-weight: 800; color: #7a78e6; line-height: 50px">
                Enjoy
              </span>
            </span>
            <br>
            <div style="width: 500px" class="pt-3">
              <span style="max-width: 500px">EOfactory allows you to use your own data, or subscribe to our data delivery services. Our platform supports multi public and private data sources that can be used to analyse and extract information.</span>
            </div>
          </div>
          <img :src="require('@/assets/images/wp2848452.png')" width="100%" style="max-height: 800px; object-fit: cover">
        </div>
        <v-container style="max-width: 1468px;">
          <div style="width: 100%; height: 160px">
            <v-layout data-aos="fade-up" class="fill-height" align-center justify-center>
              <span style="font-size: 65px; font-weight: 800; color: white">Technology Solutions</span>
            </v-layout>
          </div>
          <div style="width: 100%; height: auto">
            <v-layout data-aos="fade-up" >
              <div class="d-flex pa-5 flex-column" style="flex: 1; color: white">
                <span style="font-size: 28px; font-weight: bold">Display overlay data</span>
                <p class="mt-4"></p>
              </div>
              <div class="d-flex pa-5" style="flex: 2">
                <div>
                  <img v-scroll-animation :src="require('@/assets/images/search_image.png')" style="max-width: 800px; object-fit: cover; border-radius: 25px"/>
                </div>
              </div>
            </v-layout>
          </div>
          <div style="width: 100%; height: auto">
            <v-layout class="mt-5" data-aos="fade-up" >
              <div class="d-flex pa-5" style="flex: 2">
                <div>
                  <img v-scroll-animation :src="require('@/assets/images/data_source.png')" style="max-width: 800px; object-fit: cover; border-radius: 25px"/>
                </div>
              </div>
              <div class="d-flex pa-5 flex-column" style="flex: 1; color: white">
                <span style="font-size: 28px; font-weight: bold">Data Analysis</span>
                <p class="mt-4"></p>
              </div>
            </v-layout>
          </div>
          <div style="width: 100%; height: auto">
            <v-layout class="mt-5" data-aos="fade-up" >
              <div class="d-flex pa-5 flex-column" style="flex: 1; color: white">
                <span style="font-size: 28px; font-weight: bold">Image management</span>
                <p class="mt-4"></p>
              </div>
              <div class="d-flex pa-5" style="flex: 2">
                <div>
                  <img v-scroll-animation :src="require('@/assets/images/order.png')" style="max-width: 800px; object-fit: cover; border-radius: 25px"/>
                </div>
              </div>
            </v-layout>
          </div>
        </v-container>
        <div style="height: 300px; width: 100%; position: relative; border-top: 1px solid #454545" class="d-flex flex-column">
          <div style="width: 100%; height: 300px; background-color: rgba(0,0,0,0.48); position: absolute; top: 0; left: 0"></div>
            <img :src="require('@/assets/images/footer-background.jpg')" width="100%" style="max-height: 300px; object-fit: cover">
            <v-layout class="fill-height" style="position: absolute; top: 0; left: 0; width: 100%" align-center>
              <v-container style="max-width: 1140px; height: 100%">
                <v-layout class="fill-height" align-center>
                  <v-layout column class="footer" style="z-index: 2">
                    <span style="font-size: 45px; font-weight: 600; color: #fff">
                      Get started with EOfactory
                    </span>
                            <span style="font-size: 20px; color: #fff" class="mt-4">
                      From pixels to intelligent
                    </span>
                  </v-layout>
                  <v-spacer/>
                  <div style="height: 240px;width: 411px; border: 3px solid #47494e; border-radius: 10px; background-size: 411px 240px;" :style="{'background-image': `url(${require('@/assets/images/map.jpg')}`}"></div>
                </v-layout>
              </v-container>
            </v-layout>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import config from '@/config.json'
import AvatarPopOver from "@/components/AvatarPopOver";
import {mapState} from "@/store/ults";
export default {
  components: {
    AvatarPopOver
  },
  data: () => ({

  }),
  computed: {
    ...mapState("auth", ["currentUser"]),
  },
  created () {
  },
  methods: {
    login () {
      location.href = `${location.origin}/signIn`
    },
    goToLink(link) {
      if (this.$route.path !== link) this.$router.push({ path: link });
    }
  }
};
</script>
<style scoped>
@media (prefers-reduced-motion: no-preference) {
  .square-animation {
    animation: wipe-enter 1s 1;
  }
}
.before-enter {
  opacity: 0;
  transform: scale(.5) rotateZ(-25deg);
  transition: all 1s ease-out;
}
.enter {
  opacity: 1;
  transform: scale(1) rotateZ(0deg);
}
.header {
  height: 800px;
}
.content {
  height: 100vh
}
.join-button {
  display: flex;
}

@media only screen and (max-width: 1100px) {
  .join-button {
    display: none;
  }
}
@media only screen and (max-height: 800px) {
  .header {
    height: 700px;
  }
}
</style>
<style>
.custom-nav .v-navigation-drawer__content {
  overflow-y: hidden;
}

.main-layout .v-list-group__header .v-list-item {
  padding-left: 0;
}
.main-layout .custom-list-item {
  padding-left: 30px;
}
</style>
<style>
html,
body,
.app {
}
</style>
