<template>
  <vue-html2pdf
      :show-layout="true"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="true"
      :paginate-elements-by-height="7000"
      filename="hee hee"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      @hasDownloaded="() => $emit('setLoading', false)"
      ref="html2Pdf"
  >
    <section slot="pdf-content">
      <PdfContent
          :AOI="AOI"
          :green="green"
          :greenV2="greenV2"
          :plantHealthImg="plantHealthImg"
          :plantHealthImgV2="plantHealthImgV2"
          :plantHealthImgV3="plantHealthImgV3"
      />
    </section>
</vue-html2pdf>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf'
import Datasource from "@/components/home/dataSource/index";
import AOI from "@/components/download/pdf/maps/AOI";
import GreenCover from "@/components/download/pdf/maps/GreenCover";
import GreenCoverV2 from "@/components/download/pdf/maps/GreenCoverV2";
import PlantHealth from "@/components/download/pdf/maps/PlantHealth";
import PlantHealthV2 from "@/components/download/pdf/maps/PlantHealthV2";
import PlantHealthV3 from "@/components/download/pdf/maps/PlantHealthV3";
import PdfContent from "@/components/download/pdf/PdfContent";

export default {
  name: "ExportPDF",
  components: {
    PdfContent,
    GreenCover,
    GreenCoverV2,
    PlantHealth,
    PlantHealthV2,
    PlantHealthV3,
    AOI,
    Datasource,
    VueHtml2pdf
  },
  data() {
    return {
      AOI: undefined,
      green: undefined,
      greenV2: undefined,
      plantHealthImg: undefined,
      plantHealthImgV2: undefined,
      plantHealthImgV3: undefined,
      content: undefined
    }
  },
  mounted() {
  },
  computed: {
  },
  methods: {
    generateReport (images) {
      this.AOI = images.AOI
      this.green = images.green
      this.greenV2 = images.greenV2
      this.plantHealthImg = images.plantHealthImg
      this.plantHealthImgV2 = images.plantHealthImgV2
      this.plantHealthImgV3 = images.plantHealthImgV3
      console.log(images)
      this.$refs.html2Pdf.generatePdf()
    }
  }
}
</script>

<style scoped>

</style>
